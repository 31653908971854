@import '../../scss/variables';

.staticbanner{
    &-image__div{
      width: 100%;
      margin: 50px 0;
    }

    &-image__img{
      width: 100%;
    }
}