@import '../../scss/variables';

.navbardesktop{
  &-container__div{
    padding-bottom: 50px;
  }

  &-appbar__appbar{
    display: flex;
    flex-flow: row wrap;
  }
  
  &-icons__div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    'left center right'
    '. tagline .';
    width: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    padding: 7px 0;
  }
  
  &-lefticon__div{
    padding: 5px;
    display: flex;
    align-items: center;
    grid-area: left;
  }
  
  &-search-btn__iconbutton--active{
    transition: .5s all ease;
  }
  
  &-logo__div{
    justify-self: center;
    width: 150px;
    margin: auto;
    grid-area: center;
  }

  &-image__img{
    width: 100%;
  }

  &-navlink__navlink{
    text-decoration: none;
  }
  
  &-righticon__div{
    padding: 5px;
    position: relative;
    align-self: center;
    display: grid;
    grid-template-areas: 
    'icons'
    'links';
    align-items: center;
    justify-items: end;
    grid-area: right;
  }

  &-right-icons__div{
    grid-area: icons;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    right: 30px;
  }
  
  &-cart__navlink{
    text-decoration: none;
    color: $default-red;
    padding: 5px;
    grid-area: cart;
    &:hover{
      color: $hover-red;
    }
  }

  &-account__div{
    color: $default-black;
    grid-area: signup;
    padding: 5px;
  }

  &-account__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    position: relative;
    top: 5px;
    color: $default-black;

    &:hover{
      color: $default-red;
      text-decoration: underline;
    }
  }

  &-link__navlink--active{
    color: $default-red;
  }

  &-main-links__div{
    display: flex;
    flex-flow: row wrap;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    color: $default-black;
    min-height: 35px;
    grid-area: links;
  }
  
  &-main-link__div{
    font-family: inherit;
    font-size: inherit;
    position: relative;
    padding: 5px;
  }
  
  &-main-link__navlink{
    font-family: inherit;
    font-size: inherit;
    margin: 0px 5px 0px 5px;
    text-decoration: none;
    color: $default-black;

    &:hover{
      color: $default-red;
    }
  }

  &-tagline__div{
    font-family: $default-sans-serif-font;
    font-size: 11px;
    color: $default-black;
    margin: auto;
    grid-area: tagline;
    justify-self: center;
  }

  &-tagline__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    text-align: center;
  }

  &-bottom__div{
    grid-area: 'bottom';
    display: flex;
    flex-flow: row wrap;
    margin: 5px auto 5px auto;
    font-family: inherit;
    font-size: inherit;
    min-height: 40px;
    position: relative;
  }
  
  &-bottom-link__navlink,
  &-bottom-link__button{
    cursor: pointer;
    background: none;
    text-decoration: none;
    color: $default-black;
    transition: .3s all ease;
    font-size: 15px;
    font-family: inherit;
    padding: 0px 10px;
    line-height: 1.7;
    &:hover{
      color: $default-red;
    }
  }

  &-link__span--hover{
    &:hover{
      color: $hover-red;
    }
  }

  &-bottom-link-account__navlink{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  &-bottom-link-text__p{
    font-size: inherit;
    font-family: inherit;
    padding: 5px;
  }

  &-link__navlink--active{
    color: $default-red;
  }
  
  &-link-border__div{
    width: 3px;
    background-color: $default-red;
    height: 30px;
    align-self: center;
  }
  
  &-link__div{
    display: flex;
    flex-flow: column wrap;
    align-self: center;

    &:hover{
      color: $hover-red;
    }
  }
  
  &-dash__div{
    width: 30px;
    height: 3px;
    margin: 0 auto 0 auto;
    background-color: $default-red;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }

  &-submenu__div{
    background-color: $default-off-white;
    padding: 30px;
    min-height: 200px;
    border-top-color: transparent;
  }

  &-submenu__div:not(&-submenu__div--active):not(&-submenu-account__div--active){
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
  }
  
  &-submenu__div--active{
    position: absolute;
    top: 30px;
    left: 0;
    width: 969px;
  }
  
  &-submenu-account__div--active{
    position: absolute;
    top: 46px;
    right: -25px;
    padding: 30px;
    width: 125px;
    display: block;
    z-index: 1000000;
    border-top: 3px solid $default-red;
  }

  &-login-link__navlink{
    text-decoration: none;
    color: $default-black;
    transition: .3s all ease;
    font-size: 15px;
    font-family: inherit;
    line-height: 1.7;
    &:hover{
      color: $default-red;
    }
  }
  
  &-submenu-links__div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 600px;
    align-items: start;
    grid-template-rows: auto;
    grid-row-gap: 5px;
  }
  
  &-submenu-account-links__div{
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    grid-template-rows: auto;
    justify-content: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
  }
  
  &-searchbar__div{
    display: flex;
    flex-flow: row wrap;
    padding: 15px 5px 5px 5px;
    justify-content: center;
  }
  
  &-icon-close__iconbutton{
    position: relative;
    top: -10px;
  }
}