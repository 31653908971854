@import '../../scss/variables';

.orderdetaildialog{
  &-content__div{
    padding: 5px;
    margin: 5px auto;
    max-width: 400px;
  }

  &-close__div{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    margin: 5px;
    text-align: center;
  }
 
 &-accent__div{
   width: 28px;
   height: 3px;
   background-color: $default-red;
   display: block;
   margin: 15px auto 15px auto;
 }
  
  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
    padding: 5px;
  }

  &-details__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    line-height: 1.5;
  }

  &-status__div{
    padding: 5px;
  }

  &-details__span--strong{
    font-weight: 600;
  }


}