@import '../../scss/variables';

.checkoutpaymentshipping{
  &-cont-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 50%;
    margin: 15px auto 40px auto;
    max-width: 250px;
  }

  &-header__div{
    display: flex;
    flex-flow: row wrap;
    padding: 5px;
    align-items: center;
    margin-bottom: 10px;
  }

  &-header__div--red{
    background-color: $default-red;
  }

  &-header__h3{
    padding: 5px;
    margin: 0 auto 0 0;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
    color: #5a5a5a;
  }
  
  &-header__h3--white{
    color: $default-white;
  }

  &-methods__div{
    font-size: 15px;
    font-family: $default-sans-serif-font;
  }

  &-method-details__div{
    display: grid;
    grid-template-columns: 45px 1fr;
    align-items: center;
    padding: 5px;
  }

  &-payment-options__div{
    padding: 5px;
  }

  &-payment-option__div{
    line-height: 1.3;
    padding: 5px;
    border: 2px solid #d2d2d2;
    // background-color: $default-cream;
  }

  &-new-payment__div{
    padding: 5px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 15px auto;
    color: $default-red;
  }

  &-new-payment-btn__div{
    padding: 5px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 15px auto;
    color: $default-red;
  }

  &-new-payment__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-new-header__div{
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-template-rows: auto;
  }

  &-new-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 20px;
    font-weight: 400;
    padding: 0 15px;
    justify-self: start;
  }

  &-new-header-btn__iconbutton{
    justify-self: center;
  }

  &-payment-dialog__div{
    display: block;
    margin: auto;
    max-width: 450px;
  }

  &-payment__form{
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: auto;
    // grid-row-gap: 15px;
    display: block;
    margin: 50px auto 0 auto;
    // justify-items: center;
  }

  &-billing-dialog__div{
    display: block;
    margin: auto;
    max-width: 450px;
  }

  &-billing__form{
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: auto;
    // grid-row-gap: 15px;
    display: block;
    margin: 50px auto 0 auto;
  }

  &-shipping-list__div--no-display{
    display: none;
  }

  &-shipping-checkbox__div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 5px;
    color: #5a5a5a;
  }

  &-shipping-checkbox-details__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-shipping-dialog__div{
    display: block;
    margin: auto;
    max-width: 450px;
  }

  &-shipping__form{
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: auto;
    // grid-row-gap: 15px;
    display: block;
    margin: 50px auto 0 auto;
  }

  &-country-list__div{
    
  }

  &-new-btns__div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    padding: 10px;
    margin: 25px 0;
  }

  &-divider__div{
    width: 80%;
    display: block;
    margin: 50px auto;
    height: 3px;
    background-color: $default-content-gray;
  }

  &-instructions__div{
    padding: 5px;
  }

  &-instructions__textarea{
    border: 1px solid rgba(0, 0, 0, .23);
    border-radius: 4px;
    font-size: 15px;
    font-family: $default-sans-serif-font;
    background-color: #fdfdfd;

    &:focus{
      outline: none;
      border: 2px solid $default-red;
    }

    &:hover{
      border: 1px solid $default-black;
    }
  }

  &-pickup-list__div{
    padding: 5px;
    margin: 0 0 50px 0;
  }

  @media only screen and (min-width: 767px) {
    &-container__div{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: 
      'info summary';
      grid-column-gap: 20px;
    }
  
    &-info__main{
      grid-area: info;
      margin-top: 15px;
      padding-left: 20px;
    }

    &-order-summary__aside{
      grid-area: summary;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      grid-template-columns: .6fr .4fr;
    }

    &-new-payment-btn__div{
      &:hover{
        color: $hover-red;
      }
    }
  }
}