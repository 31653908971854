@import '../../scss/variables';

.booksmedialp{
  &-floors__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
    padding: 5px;
    margin-bottom: 50px;
  }

  &-floor-bg__div{
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    background-position: center;
    padding: 5px;
    margin: 15px;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
  }

  &-floor-first__div{
    background-image: url(urlGeneratorSASS('/S/misc/FirstFloor_Mobile_Static.png'));
  }

  &-floor-second__div{
    background-image: url(urlGeneratorSASS('/S/misc/SecondFloor_Mobile_Static.png'));
  }

  &-floor-rare__div{
    background-image: url(urlGeneratorSASS('/S/misc/Rare_Mobile_Static.png'));
  }

  &-floor-underground__div{
    background-image: url(urlGeneratorSASS('/S/misc/Underground_Mobile_Static.png'));
  }

  @media only screen and (min-width: 767px) {
    &-floors__div{
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__div{
      max-width: 1366px;
      margin: auto;
    }
    &-floors__div{
        grid-template-columns: 1fr;
    }

    &-floor-bg__div{
      height: 300px;
      width: 1000px;
      background-size: contain;
      background-color: $default-red;
      transition: all .5s ease;
      padding: 0px;
      margin: 25px;
      box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    }
    
    &-floor-first__div{
        background-image: url(urlGeneratorSASS('/S/misc/FirstFloor_Static.png'));

        &:hover{
          background-image: url(urlGeneratorSASS('/S/misc/FirstFloor_loop.gif'));
          box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
        }
      }
    
    &-floor-second__div{
        background-image: url(urlGeneratorSASS('/S/misc/SecondFloor_Static.png'));

        &:hover{
            background-image: url(urlGeneratorSASS('/S/misc/SecondFloor_loop.gif'));
            box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
        }
    }

    &-floor-rare__div{
        background-image: url(urlGeneratorSASS('/S/misc/Rare_Static.png'));

        &:hover{
            background-image: url(urlGeneratorSASS('/S/misc/Rare_loop.gif'));
            box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
        }
    }

    &-floor-underground__div{
        background-image: url(urlGeneratorSASS('/S/misc/Underground_Static.png'));

        &:hover{
            background-image: url(urlGeneratorSASS('/S/misc/Underground_loop.gif'));
            box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
        }
        }
  }

  @media only screen and (min-width: 1339px) {
    &-floor-bg__div{
      width: 1300px;
    }
  }
}