@import '../../scss/variables';

.bbtftc{
    &-content__div{
        font-family: $default-sans-serif-font;
        font-size: 15px;
        padding: 5px;
        line-height: 1.7;
        margin: 0 10px 50px 10px;
      }
    
      &-details__div{
        font-family: $default-sans-serif-font;
        font-size: 15px;
      }
    
      &-header__h2{
        font-family: $default-sans-serif-font;
        font-size: 18px;
        font-weight: 600;
        color: $default-red;
      }
    
      &-details__p{
        font-family: inherit;
        font-size: inherit;
      }

      &-link__a{
        text-decoration: none;
        color: $default-red;
        font-family: inherit;
        font-size: inherit;
      }

      &-header__h3{
        font-family: $default-sans-serif-font;
        font-size: 15px;
      }
    
      &-details__p--strong{
        font-weight: 600;
      }
    
      @media only screen and (min-width: 767px) {
        &-content__div{
          width: 600px;
          margin: 0 auto 50px auto;
        }
      }
    
      @media only screen and (min-width: 1023px) {
        &-container__div{
          max-width: 1366px;
          margin: auto;
        }
        &-content__div{
          width: 900px;
        }
      }
}