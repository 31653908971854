@import '../../scss/variables';

.eventslist{

  &-date-sort__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 10px;
  }
  &-list__div{
    padding: 5px;
    // margin-left: 15px;
  }

  &-item__div{
    display: grid;
    grid-template-columns: 110px 1fr;
    margin-bottom: 20px;
  }

  &-link__link{
    text-decoration: none;
    color: $default-black;
  }

  &-image__div{
    align-self: center;
    padding: 5px;
    max-width: 100px;
    margin-left: 10px;
  }

  &-image__img{
    width: 100%;
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 0 10px 5px 0;
  }

  &-accent__div{
    height: 3px;
    width: 25px;
    background-color: $default-red;
    margin-bottom: 10px;
  }

  &-details__div{
    padding: 5px;
    margin-left: 10px;
    align-self: flex-start;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-date__p{
    margin: 15px 0;
  }

  &-summary__div{
    align-self: flex-start;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-more-link__link{
    text-decoration: none;
    color: $default-red;
    &:hover{
      color: $hover-red;
    }
    font-family: inherit;
    font-size: inherit;
  }

  &-more__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-no-events__div{
    padding: 5px;
    text-align: center;
    margin: 25px;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    margin: 10px;
    color: $default-red;
    font-weight: 600;
  }

  @media only screen and (min-width: 1023px) {
    // &-date-sort__div{
    //   justify-content: flex-end;
    //   position: relative;
    //   right: 50px;
    // }

    &-list__div{
      max-width: 900px;
      margin: 50px auto;
    }
    &-item__div{
      grid-template-columns: 150px 1fr;
      margin-bottom: 20px;
    }

    &-link__link{
      &:hover{
        color: $hover-red;
      }
    }

    &-image__div{
      max-width: 125px;
    }
  }
}