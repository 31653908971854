@import '../../scss/variables';

.strand{
  &-container__div{
    background-color: #ed602d;
    border: 5px solid $default-yellow;
  }
  &-content__div{
    background-color: $default-white;
  }
  &-hero__div{
    width: 300px;
    padding: 25px 5px;
  }

  &-hero__img{
    width: 100%;
  }

  &-intro__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    text-align: center;
    margin: 25px auto;
    color: $default-black;
  }

  &-intro__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-border__div{
    height: 3px;
    background-color: $default-red;
    width: 80%;
    margin: 15px auto;
  }

  &-carousel__div{
    min-width: 0;
  }

  &-categories__div{
    display: grid;
    grid-template-columns: 1fr;
    padding: 5px;
    margin: 25px auto;
  }
  &-category__div{
    
    width: 300px;
    // margin: 5px auto;
    margin: 15px auto;
    transition: .5s all ease;

    &:hover{
      transform: translateY(-5px);
    }
  }

  &-category-image__img{
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
    width: 100%;
    &:hover{
        box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
    }
  }

  &-disclaimer__div{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    padding: 5px;
    margin: 5px;
    line-height: 1.5;
  }

  @media only screen and (min-width: 374px) {
    &-container__div{
      padding: 15px;
    }

    &-hero__div{
      margin: 25px auto;
    }
  }

  @media only screen and (min-width: 425px) {
    &-hero__div{
      width: 350px;
    }
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      width: 100%;
      padding: 0;
    }

    &-categories__div{
      grid-template-columns: 1fr 1fr;
      max-width: 800px;
      margin: auto;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__div{
      max-width: 1366px;
      margin: auto;
    }

    &-intro__div{
      font-size: 18px;
    }
  }
}