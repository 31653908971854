@import '../../scss/variables';

.searchbar{

  &-container__div{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }

  &-main__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: #808080;
    transition: .5s all ease-in;
    position: relative;

    &:hover{
      color: $default-red;
    }

  }

  &-input__input{
    border: 2px solid $default-content-gray;
    border-radius: 10px;
    padding: 1px;
    margin: 0;
    font-family: $default-sans-serif-font;
    min-height: 35px;
    background-color: transparent;
    width: 255px;
    font-size: 15px;
    color: rgba(0, 0, 0, .5);
    position: relative;
    text-indent: 95px;
    color: $default-black;
    font-weight: 600;
    z-index: 1;
      &:focus{
        outline: none;
      }
      -webkit-box-shadow: inset 0px 2px 10px 1px $default-content-gray;
      -moz-box-shadow: inset 0px 2px 10px 1px $default-content-gray;
      box-shadow: inset 0px 2px 10px 1px $default-content-gray;
  }

  &-clear__p{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    color: $default-red;
  }

  &-advance-link__navlink{
    text-decoration: none;
    color: $default-red;
    font-family: $default-serif-font;
    font-size: 15px;
  }

  &-icon-close__iconbutton{
    justify-self: flex-end;
  }

  @media only screen and (min-width: 374px) {
    &-input__input{
      width: 300px;
    }
  }

  @media only screen and (min-width: 425px) {
    &-input__input{
      width: 350px;
    }
  }

  @media only screen and (min-width: 600px) {
    &-input__input{
      width: 525px;
    }
  }

  @media only screen and (min-width: 767px) {
    &-input__input{
      width: 500px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;
    }
  
    &-input__input{
      width: 350px;
    }
  }
}