@import '../../scss/variables';

.hourslocations{

  &-hero__img{
    width: 100%;
  }

  &-notice__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    margin: 0px 10px;
    text-align: center;
    font-weight: 600;
  }

  &-notice__p{
    font-family: inherit;
    font-size: inherit;
    color: $default-red;
    font-weight: inherit;
    margin-bottom: 10px;
  }

  &-notice-table__div{
    width: 100%;
    overflow: auto;
  }

  &-notice-table__table{
    color: $default-red;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    text-align: left;
    width: 650px;
    margin: 0 auto;
  }

  &-notice-table__th,
  &-notice-table__td{
    vertical-align: top;
  }

  &-notice-table__hidden{
    display: none;
  }

  &-content__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-bottom: 50px;
  }

  &-location__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
    padding: 5px 15px;
    margin-bottom: 20px;
    line-height: 1.7;
  }

  &-location-image__div{
    border-radius: 50%;
    border: 5px solid $default-red;
    width: 275px;
    height: 275px;
    overflow: hidden;
    margin: 25px auto 50px auto;
  }

  &-location-image__img{
    width: 100%;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0;
  }

  &-accent__div{
    width: 30px;
    height: 3px;
    background-color: $default-red;
    margin: 5px auto 15px auto;
  }

  &-border__div{
    width: 90%;
    height: 3px;
    background-color: $default-border-gray;
    margin: 50px auto;
  }

  &-products-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    text-align: center;
  }

  &-products__div{
    margin-bottom: 100px;
  }

  @media only screen and (min-width: 768px) {
    &-hero__div{
      height: 300px;
      overflow: hidden;
    }

    &-hero__img{
      position: relative;
      top: -175px;
    }

    &-content__div{
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "usq uws"
        "cpk lga";
      justify-items: center;
    }

    &-location-usq__div{
      grid-area: usq;
    }

    &-location-uws__div{
      grid-area: uws;
    }
    &-location-lga__div{
      grid-area: cpk;
    }

    &-location-lga__div{
      grid-area: lga;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }

    &-hero__div{
      height: 500px;
    }

    &-hero__img{
      top: -225px;
    }

    &-notice__div{
      margin-bottom: 15px;
    }

    &-content__div{
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: "usq uws cpk lga";
    }
  }

  @media only screen and (min-width: 1439px) {
    &-hero__img{
      top: -400px;
    }
  }

  @media only screen and (min-width: 2559px) {
    &-hero__div{
      height: 800px;
    }
    &-hero__img{
      top: -800px;
    }
  }
}