@import '../../scss/variables';

.botm{
  &-content__div{
    background-color: $default-white;
  }
  &-hero__div{
    width: 300px;
    margin: 50px auto;
    padding: 5px;
  }

  &-hero__img{
    width: 100%;
  }

  &-intro__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    // text-align: center;
    line-height: 1.7;
  }
  &-new_text__p {
    padding: 5px;
    background-color: $default-light-gray;
    margin: 25px auto;

  }

  &-new_text__p {
    padding: 5px;
    background-color: $default-light-gray;
    margin: 25px auto;

  }
  &-book_link_a{
    text-decoration: none;
    color: $default-red;
    font-family: inherit;
    font-size: inherit;
  }

  &-intro__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-intro-center__p{
    text-align: center;
  }

  &-border__div{
    width: 80%;
    height: 3px;
    background-color: $default-border-gray;
    margin: 15px auto;
  }

  &-option-image__div{
    width: 300px;
    padding: 5px;
    margin: auto;
  }

  &-option-image__img{
    width: 100%;
  }

  &-pick__p {
    color: $default-red;
    margin-left: 80px;
  }

  &-descrip__p {
    text-align: center;
  }

  &-option-form__div{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-template-rows: 1fr 75px;
  }

  &-inputs__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  &-option__div{
    padding: 5px;
    margin: 15px auto;
  }

  &-add__div{
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding: 5px;
    margin: 10px auto;
    width: 150px;
  }

  &-book__div{
    padding: 5px;
  }

  &-header__h3{
    margin: 5px;
    font-weight: 600;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
  }

  &-book_link_a {
    text-decoration: none;
    color: $default-red;
    font-family: inherit;
    font-size: inherit;
  }

  &-book-genre__div{
    text-align: center;
    color: $default-red;
  }

  &-book-details__div{
    display: grid;
    grid-template-columns: 1fr;
    padding: 5px;
  }

  &-book-image-container__div{
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
  }

  &-book-image__div{
    width: 150px;
    padding: 5px;
    margin: 5px auto;
  }

  &-book-image__img{
    width: 100%;
  }

  &-book-title__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
    margin: 5px 0 10px 0;
  }

  &-book-author__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-book-description-container__div{
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    padding: 5px;
  }

  &-book-description__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-view-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  &-book-border__div{
    width: 80%;
    height: 3px;
    background-color: $default-border-gray;
    margin: 50px auto;
  }

  &-faq__div{
    margin: 50px auto 0 auto;
    padding: 5px 5px 50px 5px;
    background-color: $default-light-gray;
  }

  &-faq-details__div{
    padding: 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
  }

  &-faq-details__p{
    margin-left: 5px;
    font-family: inherit;
    font-size: inherit;
  }

  &-faq-link__a{
    text-decoration: none;
    color: $default-red;

    &:hover{
      color: $hover-red;
    }
  }

  @media only screen and (min-width: 450px) {
    &-hero__div{
      width: 100%;
      padding: 0;
    }
  }

  @media only screen and (min-width: 767px) {
    &-options__div{
      display: grid;
      grid-template-columns: 300px 1fr;
      max-width: 800px;
      margin: auto;
    }

    &-inputs__div{
      flex-flow: row wrap;
      align-items: center;
      padding: 10px;
    }

    &-add__div{
      position: relative;
      top: -75px;
      left: 30px;
    }

    &-book-details__div{
      grid-template-columns: 200px 1fr;
    }

    &-book-image-container__div{
      justify-content: flex-start;
    }

    &-book-description-container__div{
      justify-content: flex-start;
      position: relative;
      top: 45px;
    }
  }

  @media only screen and (min-width: 1023px){
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }

    &-intro__div{
      font-size: 18px;
    }
  }
}