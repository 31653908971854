@import '../../scss/variables';

.routes{
  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    margin: 50px auto;
    text-align: center;
    line-height: 1.5;
    color: $default-red;
  }

  &-error__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 5px;
    margin: 50px 0;
  }

  &-logo__div{
    width: 125px;
    margin: auto;
  }

  &-logo__img{
    width: 100%;
  }
}