@import '../../scss/variables';

.navsidebar{
  &-container__div{
    padding: 5px;
    margin: 10px 0 0 30px;
    position: sticky;
    top: 200px;
    border-left: 2px solid $default-border-gray;
  }

  &-content__nav{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    display: flex;
    flex-flow: column wrap;
  }

  &-item-link__a{
    text-decoration: none;
    padding: 5px;
    color: $default-dark-gray;
    background-color: #fdfdfd;
  }

  @media only screen and (min-width: 1023px) {
    &-item-link__a{
      &:hover{
        color: $default-red;
      }
    }
  }
}