@import '../../scss/variables';

.contactus{

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    text-align: center;
    margin: 50px 0;
  }

  &-link__a{
    text-decoration: none;
    color: $default-red;
    font-weight: 600;
  }

  &-border__div{
    height: 3px;
    width: 80%;
    background-color: $default-border-gray;
    margin: 5px auto;
  }

  &-faq__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 10px;
    text-align: left;
    margin: 50px 0px;
  }

  &-faq__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-header__h3{
    font-size: 15px;
    font-weight: 600;
    padding: 0 5px;
    margin: 10px 0;
  }

  @media only screen and (min-width: 1023px) {
    &-faq__div{
      max-width: 900px;
      margin: 50px auto;
    }
    &-link__a{
      &:hover{
        color: $hover-red;
      }
    }
  }
}