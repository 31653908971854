@import '../../scss/variables';

.myaccount{

  &-main__div{
    margin: 50px 0;
  }

  @media only screen and (min-width: 767px) {

    &-drawer-content__div{
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      margin-left: 15px;
      // border-right: 3px solid $default-border-gray;
    }
  
    &-content__div{
      margin: 25px 0 0 0;
    }
  
    &-header__h2{
      font-family: $default-sans-serif-font;
      font-size: 18px;
      margin: 5px;
      font-weight: 400;
    }
  
    &-dash__div{
      width: 30px;
      margin: 10px 5px;
      height: 3px;
      background-color: $default-red;
    }
  
    &-border__div{
      background-color: $default-border-gray;
      height: 3px;
      margin: 10px 0;
    }
  
    &-main__div{
      display: grid;
      grid-template-columns: 25% 1fr;
    }

    &-drawer-button__div{
      padding: 5px;
    }

    &-link__navlink{
      text-decoration: none;
      color: $default-black;

      &:hover{
        color: $default-red;
      }
    }

    &-content__div{
      margin: 0;
    }

    &-content__div{
      border-left: 3px solid $default-border-gray;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-main__div{
      margin: 25px 0;
      grid-template-columns: 20% 1fr;
    }

    &-drawer-button__div{
      padding: 5px;
    }

    &-link__navlink{
      text-decoration: none;
      color: $default-black;

      &:hover{
        color: $default-red;
      }
    }

  }

  @media only screen and (min-width: 1439px) {
    &-main__div{
      margin: 25px 0;
      // grid-template-columns: 10% 1fr;
    }
  }

  @media only screen and (min-width: 1600px) {
    &-main__div{
      grid-template-columns: 10% 1fr;
      padding-left: 50px;
    }
  }
}