@import '../../scss/variables';

.jobs{
  &-hero__div{
    background-image: url(urlGeneratorSASS('/S/pages/jobs_strand_basement_02_with_steve_powers_artwork_by_colleen_callery.jpg'));
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-content__div{
    margin-bottom: 50px;
  }
  &-notice__div{
    margin: auto;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 15px;
    width: 300px;
    text-align: center;
    background-color: $default-border-gray;
  }

  &-notice__p{
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
  }

  &-notice-link__a{
    text-decoration: none;
    color: #af272f;
    font-family: inherit;
    font-size: inherit;
  }

  &-item__div{
    padding: 8px;
  }

  &-item__div--gray{
    background-color: $default-light-gray;
  }

  &-item-details__div{
    padding: 8px;
    margin-left: 16px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
  }

  &-item-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-item-details-list__ul{
    margin-left: 24px;
  }

  &-item-details-list-no-bullets__ul{
    list-style-type: none;
  }

  &-item-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  &-header__h1{
    font-family: $default-sans-serif-font;
    font-size: 25px;
    padding: 8px;
    text-align: center;
  }

  &-header__div{
    padding: 8px;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 8px 0px 8px;
    margin: 8px 0;
  }

  &-accent__div{
    background-color: $default-red;
    height: 3px;
    width: 24px;
    margin: 0px 16px 0px 8px;
  }

  &-apply__a{
    text-decoration: none;
    color: $default-white;
  }

  &-apply__div{
    background-color: $default-red;
    padding: 16px;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    border-radius: 4px;
    width: 150px;
    text-align: center;
    margin: 8px auto;
  }

  &-apply__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-contact__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin-left: 32px;
  }

  &-contact__a{
    color: $default-red;
    &:hover{
      color: $hover-red;
    }
  }

  @media only screen and (min-width: 374px) {
    &-header__h1{
      text-align: left;
      margin-left: 16px;
    }
  }

  @media only screen and (min-width: 767px) {
    &-notice__div{
      width: 500px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }
    &-hero__div{
      height: 500px;
    }

    &-notice__div{
      width: 700px;
    }
  }
}