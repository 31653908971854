@import '../../scss/variables';

.landingpage{
  &-container__div{
    margin: 75px 0;
  }

  &-banner__div{
    width: 100%;
    margin: 10px 0;
  }

  &-banner__img{
    width: 100%;
  }

  &-header__h1{
    font-size: 25px;
    text-align: center;
    padding: 5px;
    margin: 5px;

    &--red{
      color: $default-red;
    }
  }

  &-intro__div{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    padding: 5px;
    text-align: center;
    line-height: 1.5;
    position: relative;
  }

  &-content__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  &-book__div{
    width: 150px;
    padding: 5px;
    margin: 10px auto;
  }

  &-book__img{
    width: 100%;
  }

  &-end__div{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    padding: 5px;
    color: $default-red;
    text-align: center;
    line-height: 1.5;
  }

  &-end__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-link__div{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 175px;
    margin: 10px auto;
  }

  &-link__link{
    background-color: $default-red;
    font-family: $default-sans-serif-font;
    text-decoration: none;
    color: #ffffff;
    min-width: 100px;
    font-size: 15px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    line-height: 1.5;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 15px;
    margin: 10px 0 10px 10px;
  }

  &-header__h2{
    font-size: 18px;
    font-family: $default-sans-serif-font;
    margin: 5px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 25px;
  }

  &-others__div{
    padding: 5px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  &-others-item__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 15px 0;
  }

  &-item-image__div{
    width: 150px;
    padding: 5px;
  }

  &-item-image__img{
    width: 100%;
  }

  &-item-link__div{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: auto;
    margin: 10px auto;
  }

  &-item-link__link{
    background-color: $default-red;
    font-family: $default-sans-serif-font;
    text-decoration: none;
    color: #ffffff;
    min-width: 100px;
    font-size: 15px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    line-height: 1.5;
  }

  &-author__div{
    padding: 5px;
    margin: 10px 0;
  }

  &-author-image__div{
    width: 200px;
    padding: 5px;
    margin: 10px auto;
  }

  &-author-image__img{
    width: 100%;
  }

  &-credit__p{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    padding: 5px;
    text-align: center;
  }

  &-bio__div{
    line-height: 1.5;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
  }

  @media only screen and (min-width: 767px) {
    &-banner__div{
      margin: 0 0 15px 0;
    }

    &-content__div{
      grid-template-areas: 
      'intro intro'
      'book blurb'
      'oheader oheader'
      'others others'
      'author author';
      grid-template-columns: 225px 1fr;
    }

    &-intro__div{
      grid-area: intro;
      margin-bottom: 25px;
    }

    &-book-button__div{
      grid-area: book;
    }

    &-blurb__div{
      grid-area: blurb;

      ul, ol, dl {
        list-style-position: outside;
        padding-inline-start: 20px;
       }
    }

    &-header__h2{
      grid-area: oheader;
    }

    &-others__div{
      grid-area: others;
    }

    &-author__div{
      grid-area: author;
      margin-top: 50px;
      display: grid;
      grid-template-columns: 225px 1fr;
      align-items: start;
    }

    &-author-image__div{
      margin: 50px auto 0px auto;
    }

    &-bio__div{
      margin-top: 50px;

      ul, ol, dl {
        list-style-position: outside;
        padding-inline-start: 20px;
       }
    }
  }

  @media only screen and (min-width: 1023px) {
    &-banner__div{
      max-width: 1300px;
      margin: 0 auto 15px auto;
    }

    &-content__div{
      max-width: 900px;
      margin: auto;
    }

    &-link__link{
      &:hover{
        background-color: '#d4301a',
      }
    }
  }

  @media only screen and (min-width: 1339px) {
    &-content__div{
      max-width: 1200px;
    }
  }
}