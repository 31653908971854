@import '../../scss/variables';

.pageheader{
  &-container__div{
      margin: 50px auto 10px auto;
      font-family: $default-sans-serif-font;
      font-size: 25px;
      text-align: center;
      font-weight: 400;
  }
  &-title__h1{
     font-family: inherit;
     font-size: inherit;
     font-weight: inherit;
  }
  &-text__p{
    font-size: 16px;
  }
  &-dash__div{
    width: 35px;
    height: 3px;
    background-color: $default-red;
    display: block;
    margin: 15px auto 50px auto;
  }
}