@import '../../scss/variables';

.pride2021{
    &-hero__div{
      width: 95%;
      margin: 50px auto;
      padding: 5px;
    }

    &-hero__img{
      width: 100%;
    }

    &-hero-intro__div{
      padding: 5px;
      text-align: center;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      line-height: 1.7;
      width: 95%;
      margin: auto;
    }

    &-hero-intro__p{
      font-family: inherit;
      font-size: inherit;
    }

    &-link__a{
      text-decoration: none;
      color: $default-red;
      font-family: $default-sans-serif-font;
      font-size: inherit;

      &:hover{
        color: $hover-red;
      }
    }

    &-lists-categories__div{
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-content: center;
      margin: auto;
      margin-bottom: 50px;
    }
  
    &-lists-category__div{
      padding: 8px;
      font-family: $default-sans-serif-font;
      font-size: 18px;
    }

    &-lists-category-image__img{
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
            transition: .5s all ease;
            &:hover{
              transform: translateY(-5px);
              box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
            }
            margin: 5px auto;
    }

    @media only screen and (min-width: 767px) {  

      &-lists-categories__div{
        grid-template-columns: repeat(2, 1fr);
        max-width: 900px;
      }

    }

    @media only screen and (min-width: 1023px) {
      &-container__div{
        max-width: 1366px;
        margin: auto;
      }

      &-hero-intro__div{
        font-size: 18px;
      }
    }
}