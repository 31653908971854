@import '../../scss/variables';

.highlightitem{
  &-content__div{
    transition: .5s all ease;
    &:hover{
      transform: translateY(-5px);
    }
    margin: 5px auto;
  }

  &-image__img{
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
    &:hover{
        box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
      }
  }
}