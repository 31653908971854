@import '../../scss/variables';

.rentrare {

  &-slider-hero-image__img{
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  &-title-border__div{
    position: absolute;
    padding: 10px;
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    width: 85%;

    &:before{
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 3px solid transparent;
      animation: headerIntro 1.5s linear forwards;
    }

    &:after{
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 3px solid transparent;
      animation: headerIntro2 1.5s linear forwards;
    }
  }

  &-title__div{
    font-family: $default-serif-font;
    color: $default-white;
    font-size: 15px;
    padding: 10px;
    text-align: center;
    font-weight: 400;
    animation: headerBackground 3s linear forwards;
  }

  &-title__h1{
    font-family: inherit;
    font-size: 1.75em;
    font-weight: inherit;
    margin: 5px 0;
  }

  &-title__h2{
    font-family: $default-sans-serif-font;
    font-weight: 400;
    font-size: 1.25em;
  }

  &-submenu__div{
    display: grid;
    grid-template-columns: 75px 75px 75px 75px 75px 1fr;
    justify-items: center;
    color: $default-red;
    background-color: $default-cream;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 15px 0;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 8;
    border-bottom: 3px solid $default-red;
  }

  &-submenu-link__a{
    padding: 5px;
    font-size: 1em;
    text-decoration: none;
    color: inherit;

    &:hover{
      color: $hover-red;
    }
  }

  &-submenu__div--nodisplay{
    display: none;
  }

  &-intro__div{
    padding: 5px;
    margin: 20px 0;
    display: flex;
    flex-flow: column wrap;
  }

  &-rbr-image__img{
    width: 95%;
    height: auto;
    padding: 5px;
    display: block;
    margin: auto;
  }

  &-rbr-image-1__img{
    order: 1;
  }

  &-rbr-image-2__img{
    order: 3;
  }

  &-details__div{
    font-family: $default-serif-font;
    font-size: 15px;
    text-align: center;
    line-height: 1.7;
    color: $default-dark-gray;
  }

  &-details-1__div{
    order: 2;
  }

  &-accent__div{
    width: 10%;
    height: 3px;
    background-color: $default-red;
    margin: 15px auto;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-border__div{
    margin: 100px auto;
    width: 80%;
    height: 2px;
    background-color: $default-content-gray;
  }

  &-border-1__div{
    order: 4;
  }

  &-rbr-image-3__img{
    order: 5;
  }

  &-details-2__div{
    order: 6;
  }

  &-rbr-image-4__img{
    order: 7;
  }

  &-details-3__div{
    order: 8;
    margin-top: 50px;
  }

  &-intro-btn__buttonbase{
    font-family: $default-serif-font;
    font-size: inherit;
    top: -1px;
  }

  &-pricing__div{
    margin: 50px 0;
  }

  &-pricing-banner__div{
    background-image: url(urlGeneratorSASS("/S/pages/rentrare-pricing-banner.jpg"));
    height: 300px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  &-pricing-border__div{
    border: 3px solid rgba(255, 255, 255, .8);
    padding: 10px;
    width: 85%;
    margin: auto;
  }

  &-pricing-header__div{
    background-color: rgba(191, 46, 26, .3);
    color: $default-white;
    font-size: 15px;
    font-family: $default-serif-font;
    text-align: center;
    padding: 10px;
    font-weight: 400;
  }

  &-header__h3{
    font-size: 1.25em;
    font-family: inherit;
    margin: 5px 0;
    font-weight: inherit;
  }

  &-pricing-details__div{
    padding: 5px;
  }

  &-header__h4{
    font-family: $default-serif-font;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin: 50px 0 10px 0;
  }

  &-header-accent__div{
    height: 3px;
    margin: 15px auto;
    width: 10%;
    background-color: $default-red;
  }

  &-pricing-banner-2__div{
    background-image: url(urlGeneratorSASS("/S/pages/rentrare-pricing-banner-2.jpg"));
    height: 300px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  &-pricing-image__img{
    margin-top: 50px;
  }

  &-categories__div{
    font-family: inherit;
    font-size: inherit;
  }

  &-fees__div{
    font-family: inherit;
    font-size: inherit;
  }

  &-partners__div{
    margin: 50px 0;
  }

  &-partners-banner__div{
    background-image: url(urlGeneratorSASS("/S/pages/rentrare-partner-banner.jpg"));
    height: 300px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  &-partners-border__div{
    border: 3px solid rgba(255, 255, 255, .8);
    padding: 10px;
    width: 85%;
    margin: auto;
  }

  &-partners-header__div{
    background-color: rgba(191, 46, 26, .3);
    color: $default-white;
    font-size: 15px;
    font-family: $default-serif-font;
    text-align: center;
    padding: 10px;
    font-weight: 400;
  }

  &-partners-details__div{
    padding: 5px;
  }

  &-partners-grid__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 10px;
  }

  &-partner__div{
    padding: 15px;
    border: 3px solid $default-content-gray;
    margin: 15px;
  }

  &-partner-details__div{
    display: flex;
    flex-flow: row wrap;
    padding: 5px;
    font-family: $default-serif-font;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
  }

  &-header__h5{
    font-size: 1em;
    font-family: inherit;
    font-weight: 400;
  }

  &-more__iconbutton{
    justify-self: flex-end !important;
  }

  &-dialog-image__img{
    width: 100%;
    margin: auto;
  }

  &-dialog-details__div{
    font-family: $default-serif-font;
    font-size: 15px;
    line-height: 1.7;
    text-align: center;
    padding: 5px;
    width: 90%;
    margin: auto;
  }

  &-dialog-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-dialog-details__strong--red{
    color: $default-red;
  }

  &-buy-out_a {
    color: #af272f;
  }

  &-dialog-link__a{
    text-decoration: none;
    color: $default-red;

    &:hover{
      color: $hover-red;
    }
  }

 &-contact-iframe__iframe{
   min-height: 800px;
   width: 300px;
   margin: auto;
 }

 &-gallery__div{
  margin: 50px 0;
  font-size: 15px;
  font-family: $default-serif-font;
}

&-gallery-stories__div{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 50px;
}

&-gallery-banner__div{
  background-image: url(urlGeneratorSASS("/S/pages/rentrare-gallery.jpg"));
  height: 300px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

&-gallery-border__div{
  border: 3px solid rgba(255, 255, 255, .8);
  padding: 10px;
  width: 85%;
  margin: auto;
}

&-gallery-header__div{
  background-color: rgba(191, 46, 26, .3);
  color: $default-white;
  font-size: 15px;
  font-family: $default-serif-font;
  font-weight: 400;
  text-align: center;
  padding: 10px;
}

&-gallery-grid__div{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-content: center;
}

&-gallery-img__div{
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

&-gallery-image__img{
  width: 100%;
  margin: auto;
}

&-gallery-stories__div{
  width: 95%;
  margin: 50px auto;
}

&-gallery-link__a{
  text-decoration: none;
}

&-gallery-couples-img__div{
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}

&-gallery-couples-header__div{
  background-color: rgba(0, 0, 0, .4);
  width: 100%;
  color: $default-white;

  &:hover{
    background-color: rgba(0, 0, 0, .2);
  }
}

&-header-couple__h4{
  margin: 10px 0;
}

  @media only screen and (min-width: 425px) {
    &-partners-grid__div{
      max-width: 375px;
      margin: auto;
    }
  }



  @media only screen and (min-width: 767px) {
    &-title-border__div{
      width: 60%;
    }

    &-title__div{
      font-size: 20px;
      padding: 20px;
    }

    &-intro__div{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
      'img1 details1'
      'img2 details1'
      'border1 border1'
      'details2 img3'
      'details2 img4'
      'details3 details3';
    }

    &-rbr-image__img{
      width: 350px;
    }

    &-rbr-image-1__img{
      grid-area: img1;
    }

    &-rbr-image-2__img{
     grid-area: img2;
    }

    &-details-1__div{
      grid-area: details1;
      align-self: center;
    }

    &-border-1__div{
      grid-area: border1;
    }

    &-rbr-image-3__img{
      grid-area: img3;
    }

    &-details-2__div{
      grid-area: details2;
      align-self: center;
    }

    &-rbr-image-4__img{
      grid-area: img4;
    }

    &-details-3__div{
      grid-area: details3;
      align-self: center;
    }

    &-pricing-border__div{
      width: 40%;
    }

    &-header__h4{
      font-size: 25px;
    }

    &-header-accent-included__div{
      width: 5%;
    }

    &-included__div{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      align-items: center;
      margin: 50px 0;
    }

    &-partners-border__div{
      width: 40%;
    }

    &-partners-grid__div{
      max-width: none;
      grid-template-columns: repeat(2, 1fr);
    }

    &-contact-iframe__iframe{
      width: 500px;
    }

    &-gallery-border__div{
      width: 40%;
    }

    &-gallery-grid__div{
      grid-template-columns: 1fr 1fr;
    }

    &-gallery-couples__div{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-title-border__div{
      width: 600px;
    }

    &-title__div{
      font-size: 25px;
    }

    &-intro__div{
      max-width: 1366px;
      margin: 100px auto;
    }

    &-rbr-image__img{
      width: 500px;
    }

    &-details__div{
      font-size: 18px;
    }

    &-pricing-banner__div{
      height: 500px;
    }

    &-pricing-border__div{
      width: 400px;
    }

    &-pricing-header__div{
      font-size: 20px;
    }

    &-pricing-details__div{
      max-width: 1366px;
      margin: auto;
    }

    &-header__h4{
      font-size: 25px;
    }

    &-header-accent__div{
      width: 50px;
    }

    &-partners-banner__div{
      height: 500px;
    }

    &-partners-border__div{
      width: 400px;
    }

    &-partners-header__div{
      font-size: 20px;
    }

    &-partners-grid__div{
      grid-template-columns: repeat(3, 1fr);
      max-width: 1366px;
      margin: auto;
    }

    &-gallery-banner__div{
      height: 500px;
    }

    &-gallery-border__div{
      width: 400px;
    }

    &-gallery-header__div{
      font-size: 20px;
    }

    &-gallery-grid__div{
      grid-template-columns: repeat(3, 1fr);
    }

    &-gallery-img__div{
      height: 450px;
    }

    &-gallery-iframe__iframe{
      width: 800px;
      height: 800px;
    }

    &-gallery-couples__div{
      max-width: 1366px;
      margin: auto;
    }
  }
  }

  @keyframes headerIntro {
    0% {
      width: 0;
      height: 0;
      border-top-color: $default-red;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    50% {
      width: 100%;
      height: 0;
      border-top-color: $default-red;
      border-right-color: $default-red;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

    100% {
      width: 100%;
      height: 100;
      border-top-color: rgba(255, 255, 255, .8);
      border-right-color: rgba(255, 255, 255, .8);
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }

  @keyframes headerIntro2 {
    0% {
      width: 0;
      height: 0;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: $default-red;
    }
    50% {
      width: 0;
      height: 100%;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: $default-red;
      border-left-color: $default-red;
    }

    100% {
      width: 100%;
      height: 100;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: rgba(255, 255, 255, .8);
      border-left-color: rgba(255, 255, 255, .8);
    }
  }

  @keyframes headerBackground {
    from {
      background-color: transparent;
    }

    to {
      background-color: rgba(191, 46, 26, .3);
    }
  }