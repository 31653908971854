@import '../../scss/variables';

.productlist{
  &-content__div{
    padding: 5px;
    // width: 95%;
  }

  &-list__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 20px;
  }

  &-product__div{
    padding: 5px;
  }

  &-link__link{
    text-decoration: none;
    color: black;
    transition: all .5s ease;
  }

  &-productimage__div{
    width: 150px;
    display: block;
    margin: 10px auto;
    padding: 5px;
  }

  &-productimage__img{
    width: 100%;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
  }

  &-producttitle__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 5px;
  }

  &-productprice__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 5px;
  }

  @media only screen and (min-width: 766px) {

    &-content__div{
      display: grid;
      grid-template-columns: 205px 1fr;
      grid-column-gap: 10px;
    }

    &-main__main{
      // border-left: 3px solid #efefef;
      border-left: 3px solid #efefef;
    }
    &-list__div{
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-productimage__div{
      width: 125px;
      height: 225px;
    }

    &-productimage__img{
      max-height: 225px;
    }

    &-details__div{
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
      border-left: 3px solid #efefef;
    }

    &-content__div{
      grid-template-columns: 275px 1fr;
    }
    &-link__link{
      &:hover{
        color: $default-red;
      }
    }

    &-productimage__div{
      height: 250px;
    }

    &-productimage__img{
      max-height: 250px;
      transition: .5s all ease;
      &:hover{
        transform: translateY(-5px);
      }
    }
    &-list__div{
      grid-template-columns: repeat(4, 1fr);
    }
    &-productimage__div{
      width: 150px;
    }
    &-details__div{
      // font-size: 18px;
    }
  }
}