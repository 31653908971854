@import '../../scss/variables';

.emailverify{
  &-content__div{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    padding: 5px;
    margin: 200px 0;
  }
  &-header__h3{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}