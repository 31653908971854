@import '../../scss/variables';

.strandcastlist{
  &-items__div{
    display: grid;
    grid-template-columns: 1fr;
  }

  &-item__div{
    padding: 5px;
    margin: 20px;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
  }

  &-header__h3{
    margin: 0px 5px 5px 5px;
    color: $default-red;
  }

  &-accent__div{
    width: 25px;
    height: 4px;
    background-color: $default-yellow;
    margin-left: 5px;
  }

  &-header__h4{
    margin: 5px 5px 5px 5px;
  }

  &-header__h5{
    margin: 5px;
    font-size: 15px;
  }

  &-details__p{
    margin: 0px 5px 5px 5px;
  }

  &-link__link{
    text-decoration: none;
    color: $default-black;
  }
  
  &-media__div{
    overflow: hidden;
  }

  @media only screen and (min-width: 767px) {
    &-items__div{
      grid-template-columns: 1fr 1fr;
    }

  }

  @media only screen and (min-width: 1023px) {
    &-items__div{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media only screen and (min-width: 1439px) {
    &-items__div{
      grid-template-columns: repeat(3, 1fr);
    }
  }
}