@import '../../scss/variables';

.home{
  &-content__div{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    'vote'
    'hero'
    'highlights'
    'carousel'
    'carousel-2'
    'events'
    'locations';
    position: relative;
  }

  &-hero__section{
    grid-area: hero;
    min-width: 0;
  }

  &-hero__div{
    margin: 50px 0 0 0;
  }

  &-vote__section{
    grid-area: vote;
  }

  &-events__section{
    grid-area: events;
  }

  &-highlights__section{
    grid-area: highlights;
  }

  &-events__div{
    position: relative;
  }

  &-carousels__section{
    grid-area: carousel;
    min-width: 0;
  }

  &-subbanner__section{
    grid-area: subbanner;
  }

  &-other-carousels__section{
    grid-area: carousel-2;
    min-width: 0;
  }

  &-locations__section{
    grid-area: locations;
  }

  &-hero__div{
    min-width: 0;
  }

  &-carousel__div{
    min-width: 0;
  }

  &-subbanner__div{
    min-width: 0;
  }

  &-notice__div{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    line-height: 1.7;
    padding: 5px;
    margin: 0px 10px;
    text-align: center;
    font-weight: 600;
  }

  &-notice__p{
    font-family: inherit;
    font-size: inherit;
    color: $default-red;
    font-weight: inherit;
  }

  &-locations__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: start;
    margin-bottom: 50px;
    // margin-left: 30px;
  }

  &-location__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    text-align: center;
    padding: 10px;
    margin: 10px auto;
  }

  &-location-image__div{
    border-radius: 50%;
    border: 5px solid $default-red;
    width: 275px;
    height: 275px;
    overflow: hidden;
    margin: 25px auto 50px auto;
  }

  &-location-image__img{
    width: 100%;
  }

  &-location-details__div{
    text-align: center;
  }

  &-location-name__h3{
    font-family: inherit;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-accent__div{
    width: 30px;
    height: 3px;
    background-color: $default-red;
    margin: 5px auto 15px auto;
  }

  &-location-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  @media only screen and (min-width: 767px) {
    &-locations__div{
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
      "usq uws"
      "cpk lga";
      justify-items: center;
      margin: 0 auto 50px auto;
    }

    &-location-usq__div{
      grid-area: usq;
    }

    &-location-uws__div{
      grid-area: uws;
    }

    &-location-lga__div{
      grid-area: cpk;
    }

    &-location-lga__div{
      grid-area: lga;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }

    &-content__div{
      display: grid;
      grid-template-columns: 60% 1fr;
      grid-template-rows: auto;
      grid-template-areas:
      'vote vote'
      'hero hero'
      'highlights highlights'
      'carousel events'
      'carousel-2 carousel-2'
      'locations locations';
    }

    &-carousels__div{
      position: relative;
      top: 65px;
    }

    &-events__div{
      position: relative;
    }

    &-other-carousels__div{
      margin-top: 100px;
    }

    &-hero__div{
      min-width: 0;
      margin: 25px 0 0 0;
    }

    &-carousel__div{
      min-width: 0;
    }

    &-subbanner__div{
      min-width: 0;
    }

    &-locations__section{
      position: relative;
      top: 25px;
    }

    &-notice__div{
      font-size: 15px;
    }

    &-locations__div{
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: "usq uws cpk lga";
      max-width: 1366px;
    }
  }
}