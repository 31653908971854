@import '../../scss/variables';

.singleproductpage{
  &-container__div{
    width: 100%;
  }

  &-main__div{
    margin-top: 50px;
  }

  &-item__div{
    padding: 5px 0;
    margin-top: 20px;
  }

  &-item-image__div{
    width: 150px;
    display: block;
    margin: auto;
    padding: 5px;
  }

  &-item-image__img{
    width: 100%;
    display: block;
    margin: auto;
  }

  &-item-header__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
  }

  &-item-title__h1{
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }

  &-item-originator__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-item-binding__p{
    font-family: inherit;
    font-size: inherit;
    margin: 10px 0 0 0;
  }

  &-item-format__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
  }

  &-item-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
    padding: 5px 0;
    line-height: 1.5;
  }

  &-item-inventory__div{
    padding: 10px;
    margin: 15px auto;
    background-color: $default-light-gray;
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: auto;
  }

  &-item-option__div{
    width: 100%;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    grid-template-areas: 
    'content content prices'
    'scarcity scarcity discount'
    'icons select add';
    border: 2px solid #bababa;
    margin: 40px 0;
    padding: 10px 0;
    position: relative;
  }

  &-drop-tab__div{
    position: absolute;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-family: $default-sans-serif-font;
    font-size: 11px;
    padding: 4px 7px;
    top: -33px;
    left: -2px;
  }

  &-drop-tab__div--warehouse{
    background-color: $default-yellow;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  &-drop-tab__p{
    font-family: inherit;
    font-size: inherit;
    position: relative;
    top: 2px;
  }

  &-item-option__div--disabled{
    cursor: not-allowed;
    background-color: #e6e6e6;
  }

  &-item-detail-radio__radio{
    grid-area: radio;
    width: 50px;
    height: 50px;
    align-self: center;
  }

  &-item-detail-content__div{
    grid-area: content;
    padding: 5px;
    margin-bottom: 5px;
    justify-content: center;
  }

  &-item-detail-prices__div{
    grid-area: prices;
    display: flex;
    flex-flow: row wrap;
    justify-self: center;
    padding: 5px;
    margin-bottom: 5px;
    align-self: center;
  }

  &-item-detail-discount{
    grid-area: discount;
    text-align: center;
    padding: 5px;
  }

  &-item-detail-scarcity__div{
    grid-area: scarcity;
    justify-self: center;
  }

  &-catalog-icons__div{
    grid-area: icons;
    display: flex;
    flex-flow: row wrap;
    padding: 5px;
    align-items: center;
    color: $default-red;
  }



  &-item-detail__p{
    font-family: inherit;
    font-size: inherit;
    padding: 0 5px;
  }

  &-item-detail-signed__p{
    color: $default-red;
    padding: 5px;
  }

  &-item-detail__p--gray{
    color: $default-red;
  }

  &-item-detail__p--red{
    color: $default-red;
  }

  &-item-detail__p--strikethrough{
    text-decoration-line: line-through;
  }

  &-item-detail__p--nodisplay{
    display: none;
  }

  &-item-detail__p--out{
    &:before{
      content: 'Out of Stock! '
    }
    &:after{
      display: none;
    }
  }

  &-item-qty__div{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    grid-area: select;
    justify-self: end;
    position: absolute;
    margin-top: 15px;
    right: 10px;
  }

  &-item-qty__p{
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
  }

  &-item-select__select{
    color: $default-red;
    background-color: $default-cream;
    border: 1px solid $default-light-gray;
    margin: 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;

    &:focus{
      outline: none;
    }
  }

  &-item-button__div{
    grid-area: add;
    display: flex;
    flex-flow: column wrap;
    justify-items: center;
    max-width: 125px;
    margin: 10px auto 0 auto;
  }

  &-item-more__div{
    margin-top: 40px;    
    background-color: $default-cream;
    padding-bottom: 25px;
  }

  &-item-more-content__div{
    text-align: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-item-more-header__h2{
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    padding-top: 25px;
    margin-bottom: 10px;
  }

  &-item-more-dash__div{
    width: 40px;
    height: 3px;
    background-color: $default-red;
    display: block;
    margin: 10px auto;
  }

  &-item-more__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-item-more-desc__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    margin: 25px 10px 10px 10px;
  }

  &-item-more-desc-header__h3{
    font-family: inherit;
    font-size: 15px;
    font-weight: 600;
  }

  &-anno-btn__div{
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: 767px){
    &-main__div{
      display: grid;
      grid-template-columns: 250px 1fr;
      grid-template-rows: auto;
      grid-template-areas:
      '. details'
      'image details'
      'header details'
      'submit details'
      '. details';
      width: 750px;
      margin: 50px auto;
    }
    &-item-image__div{
      grid-area: image;
      width: 175px;
    }

    &-item-header__div{
      grid-area: header;
      padding: 10px;
      // font-size: 18px;
    }

    &-item-title__h1{
      // font-size: 22px;
    }

    &-item-details__div{
      grid-area: details;
      display: flex;
      justify-content: center;
    }

    &-item-option__div{
      // width: 100%;
      width: 450px;
    //   grid-template-columns: 1fr 50px 150px;
      grid-template-columns: 1fr 1fr 150px;
    //   grid-template-areas:
    //   'content content prices'
    //   'scarcity scarcity discount'
    //   '. select add';
      grid-template-areas: 
      'content content prices'
      'scarcity scarcity discount'
      'icons select add';
      // font-size: 18px;
      // margin: 20px 0;
    }

    &-drop-tab__div{
      font-size: 15px;
    }

    &-item-detail-content__div{
      align-self: center;
      justify-self: center;
      display: flex;
      flex-flow: row wrap;
    }

    &-item-detail-signed__p{
        // color: #eca900;
        color: $default-red;
        padding: 0px;
      }

    &-item-detail-prices__div{
      align-self: end;
    //   font-size: 18px;
    }

    &-item-detail__p{
      margin-left: 5px;
    }

    &-item-detail-discount{
      font-size: 15px;
    }

    &-item-qty__div{
    //   font-size: 18px;
    right: -15px;
    }

    &-item-select__select{
      font-size: 15px;
    }

    // &-item-detail-scarcity__div{
    // //   align-self: start;
    // }

    &-item-button__div{
        justify-self: start;
      }

    // &-item-button__div{
    //   grid-area: submit;
    //   padding: 10px;
    // }

    &-item-more__div{
      grid-area: more;
    }

    &-item-more-content__div{
      font-size: 15px;
      text-align: left;
      padding-left: 20px;
    }

    &-item-more-header__h2{
      // font-size: 22px;
      text-align: center;
    }

    &-item-more__p{
      text-align: left;
      margin-left: 20px;
    }

    &-item-more-dash__div{
      // margin: 10px auto 30px 0;
    }

    &-item-more-desc__div{
      // font-size: 18px;
      margin-top: 15px;
    }

    &-annotation__div{
      padding-left: 10px;
    }
    &-item-more-desc-header__h3{
      // font-size: 18px;
      margin-left: 10px;
    }

    &-location__h3{
      margin-left: 20px;
    }

    &-location__p{
      margin-left: 15px;
    }
  }

  @media only screen and (min-width: 1023px){
    &-main__div{
      width: 800px;
      margin: 50px auto;
    }

    &-item-more-content__div{
      width: 800px;
      margin: auto;
    }

  }
}
