@import '../../scss/variables';

$memorial-bg-color: #f2e8c5;
$memorial-spacing: 15px;
$memorial-spacing-desktop: 20px;
$memorial-head-spacing: 30px;
$memorial-head-spacing-desktop: 50px;

.memorial {
    &-link__link {
        color: $default-red;
        text-decoration: none;
    }

    &-container__div {
        max-width: 1440px;
        margin: 0 auto;
    }

    &-content__main {
        background-color: $memorial-bg-color;
        padding: 15px 10px;

        @media only screen and (min-width: 767px) {
            padding: 40px;
        }
    }

    &-content__div {
        color: $default-dark-gray;
        text-align: center;
        border: 2px solid $default-red;
        padding: 20px;

        @media only screen and (min-width: 767px) {
            padding: 20px 40px;
        }
    }

    &-divider__div {
        position: relative;
        margin: $memorial-head-spacing 0;
        z-index: 0;

        @media only screen and (min-width: 767px) {
            margin: $memorial-head-spacing-desktop;
        }

        &::before {
            content: '';
            background-color: $default-red;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 2px;
            z-index: -1;
        }
    }

    &-divider-heading__h2 {
        background-color: $memorial-bg-color;
        color: $default-red;
        display: inline-block;
        font-size: 24px;
        width: auto;
        margin: 0;
        padding: 0 20px;

        @media only screen and (min-width: 767px) {
            font-size: 30px;
        }
    }

    &-hero__section {
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }
    }

    &-hero-heading__div {
        margin: 0 0 20px;

        @media only screen and (min-width: 767px) {
            margin: 0 0 30px;
        }
    }

    &-hero-heading__h1 {
        font-family: $default-serif-font;
        font-weight: normal;
        font-size: 24px;
        margin: 0;

        @media only screen and (min-width: 767px) {
            font-size: 30px;
        }
    }

    &-hero-image__img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    &-intro__section {
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }
    }

    &-recommends__section {
        margin: 0 0 $memorial-head-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-head-spacing-desktop;
        }
    }

    &-recommends-text_div {
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }
    }

    &-recommends-slider__div {
        font-family: $default-sans-serif-font;
        font-size: 14px;
        text-align: left;
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }

        .slick-dots {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
        }
    }

    &-recommends-slide-inner__div {
        padding: 0 15px;

        @media only screen and (min-width: 450px) {
            padding: 0 20px;
        }
    }

    &-recommneds-product-image__div {
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }
    }

    &-recommends-product-image__img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    &-recommends-product-title__link {
        color: inherit;
        text-decoration: none;
        font-weight: bold;
    }

    &-recommends-button__link {
        background-color: transparent;
        display: inline-block;
        font-family: $default-serif-font;
        text-decoration: none;
        color: $default-red;
        min-width: 130px;
        font-size: 18px;
        border: 2px solid $default-red;
        border-radius: 4px;
        padding: 4px 20px;
        text-align: center;
        line-height: 1.5;

        &:hover {
            background-color: $default-red;
            color: #ffffff;
        }
    }

    &-columns__section {
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }
    }

    &-columns-row__div {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px -15px;

        @media only screen and (min-width: 767px) {
            margin: 0 -20px -20px;
        }
    }

    &-columns-col__div {
        flex: 0 1 auto;
        width: 100%;
        padding: 0 10px 15px;
        box-sizing: border-box;

        @media only screen and (min-width: 767px) {
            width: 50%;
            padding: 0 20px 20px;
        }
    }

    &-columns-head__h2 {
        color: $default-red;
        font-size: 20px;

        @media only screen and (min-width: 767px) {
            font-size: 28px;
        }
    }

    &-columns-asset__div {
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }
    }

    &-columns-asset__img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    &-columns-asset-wrapper__div {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
    }

    &-columns-asset__iframe {
        position: absolute;
        object-fit: contain;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &-columns-text__div {
        margin: 0 0 $memorial-spacing;

        @media only screen and (min-width: 767px) {
            margin: 0 0 $memorial-spacing-desktop;
        }
    }

    &-columns-button__link {
        background-color: transparent;
        display: inline-block;
        font-family: $default-serif-font;
        text-decoration: none;
        color: $default-red;
        min-width: 110px;
        font-size: 16px;
        border: 2px solid $default-red;
        border-radius: 4px;
        padding: 4px 20px;
        text-align: center;
        line-height: 1.5;

        &:hover {
            background-color: $default-red;
            color: #ffffff;
        }
    }

    &-columns-bottom-text__div {
        margin: $memorial-spacing 0 0;

        @media only screen and (min-width: 767px) {
            margin: $memorial-spacing-desktop 0 0;
        }
    }

    &-photos-text__div {
        margin: 0 0 30px;
    }

    &-photos-slides__div {
        width: 260px;
        margin: 0 auto 20px;

        @media only screen and (min-width: 450px) {
            width: 400px;
        }

        @media only screen and (min-width: 767px) {
            width: 600px;
            margin: 0 auto 30px;
        }
    }

    &-photos-slides-container__div {
        position: relative;
        width: 100%;
        margin: 0 0 10px;
        padding-top: 75%;
    }

    &-photos-flickr__img {
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    &-photos-button__link {
        background-color: transparent;
        display: inline-block;
        font-family: $default-serif-font;
        text-decoration: none;
        color: $default-red;
        min-width: 130px;
        font-size: 18px;
        border: 2px solid $default-red;
        border-radius: 4px;
        padding: 4px 20px;
        text-align: center;
        line-height: 1.5;

        &:hover {
            background-color: $default-red;
            color: #ffffff;
        }
    }
}