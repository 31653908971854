@import '../../scss/variables';

.myaccountorders{
  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 20px;
    margin: 5px;
    font-weight: 400;
    padding-left: 10px;
  }
    
  &-dash__div{
    width: 30px;
    margin: 10px 15px;
    height: 3px;
    background-color: $default-red;
  }

  &-text-icon__div{
  }

  &-text-icon__p{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    color: $default-red;
    &:hover{
      color: $hover-red;
      text-decoration: underline;
    }
  }
  }