@import '../../scss/variables';

.error{
  &-content__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 5px;
    margin: 50px 0;
  }

  &-image__div{
    width: 125px;
    margin: auto;
  }

  &-image__img{
    width: 100%;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    text-align: center;
    color: $default-red;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    text-align: center;
    line-height: 1.7;
  }

  &-btn__div{
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 15px 0;
  }

  &-btn__link{
    color: #ffffff;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    background-color: $default-red;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
  
    &:hover{
      background-color: $hover-red;
    }
  }

  &-error-log__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    text-align: center;
  }

  &-message__p{
    font-family: inherit;
    font-size: inherit;
  }
}