@import '../../scss/variables';

.readsolutions{
  &-hero__div{
    width: 100%;
    margin: 10px 0;
  }

  &-hero__img{
    width: 100%;
  }

  &-list__div{
    margin: 50px 0;
  }

  &-header__h3{
    padding: 5px;
    margin: 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-link__a{
    text-decoration: none;
    color: $default-red;
    font-family: inherit;
    font-size: inherit;
  }
}