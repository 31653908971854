@import '../../scss/variables';

.strandcast{
  &-container__div{
    background-color: #ffd112;
    // margin-bottom: 50px;
  }

  &-logo__div{
    width: 100%;
    padding-top: 50px;
  }

  &-logo__img{
    width: 100%;
  }

  &-info-details__div{
    top: 50%;
    text-align: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.7;
    padding: 10px;
    z-index: 2;
  }

  &-link__a{
    text-decoration: none;
    color: #a52413;
    font-weight: 600;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 25px;
    color: $default-red;
    text-align: center;
    margin: 10px;
  }

  &-divider__div{
    width: 80%;
    background-color: $default-red;
    height: 4px;
    margin: 15px auto;
  }

  @media only screen and (min-width: 767px) {
    &-logo__div{
      width: 350px;
      margin: auto;
    }

    &-info-details__div{
      max-width: 800px;
      margin: auto;
    }
    &-header__h2{
      font-size: 30px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-logo__div{
      width: 375px;
    }
    &-logo__img{
        display: block;
      }

    &-content__div{
      max-width: 1366px;
      margin: 0 auto;
    }

    &-link__a{
      &:hover{
        color: $hover-red;
      }
    }
  }
}