@import '../../scss/variables';

.highlight{
  &-content__div{
    display: grid;
    grid-template-columns: 1fr;
    padding: 5px;
    margin: 10px auto;
    justify-items: center;
  }

  @media only screen and (min-width: 767px) {
    &-content__div{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 
      'highlight-1 highlight-2'
      'highlight-3 highlight-3';
    }

    &-highlight-1__highlightitem{
      grid-area: highlight-1;
      justify-self: start;
    }

    &-highlight-2__highlightitem{
      grid-area: highlight-2;
    }

    &-highlight-3__highlightitem{
      grid-area: highlight-3;
      justify-self: end;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__div{
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'highlight-1 highlight-2 highlight-3';

    }
  }
}