@import '../../scss/variables';

.drawernav{
  &-link__div{
    text-align: center;
    padding: 5px;
    margin: 0 20px;
  }

  &-header__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  &-header__p{
    text-decoration: none;
    color: black;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 400;
  }

  &-header__p--active{
    color: $default-red;
  }

  &-submenu__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 5px;
    margin-top: 10px;
    line-height: 2.5;
  }

  &-navlink__navlink{
    text-decoration: none;
    color: black;
    font-family: $default-sans-serif-font;
    font-size: 15px;

    &--active{
      color: $default-red;
    }

    &:hover{
      color: $hover-red;
    }
  }

  &-home-icon__home{
    cursor: pointer;
    padding: 5px;
    margin: 20px 0 0 0;
    &:hover{
      fill: $default-red;
    }
  }

  &-border__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    display: block;
    margin: 15px auto 15px auto;
  }
}