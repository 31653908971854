@import '../../scss/variables';

.checkoutcarttotal{
    &-content__div{
      padding: 10px;
      background-color: $default-off-white;
      border: 2px solid $default-content-gray;
      margin: 20px;
    }

    &-detail__div{
      display: grid;
      grid-template-columns: .65fr .35fr;
      grid-template-rows: auto;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      color: #5a5a5a;
      padding: 5px;
    }

    &-header__p{
      justify-self: start;
    }

    &-header__p--strong{
      color: $default-black;
      font-weight: 600;
    }

    &-value__p{
      justify-self: center;
    }

    &-value__p--red{
      color: $default-red;
    }

    &-value__p--strong{
      color: $default-black;
      font-weight: 600;
    }

    &-divider__div{
      width: 75px;
      height: 3px;
      // background-color: #5a5a5a;
      background-color: $default-red;
      justify-self: center;
    }

    &-promo__div{
      margin: 20px 0 15px 0;
      padding: 5px;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
    }

    &-promo-form__div{
      justify-self: start;
    }

    &-notice__span{
      color: $default-red;
    }

    &-notice__div{
      font-family: $default-sans-serif-font;
      font-size: 13px;
      margin: 5px auto;
      padding: 5px;
      border: 2px solid $default-red;
      background-color: rgba(175, 39, 47, .1);
      line-height: 1.3;
    }


    @media only screen and (min-width: 767px) {
      &-promo__div{
        grid-template-columns: 150px 1fr;
      }
    }

    @media only screen and (min-width: 1023px) {
    &-detail__div{
      font-size: 17px;
    }

    &-promo__div{
      font-size: 17px;
    }
    }

}