@import '../../scss/variables';

.bbtfgallery{
  &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/bbtf-gallery-10-dropshadow-text-embedded.png"));
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    }

  &-grid__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
    margin: 75px 0;
    grid-template-areas:
    'g1'
    'g2'
    'g3'
    'g63'
    'g8'
    'g9'
    'g7'
    'g10'
    'g11'
    'g12'
    'g13'
    'g67'
    'g16'
    'g19'
    'g20'
    'g21'
    'g22'
    'g23'
    'g24'
    'g25'
    'g30'
    'g28'
    'g29'
    'g64'
    'g31'
    'g32'
    'g33'
    'g34'
    'g26'
    'g27'
    'g36'
    'g66'
    'g39'
    'g42'
    'g44'
    'g45'
    'g47'
    'g48'
    'g49'
    'g50'
    'g52'
    'g53'
    'g65'
    'g56'
    'g57'
    'g58'
    'g59'
    'g60'
    'g61'
    'g62'
    'g5'
    'g6'
    'g37'
    'g38'
    'g35'
    'g40'
    'g14'
    'g15'
    'g17'
    'g18'
    'g41'
    'g54'
    'g55';
  }

  &-photo-container__div{
    height: 300px;
    width: 100%;
    margin: 15px 0;
    cursor: pointer;

    &:focus{
        outline: none;
        border: none;
      }
  }

  &-photo__div{
    width: 100%;
    height: 300px;

    &:focus{
        outline: none;
        border: none;
      }
  }

  &-photo__img{
    width: 100%;
  }

  &-review__div{
    font-family: $default-serif-font; 
    font-size: 15px;
    padding: 5px;
    text-align: center;
    line-height: 2;
    color: $default-dark-gray;
    font-style: italic;
  }

  &-review__p{
    font-family: inherit;
    font-size: inherit;
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      height: 500px;
    }
    
    &-grid__div{
      grid-template-areas: 
      'g1 g1 g2'
      'g1 g1 g3'
      'g4 g63 g63'
      'g7 g8 g9'
      'g7 g10 g11'
      'g12 g13 g13'
      'g67 g67 g16'
      'g67 g67 g19'
      'g20 g20 g21'
      'g20 g20 g22'
      'g23 g24 g25'
      'g23 g30 g30'
      'g28 g29 g29'
      'g64 g64 g31'
      'g32 g33 g33'
      'g34 g33 g33'
      'g34 g26 g27'
      'g36 g36 g36'
      'g66 g66 g39'
      'g66 g66 g42'
      'g44 g46 g42'
      'g45 g47 g47'
      'g48 g48 g49'
      'g48 g48 g50'
      'g52 g53 g53'
      'g52 g65 g65'
      'g56 g57 g58'
      'g59 g60 g60'
      'g61 g60 g60'
      'g62 g5 g6'
      'g37 g38 g35'
      'g40 g14 g15'
      'g40 g17 g18'
      'g41 g54 g55';
    
    grid-template-columns: 1fr 1fr 1fr;
    }

    &-photo-container__div{
      margin: 0;
      width: 100%;
      height: 250px;
    }

    &-photo__div{
      width: 100%;
    height: 100%;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }

    &-grid__div{
      grid-template-columns: 1fr 1fr 1fr 1fr;
      overflow: hidden;
      grid-template-areas: 
      'g1 g1 g2 g3'
      'g1 g1 g4 g7'
      'g8 g9 g63 g63'
      'g10 g10 g11 g12'
      'g20 g20 g13 g13'
      'g20 g20 g16 g19'
      'g67 g67 g21 g22'
      'g67 g67 g24 g25'
      'g23 g30 g30 g28'
      'g23 g64 g64 g31'
      'g29 g29 g33 g33'
      'g34 g32 g33 g33'
      'g34 g26 g26 g26'
      'g27 g27 g27 g36'
      'g66 g66 g39 g42'
      'g66 g66 g46 g42'
      'g44 g45 g47 g47'
      'g48 g48 g49 g50'
      'g48 g48 g52 g50'
      'g65 g65 g53 g53'
      'g56 g57 g58 g59'
      'g60 g60 g61 g62'
      'g60 g60 g5 g6'
      'g37 g38 g35 g14'
      'g40 g15 g17 g18'
      'g40 g41 g54 g55';
    }

    &-photo-container__div{
      overflow: hidden;
    }

    &-photo-image__div{
      transition: 3s all ease;
      &:hover{
        transform: scale(1.2);
      }
    }
  }

  @media only screen and (min-width: 2560px) {
    &-review__div{
      font-size: 25px;
    }
  }

  @media only screen and (min-width: 1440px) {
    &-hero__div{
      height: 600px;
    }
  }
  @media only screen and (min-width: 767px) {
    &-review__div{
        font-size: 18px;
    }
  }
}

#gallery01{
    grid-area: g1;
}

#gallery02{
    grid-area: g2;
}
#gallery03{
    grid-area: g3;
}
#gallery04{
    grid-area: g4;
}
#gallery05{
    grid-area: g5;
}
#gallery06{
    grid-area: g6;
}
#gallery07{
    grid-area: g7;
}
#gallery08{
    grid-area: g8;
}
#gallery09{
    grid-area: g9;
}
#gallery10{
    grid-area: g10;
}
#gallery11{
    grid-area: g11;
}
#gallery12{
    grid-area: g12;
}
#gallery13{
    grid-area: g13;
}
#gallery14{
    grid-area: g14;
}
#gallery15{
    grid-area: g15;
}
#gallery16{
    grid-area: g16;
}
#gallery17{
    grid-area: g17;
}
#gallery18{
    grid-area: g18;
}
#gallery19{
    grid-area: g19;
}
#gallery20{
    grid-area: g20;
}
#gallery21{
    grid-area: g21;
}
#gallery22{
    grid-area: g22;
}
#gallery23{
    grid-area: g23;
}
#gallery24{
    grid-area: g24;
}
#gallery25{
    grid-area: g25;
}
#gallery26{
    grid-area: g26;
}
#gallery27{
    grid-area: g27;
}
#gallery28{
    grid-area: g28;
}
#gallery29{
    grid-area: g29;
}
#gallery30{
    grid-area: g30;
}
#gallery31{
    grid-area: g31;
}
#gallery32{
    grid-area: g32;
}
#gallery33{
    grid-area: g33;
}
#gallery34{
    grid-area: g34;
}
#galler35{
    grid-area: g35;
}
#gallery36{
    grid-area: g36;
}
#gallery37{
    grid-area: g37;
}
#gallery38{
    grid-area: g38;
}
#gallery39{
    grid-area: g39;
}
#gallery40{
    grid-area: g40;
}
#gallery41{
    grid-area: g41;
}
#gallery42{
    grid-area: g42;
}
#gallery43{
    grid-area: g43;
}
#gallery44{
    grid-area: g44;
}
#gallery45{
    grid-area: g45;
}
#gallery046{
    grid-area: g46;
}
#gallery47{
    grid-area: g47;
}
#gallery48{
    grid-area: g48;
}
#gallery49{
    grid-area: g49;
}
#gallery50{
    grid-area: g50;
}
#gallery51{
    grid-area: g51;
}
#gallery52{
    grid-area: g52;
}
#gallery53{
    grid-area: g53;
}
#gallery54{
    grid-area: g54;
}
#gallery55{
    grid-area: g55;
}
#gallery56{
    grid-area: g56;
}
#gallery57{
    grid-area: g57;
}
#gallery58{
    grid-area: g58;
}
#gallery59{
    grid-area: g59;
}
#gallery60{
    grid-area: g60;
}
#gallery61{
    grid-area: g61;
}
#gallery62{
    grid-area: g62;
}
#gallery63{
    grid-area: g63;
    position: relative;
    top: 10%;
}
#gallery64{
    grid-area: g64;
    position: relative;
    top: 10%;
}
#gallery65{
    grid-area: g65;
    position: relative;
    top: 25%;
}
#gallery66{
    grid-area: g66;
    position: relative;
    top: 10%;
}
#gallery67{
    grid-area: g67;
    position: relative;
    top: 10%;
}

@media only screen and (min-width: 767px) {
    #gallery01{
        grid-area: g1;
        height: 500px;
    }
    
    #gallery02{
        grid-area: g2;
    }
    #gallery03{
        grid-area: g3;
    }
    #gallery04{
        grid-area: g4;
    }
    #gallery05{
        grid-area: g5;
    }
    #gallery06{
        grid-area: g6;
    }
    #gallery07{
        height: 500px;
        grid-area: g7;
    }
    #gallery08{
        grid-area: g8;
    }
    #gallery09{
        grid-area: g9;
    }
    #gallery10{
        grid-area: g10;
    }
    #gallery11{
        grid-area: g11;
    }
    #gallery12{
        grid-area: g12;
    }
    #gallery13{
        grid-area: g13;
    }
    #gallery14{
        grid-area: g14;
    }
    #gallery15{
        grid-area: g15;
    }
    #gallery16{
        grid-area: g16;
    }
    #gallery17{
        grid-area: g17;
    }
    #gallery18{
        grid-area: g18;
    }
    #gallery19{
        grid-area: g19;
    }
    #gallery20{
        height: 500px;
        grid-area: g20;
    }
    #gallery21{
        grid-area: g21;
    }
    #gallery22{
        grid-area: g22;
    }
    #gallery23{
        height: 500px;
        grid-area: g23;
    }
    #gallery24{
        grid-area: g24;
    }
    #gallery25{
        grid-area: g25;
    }
    #gallery26{
        grid-area: g26;
    }
    #gallery27{
        grid-area: g27;
    }
    #gallery28{
        grid-area: g28;
    }
    #gallery29{
        grid-area: g29;
    }
    #gallery30{
        grid-area: g30;
    }
    #gallery31{
        grid-area: g31;
    }
    #gallery32{
        grid-area: g32;
    }
    #gallery33{
        height: 500px;
        grid-area: g33;
    }
    #gallery34{
        height: 500px;
        grid-area: g34;
    }
    #galler35{
        grid-area: g35;
    }
    #gallery36{
        grid-area: g36;
    }
    #gallery37{
        grid-area: g37;
    }
    #gallery38{
        grid-area: g38;
    }
    #gallery39{
        grid-area: g39;
    }
    #gallery40{
        height: 500px;
        grid-area: g40;
    }
    #gallery41{
        grid-area: g41;
    }
    #gallery42{
        grid-area: g42;
        height: 500px;
    }
    #gallery43{
        grid-area: g43;
    }
    #gallery44{
        grid-area: g44;
    }
    #gallery45{
        grid-area: g45;
    }
    #gallery046{
        grid-area: g46;
    }
    #gallery47{
        grid-area: g47;
    }
    #gallery48{
        grid-area: g48;
        height: 500px;
    }
    #gallery49{
        grid-area: g49;
    }
    #gallery50{
        grid-area: g50;
    }
    #gallery51{
        height: 500px;
        grid-area: g51;
    }
    #gallery52{
        grid-area: g52;
        height: 500px;
    }
    #gallery53{
        grid-area: g53;
    }
    #gallery54{
        grid-area: g54;
    }
    #gallery55{
        grid-area: g55;
    }
    #gallery56{
        grid-area: g56;
    }
    #gallery57{
        grid-area: g57;
    }
    #gallery58{
        grid-area: g58;
    }
    #gallery59{
        grid-area: g59;
    }
    #gallery60{
        grid-area: g60;
        height: 500px;
    }
    #gallery61{
        grid-area: g61;
    }
    #gallery62{
        grid-area: g62;
    }
    #gallery63{
        grid-area: g63;
        position: relative;
        top: 10%;
    }
    #gallery64{
        grid-area: g64;
        position: relative;
        top: 15%;
    }
    #gallery65{
        grid-area: g65;
        position: relative;
        top: 5%;
    }
    #gallery66{
        grid-area: g66;
        position: relative;
        top: 25%;
    }
    #gallery67{
        grid-area: g67;
        position: relative;
        top: 25%;
    }
}

@media only screen and (min-width: 1023px) {
    #gallery01{
        grid-area: g1;
        height: 500px;
    }
    
    #gallery02{
        grid-area: g2;
    }
    #gallery03{
        grid-area: g3;
    }
    #gallery04{
        grid-area: g4;
    }
    #gallery05{
        grid-area: g5;
    }
    #gallery06{
        grid-area: g6;
    }
    #gallery07{
        height: 250px;
        grid-area: g7;
    }
    #gallery08{
        grid-area: g8;
    }
    #gallery09{
        grid-area: g9;
    }
    #gallery10{
        grid-area: g10;
    }
    #gallery11{
        grid-area: g11;
    }
    #gallery12{
        grid-area: g12;
    }
    #gallery13{
        grid-area: g13;
    }
    #gallery14{
        grid-area: g14;
    }
    #gallery15{
        grid-area: g15;
    }
    #gallery16{
        grid-area: g16;
    }
    #gallery17{
        grid-area: g17;
    }
    #gallery18{
        grid-area: g18;
    }
    #gallery19{
        grid-area: g19;
    }
    #gallery20{
        height: 500px;
        grid-area: g20;
    }
    #gallery21{
        grid-area: g21;
    }
    #gallery22{
        grid-area: g22;
    }
    #gallery23{
        height: 500px;
        grid-area: g23;
    }
    #gallery24{
        grid-area: g24;
    }
    #gallery25{
        grid-area: g25;
    }
    #gallery26{
        grid-area: g26;
    }
    #gallery27{
        grid-area: g27;
    }
    #gallery28{
        grid-area: g28;
    }
    #gallery29{
        grid-area: g29;
    }
    #gallery30{
        grid-area: g30;
    }
    #gallery31{
        grid-area: g31;
    }
    #gallery32{
        grid-area: g32;
    }
    #gallery33{
        height: 500px;
        grid-area: g33;
    }
    #gallery34{
        height: 500px;
        grid-area: g34;
    }
    #galler35{
        grid-area: g35;
    }
    #gallery36{
        grid-area: g36;
    }
    #gallery37{
        grid-area: g37;
    }
    #gallery38{
        grid-area: g38;
    }
    #gallery39{
        grid-area: g39;
    }
    #gallery40{
        height: 500px;
        grid-area: g40;
    }
    #gallery41{
        grid-area: g41;
    }
    #gallery42{
        grid-area: g42;
        height: 500px;
    }
    #gallery43{
        grid-area: g43;
    }
    #gallery44{
        grid-area: g44;
    }
    #gallery45{
        grid-area: g45;
    }
    #gallery046{
        grid-area: g46;
    }
    #gallery47{
        grid-area: g47;
    }
    #gallery48{
        grid-area: g48;
        height: 500px;
    }
    #gallery49{
        grid-area: g49;
    }
    #gallery50{
        grid-area: g50;
        height: 500px;
    }
    #gallery52{
        grid-area: g52;
        height: 250px;
    }
    #gallery53{
        grid-area: g53;
    }
    #gallery54{
        grid-area: g54;
    }
    #gallery55{
        grid-area: g55;
    }
    #gallery56{
        grid-area: g56;
    }
    #gallery57{
        grid-area: g57;
    }
    #gallery58{
        grid-area: g58;
    }
    #gallery59{
        grid-area: g59;
    }
    #gallery60{
        grid-area: g60;
        height: 500px;
    }
    #gallery61{
        grid-area: g61;
    }
    #gallery62{
        grid-area: g62;
    }
    #gallery63{
        grid-area: g63;
    }
    #gallery64{
        grid-area: g64;
    }
    #gallery65{
        grid-area: g65;
        top: 25%;
    }
    #gallery66{
        height: 500px;
        grid-area: g66;
    }
    #gallery67{
        height: 500px;
        grid-area: g67;
    }
}