@import '../../scss/variables';

.footerdesktop{
  &-border__div{
    height: 3px;
    width: 100%;
    // background-color: $default-cream;
    background-color: $default-off-white;
  }
  &-container__div{
    width: 100%;
    // background-color: $default-cream;
    background-color: $default-off-white;
  }

  &-content__div{
    // border-top: 3px solid #efefef;
    // column-count: 4;
    // column-gap: 15px;
    display: grid;
    grid-template-areas: 
                       'books gifts bbtf nolist'
                       'events rare sell newsletter'
                       'copyright copyright copyright copyright';
  
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    padding: 20px 5px;
    // max-width: 1366px;
    // margin: auto;
    max-width: 1000px;
    margin: auto;
  }

  &-item__div{
    padding: 5px;
  }

  &-header__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    color: $default-red;
  }

  &-list__div{
    display: flex;
    flex-flow: column wrap;
    line-height: 2;
    font-family: $default-sans-serif-font;
    font-size: 13px;
    padding: 5px;
    // margin-left: 15px;
  }

  &-link__navlink{
    text-decoration: none;
    // color: black;
    // color: $default-footer-content-gray;
    color: $default-black;

    &:hover{
      color: $hover-red;
    }
  }

  &-link-header__navlink{
    font-size: 13px;
    font-family: $default-sans-serif-font;
    padding: 5px;
    text-decoration: none;
    // color: $default-footer-content-gray;
    color: $default-black;

    &:hover{
      color: $hover-red;
    }
  }

  &-link-header__a{
    font-size: 13px;
    font-family: $default-sans-serif-font;
    padding: 5px;
    text-decoration: none;
    // color: $default-footer-content-gray;
    color: $default-black;

    &:hover{
      color: $hover-red;
    }
  }

  &-newsletter__div{
    text-align: center;
    font-size: 15px;
    width: 350px;
    margin-top: 8px;
  }

  &-form-header__p{
    margin: 0 0 20px 0;
    color: $default-red;
    font-family: $default-sans-serif-font;
  }

  &-input__input{
    height: 20px;
    width: 200px;
    display: block;
    margin: auto;
    background-color: $default-white;
    border: 1px solid #bfbfbf;
  }

  &-input-submit__input{
    display: block;
    padding: 5px;
    margin: 20px auto;
    font-size: 15px;
    border: 2px solid $default-red;
    border-radius: 4px;
    width: 100px;
    background-color: $default-red;
    color: $default-white;
    cursor: pointer;
  }

  &-copyright__div{
    padding: 5px;
    margin: 40px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    }

  &-copyright-text__p{
    font-size: 15px;
    }
  
    &-copyright-link__navlink{
      text-decoration: none;
      color: black;

      &:hover{
        color: $default-red;
      }
    }

  &-logo__img{
    width: 30px;
    margin: 10px 20px;
    }
}

#footerdesktop{
  &-books__div{
    grid-area: books;
  }
  &-event__div{
    grid-area: events;
    position: relative;
    left: -36px;
    top: -40px;
  }
  &-staffpicks__div{
    grid-area: picks;
  }
  &-gifts__div{
    grid-area: gifts;
  }
  &-rare__div{
    grid-area: rare;
    position: relative;
    top: -40px;
  }
  &-bbtf__div{
    grid-area: bbtf;
    position: relative;
    left: -15px;
  }

  &-item-nolist__div{
    grid-area: nolist;
    position: relative;
    left: -15px;
  }
  &-sell__div{
    grid-area: sell;
    position: relative;
    top: -40px;
  }
  &-bhu__div{
    grid-area: bhu;
  }
  &-cards__div{
    grid-area: cards;
  }
  &-hours__div{
    grid-area: hours;
  }
  &-account__div{
    grid-area: account;
  }
  &-careers__div{
    grid-area: careers;
  }
  &-contact__div{
    grid-area: contact;
  }
  &-blog__div{
    grid-area: blog;
  }
  &-faq__div{
    grid-area: faq;
  }
  &-newsletter-wrapper__div{
    grid-area: newsletter;
  }
  &-copyright-wrapper__div{
    grid-area: copyright;
    display: block;
  }
}