@import '../../scss/variables';

.bbtfcollections{
  &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/bbtf-gallery-10-dropshadow-text-embedded.png"));
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-list__div{
    margin-bottom: 50px;
  }

  &-category__div{
    overflow: hidden;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 5px solid $default-red;
    margin: 50px auto;
    position: relative;
    font-family: $default-sans-serif-font;
    font-size: 25px;
    color: $default-white;
    font-weight: 400;
  }

  &-category-color__div{
    &:after{
        content: 'Collections by Color';
        position: absolute;
        top: 45%;
        right: 11%;
        padding: 10px;
        border: 2px solid $default-white;
        pointer-events: none;
      }
  }

  &-category-style__div{
    &:after{
        content: 'Collections by Style';
        position: absolute;
        top: 45%;
        right: 12%;
        padding: 10px;
        border: 2px solid $default-white;
        pointer-events: none;
      }
  }

  &-category-subject__div{
    &:after{
        content: 'Collections by Subject';
        position: absolute;
        top: 45%;
        right: 7%;
        padding: 10px;
        border: 2px solid $default-white;
        pointer-events: none;
      }
  }

  &-category-bg__div{
    width: 100%;
    height: 300px;
    transition: all 1s ease;
    transform: scale(1.1);
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &:hover{
      transform: scale(1.2);
    }
  }

  &-category-color-bg__div{
    background: url(urlGeneratorSASS("/S/pages/bbtf-gallery-8.jpg"));
  }

  &-category-style-bg__div{
    background: url(urlGeneratorSASS("/S/pages/bbtf-gallery-17.jpg"));
  }

  &-category-subject-bg__div{
    background: url(urlGeneratorSASS("/S/pages/bbtf-gallery-51.jpg"));
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      height: 500px;
    }

    &-list__div{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    &-category__div{
      margin: 10px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }

    &-overlay__div{
      width: 100%;
      height: 300px;
    }

    &-category__div{
      margin: 50px;
    }
  }

  @media only screen and (min-width: 1440px) {
    &-hero__div{
      height: 600px;
    }
  }
}
