@import '../../scss/variables';

.sellyourbooks{
  &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/sellbooks_fred_memorial_max_ferguson.jpg"));
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
  }

  &-header-border__div{
    padding: 10px;
    width: 85%;
    margin: auto;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 3px solid transparent;
      animation: headerIntro3 1.5s linear forwards;
      animation-delay: .75s;
    }

    &:after{
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 3px solid transparent;
      animation: headerIntro4 1.5s linear forwards;
      animation-delay: .75s;
    }
  }

  &-header__div{
    color: $default-white;
    font-size: 15px;
    font-family: $default-serif-font;
    text-align: center;
    padding: 10px;
    animation-name: headerBackgroundSellBooks;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  &-header__h1{
    font-size: 1.75em;
    font-family: inherit;
    font-weight: inherit;
  }
  &-header__h3{
    font-size: 1.200em;
    font-family: inherit;
    font-weight: inherit;
    margin: auto;
    position: relative;
    right: -5px;
    padding: 15px;
  }

  &-content__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    // margin: 0 10px 50px 10px;
    margin-bottom: 50px;
  }

  &-intro__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
  }

  &-intro-image__div{
    width: 100%;
    margin: auto;
  }

  &-intro-image__img{
    width: 100%;
  }

  &-intro__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-intro__p--red{
    color: $default-red;
  }

  &-intro__p--font30{
    font-size: 30px;
  }

  &-note__span{
    font-weight: 600;
    font-size: 15px;
    color: $default-red;
    font-family: inherit;
    text-align: center;
  }

  &-subheader__div{
    color: $default-red;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 10px 5px 5px;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px 10px 5px 5px;
  }

  &-details__span{
    color: $default-red;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    color: $default-red;
  }

  &-header__h2--center{
    text-align: center;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-details__p--strong{
    font-weight: 600;
  }

  &-details__p--center{
    text-align: center;
  }

  &-details-link__a{
    text-decoration: none;
    color: $default-red;
    font-size: inherit;
    font-family: inherit;
  }

  &-details__p--strong{
    font-weight: 600;
  }

  &-steps__div{
    background-color: $default-off-white;
    width: 100%;
    margin: 15px auto;
    padding: 15px 0;
  }

  &-list__ul{
    font-family: inherit;
    font-size: inherit;
    margin-left: 20px;
    margin-top: 10px;
  }

  &-list-item__li{
    font-family: inherit;
    font-size: inherit;
  }

  &-sample__div{
    padding: 5px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: auto;
  }

  &-image__div{
    padding: 5px;
    width: 250px;
    margin: auto;
  }

  &-image__img{
    width: 100%;
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      height: 500px;
    }
    &-header-border__div{
      width: 60%;
    }

    &-header__div{
      padding: 20px;
    }

    &-intro__div{
      background-color: $default-yellow;
      margin: 10px 0;
    }

    &-intro-image__div{
      width: 350px;
    }

    &-content__div{
      width: 700px;
      margin: 0 auto 50px auto;
      display: grid;
      grid-template-columns: 1fr 200px;
    }

    &-note__span{
      font-size: 18px;
    }

    &-sample__div{
      flex-flow: row wrap;
    }

    &-image__div{
      margin: 15px;
    }

    &-image__div--center{
      margin: auto;
    }

    &-packing__div{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-header-border__div{
      width: 600px;
    }

    &-container__div{
      max-width: 1366px;
      margin: auto;
    }

    &-content__div{
      width: 950px;
      font-size: 15px;
    }

    &-steps__div{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-step__div{
      padding: 15px;
    }

    &-step__div--border-right{
      border-right: 2px solid $default-yellow;
    }

    &-details-link__a{
      &:hover{
        color: $hover-red;
        text-decoration: underline;
      }
    }
  }
}

@keyframes headerIntro {
  0% {
    width: 0;
    height: 0;
    border-top-color: $default-red;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: $default-red;
    border-right-color: $default-red;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: rgba(255, 255, 255, .8);
    border-right-color: rgba(255, 255, 255, .8);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes headerIntro3 {
  0% {
    width: 0;
    height: 0;
    border-top-color: #d4af37;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: #d4af37;
    border-right-color: #d4af37;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: rgba(255, 255, 255, .8);
    border-right-color: rgba(255, 255, 255, .8);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes headerIntro2 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: $default-red;
  }
  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: $default-red;
    border-left-color: $default-red;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: rgba(255, 255, 255, .8);
    border-left-color: rgba(255, 255, 255, .8);
  }
}

@keyframes headerIntro4 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #d4af37;
  }
  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #d4af37;
    border-left-color: #d4af37;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: rgba(255, 255, 255, .8);
    border-left-color: rgba(255, 255, 255, .8);
  }
}

@keyframes headerBackgroundSellBooks {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(191, 46, 26, .3);
  }
}