@import '../../scss/variables';

.searchresults{
  &-container__div{
    margin-top: 50px;
  }

  &-sorry__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    padding: 10px;
    text-align: center;
    line-height: 1.7;
    color: $default-footer-content-gray;

    &--black{
      color: $default-black;
    }
  }
  &-sorry__h2--content{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    margin: 50px 15px;
  }

  &-sorry-accent__div{
    width: 90%;
    height: 3px;
    background-color: $default-red;
    margin: 10px auto;
  }

  &-products__div--grid{
    margin-left: 10px;
  }

  &-results__div{
    margin: 30px 0;
  }

  &-header__h1{
    font-size: 25px;
    font-family: $default-sans-serif-font;
    padding: 5px;
    font-weight: 400;
    margin-left: 10px;
    line-height: 1.5;
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    color: $default-black;
    padding: 5px;
    margin-left: 10px;
    font-weight: 400;
  }

  &-tabs__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    border-bottom: 3px solid $default-red;
  }

  &-tab__div{
    padding: 5px;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    margin-left: 10px;
    display: flex;
    flex-flow: row wrap;
  }

  &-tab-header__buttonbase{
    font-weight: 400;
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
    margin: 3px;
    transition: .5s all ease;
  }

  &-tab-header__buttonbase--active{
    color: $default-red !important;
  }

  &-tab-header__div{
    font-family: $default-sans-serif-font;
    font-size: 22px;
    color: $default-red;
    padding: 5px;
    margin-left: 10px;
  }

  &-tab-header__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-details__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-count__div{
    padding: 5px;
    margin: 10px 0;
    margin-left: 15px;
  }

  &-count__p{
    color: #5f5f5f;
  }

  &-products__div{
    padding: 5px;
    margin-left: 10px;
  }

  &-product-link__link{
    text-decoration: none;
    color: $default-black;
    transition: .5s all ease;
  }

  &-product__div{
    display: grid;
    grid-template-areas: 
    'image details';
    grid-template-columns: 125px 1fr;
    grid-column-gap: 10px;
    margin: 20px 0;
  }

  &-product-image__div{
    grid-area: image;
    padding: 5px;
  }

  &-product-image__img{
    width: 100%;
  }

  &-product-details__div{
    grid-area: details;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
  }

  &-product-title__p{
    font-size: 18px;
  }

  &-product-originator__p{
    margin: 10px 0 10px 0;;
  }

  &-originator__div{
    line-height: 1.5;
    font-family: $default-sans-serif-font;
    font-size: 18px;
  }

  &-originator__p{
    font-size: inherit;
  }

  &-section__div{
    line-height: 1.5;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    padding: 5px;
    margin-left: 10px;
  }

  &-section-link__link{
    text-decoration: none;
    color: $default-red;
  }

  &-section__p{
    font-size: 18px;
    font-family: $default-sans-serif-font;
    line-height: 2.5;
  }

  &-add-btn__div{
    grid-area: add;
    color: $default-red;
    position: relative;
    top: -10px;
    left: -10px;
  }

  &-add-btn__div--grid{
    grid-area: add;
    color: $default-red;
    position: relative;
  }

  &-add-badge__div{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $default-red;
    color: $default-white;
    font-family: $default-sans-serif-font;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 30px;
    z-index: 2;
    top: 0;
    border: 2px solid $default-cream;
  }

  &-add-badge__p{
    font-family: inherit;
    font-size: inherit;
    color: $default-white;
    position: relative;
    top: 2px;
  }

  &-out-of-stock__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    color: $default-red;
    font-weight: 600;
    padding: 5px;
  }

  &-out-of-stock__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  @media only screen and (min-width: 767px) {

    &-views__div{
      margin-left: 5px;
    }
    &-products__div--grid{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      padding: 5px;
      margin-left: 0;
    }

    &-product__div--grid{
      grid-template-areas: 
      'image'
      'details';
      grid-template-columns: 1fr;
    }

    &-product-image__div--grid{
      width: 125px;
      // height: 200px;
      margin: 0 auto;
    }

    &-product-details__div--grid{
      max-width: 175px;
      margin-top: 10px;
      justify-self: center;
      text-align: center;
      position: relative;
    }

    &-product-title__p--grid{
      font-size: 15px;
      font-weight: 600;
    }
  }

  @media only screen and (min-width: 1023px){
    // &-container__div{
    //   max-width: 1366px;
    //   margin: auto;
    // }

    &-carousels__div{
      max-width: 1366px;
      margin: auto;
    }

    &-tab-header__h2{
      &:hover{
        color: $default-red;
      }
    }

    &-products__div{
      margin-left: 100px;
    }

    &-products__div--grid{
      grid-template-columns: repeat(4, 1fr);
    }

    &-product-link__link{
      &:hover{
        color: $default-red;
      }
    }

    &-section-link__link{
      &:hover{
        color: $hover-red;
      }
    }
  }

  @media only screen and (min-width: 1339px){
    &-products__div--grid{
      grid-template-columns: repeat(5, 1fr);
    }
  }
}