@import '../../scss/variables';

.checkoutrevieworder{
  &-content__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  &-confirm__div{
    display: flex;
    justify-content: center;
    padding: 5px;
    font-size: 18px;
    font-family: $default-sans-serif-font;
  }

  &-confirm1__div{
    margin: 5px 0 50px 0;
  }

  &-payment__div{
    border: 2px solid $default-content-gray;
    line-height: 1.7;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 10px;
    margin: 10px;
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 5px 0;
    font-weight: 600;
  }

  &-accent__div{
    height: 3px;
    width: 25px;
    background-color: $default-red;
    margin: 5px 0 10px 0;
  }

  &-details__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-shipping__div{
    border: 2px solid $default-content-gray;
    line-height: 1.5;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 10px;
    margin: 10px;
  }

  &-notice__div{
    padding: 5px;
    font-family: $default-sans-serif-font;
    font-size: 13px;
    text-align: center;
    line-height: 1.5;
  }

  &-notice__p{
    color: $default-red;
    font-family: inherit;
    font-size: inherit;
  }

  @media only screen and (min-width: 767px) {
    &-content__div{
      grid-template-areas:
      'confirm1 confirm1' 
      'summary customerInfo'
      'summary customerInfo';
      grid-template-columns: 1fr 275px;
    }

    &-confirm1__section{
      grid-area: confirm1;
    }

    &-cart__section{
      grid-area: summary;
    }

    &-info__section{
      grid-area: customerInfo;
    }

    &-confirm2__section{
      grid-area: confirm2;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__div{
      grid-template-columns: 1fr 400px;
    }
  }
}