
p, img, div, body, a, li, ul, section, button{
  padding: 0;
  border: none;
  margin: 0;
  font-family: "Trade Gothic Lt Std", "Proxima Nova, sans-serif", Arial, Helvetica;
  // box-sizing: border-box;
};

textarea,
input.text,
// input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
-webkit-appearance: none;
border-radius: 4px;
border: none;
}

input[type="text"],
.input-checkbox {
  -webkit-appearance: none;
}