@import '../../scss/variables';

.recoveraccount{

  &-success__div{
    margin: 50px 0;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    margin: 15px 0;
  }

  &-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 200px;
    margin: 15px auto;
  }

  &-btn__link{
    color: #ffffff;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    background-color: $default-red;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
  
    &:hover{
      background-color: $hover-red;
    }
  }

  &-error-message__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: $default-red;
    justify-content: center;
    padding: 5px;
    margin: 10px 0;
  }

  &-error-message__p{
    padding-top: 5px;
  }

  &-form__div{
    padding: 5px;
    margin: 20px auto;
    width: 300px;
  }
  
  &-form__form{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  &-submit-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 150px;
    margin: 15px auto;
  }
}