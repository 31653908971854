@import '../../scss/variables';

.returnpolicy{
    &-content__div{
      margin: 10px 0;
      padding: 10px;
    }
    &-header__h1{
      font-family: $default-sans-serif-font;
      font-size: 20px;
      color: $default-red;
      margin-bottom: 20px;
      font-weight: 400;
    }
    &-details__p{
      font-family: $default-serif-font;
      font-size: 13px;
      padding: 10px;
    }
  
    @media only screen and (min-width: 768px) {
      &-content__div{
        width: 600px;
        margin: 0 auto 50px auto;
      }
      &-header__p{
        font-size: 25px;
      }
      &-details__p{
        font-size: 15px;
      }
    }

    @media only screen and (min-width: 1023px) {
      &-container__div{
        max-width: 1366px;
        margin: auto;
      }
      &-content__div{
        width: 900px;
      }
    }
  }