@import '../../scss/variables';

.cartsnackbar{
  &-content__div{
    color: $default-white;
    // color: $default-black;
    font-size: 15px;
    padding: 5px;
    background-color: $default-red;
    line-height: 1.3;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    // border: 3px solid $default-yellow;
    border: 3px solid $default-cream;
  }

  &-header__h2{
    margin: 5px;
    font-size: inherit;
    font-weight: 400;
  }
}