@import '../../scss/variables';

.bhufaq{
    &-container__div{
      margin: 75px 0;
    }
    &-content__div{
        font-family: $default-sans-serif-font;
        font-size: 15px;
        padding: 5px;
        line-height: 1.7;
        margin: 0 10px 50px 10px;
    }

    &-header__h2{
        font-family: $default-sans-serif-font;
        font-size: 18px;
        font-weight: 400;
        color: $default-red;
    }

    &-header__h3{
        font-family: $default-sans-serif-font;
        font-size: 15px;
    }

    &-details__div{
        font-family: $default-sans-serif-font;
        font-size: 15px;
    }

    &-details__p{
        font-family: inherit;
        font-size: inherit;
    }

    &-details-link__a{
        text-decoration: none;
        color: $default-red;
        font-size: inherit;
        font-family: inherit;
    }

    &-details__p--strong{
        font-weight: 600;
    }

    &-list__ul{
        font-family: inherit;
        font-size: inherit;
        margin-left: 20px;
        margin-top: 10px;
      }

      &-list-item__li{
        font-family: inherit;
        font-size: inherit;
      }

      &-list-item__li--indent{
        margin-left: 10px;
      }

      &-list-item__span--strong{
        font-weight: 600;
      }

    @media only screen and (min-width: 767px) {
        &-content__div{
          width: 700px;
          margin: 0 auto 50px auto;
          display: grid;
          grid-template-columns: 1fr 200px;
        }
        &-header__h2{
        font-size: 20px;
        }
        &-header__h3{
        font-size: 15px;
        }
    }

    @media only screen and (min-width: 1023px) {
        &-container__div{
          max-width: 1366px;
          margin: auto;
        }

        &-content__div{
        width: 900px;
        font-size: 15px;
        }

        &-details__div{
        font-size: 15px;
        }

        &-hyperLink_a{
          color: $hover-red;
        }
        &-details-link__a{
        &:hover{
            color: $hover-red;
            text-decoration: underline;
        }
        }

        &-header__h2{
        font-size: 25px;
        }
        &-Links__p{
          text-decoration: none;
          color: $default-red;
          font-family: inherit;
          font-size: inherit;
        }

        &-header__h3{
        font-size: 18px;
        }
    }
}