@import '../../scss/variables';

.navbar{
  &-container__div{
    padding-bottom: 50px;
  }

  &-appbar__appbar{
    display: flex;
    flex-flow: row wrap;
    // border-bottom: 3px solid $default-red;
  }

  &-icons__div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
    'left center right'
    'tagline tagline tagline';
    width: 100%;
    padding: 5px 0;
  }

  &-lefticon__div{
    padding: 5px;
    grid-area: left;
  }

  &-search-btn__iconbutton--active{
    transition: .5s all ease;
  }

  &-navlink__navlink{
    text-decoration: none;
    grid-area: center;
  }

  &-logo__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100px;
    margin: auto;
  }

  &-image__img{
    width: 100%;
  }

  &-tagline__div{
    font-family: $default-sans-serif-font;
    font-size: 10px;
    color: $default-black;
    margin: auto;
    grid-area: tagline;
    justify-self: center;
  }

  &-tagline__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    text-align: center;
  }

  &-righticon__div{
    justify-self: end;
    padding: 5px;
    position: relative;
    right: 15px;
    grid-area: right;
  }

  &-account-icon__accountcircle{
    position: relative;
    top: 11px;
  }

  &-cart__navlink{
    text-decoration: none;
    color: $default-red;
    padding: 5px;

    &:hover{
      color: $hover-red;
    }
  }

  &-submenu__div{
    // background-color: white;
    // background-color: #fdf2ca;
    // background-color: #fff1ba;
    // background-color: $default-cream;
    background-color: $default-off-white;
    padding: 30px;
    min-height: 200px;
    display: none;
    // border-right: 3px solid $default-red;
    // border-left: 3px solid $default-red;
    // border-bottom: 3px solid $default-red;
    border-top-color: transparent;
  }

  &-submenu__div--active{
    position: absolute;
    top: 45px;
    // width: 100vw;
    width: 890px;
    display: block;
  }

  &-submenu-account__div--active{
    position: absolute;
    top: 63px;
    left: -41px;
    // right: 0;
    padding: 30px;
    // width: 100vw;
    width: 100px;
    display: block;
    z-index: 1000000;
    border-top: 3px solid $default-red;
  }

  &-submenu-links__div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 500px;
    align-items: start;
    grid-template-rows: auto;
    grid-row-gap: 5px;
  }

  &-submenu-account-links__div{
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    grid-template-rows: auto;
    justify-content: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
  }

  &-bottom-link__navlink{
    text-decoration: none;
    color: $default-black;
    transition: .3s all ease;
    font-size: 15px;
    font-family: inherit;
    padding: 5px 10px;
    line-height: 1.7;
    &:hover{
      color: $default-red;
    }
  }

  &-searchbar__div{
    display: flex;
    flex-flow: row wrap;
    padding: 15px 5px 15px 5px;
    justify-content: flex-start;
    width: 100%;
    position: relative;
  }

  &-icon-close__iconbutton{
    position: relative;
    top: -10px;
    // left: -10px; 
  }

  @media only screen and (min-width: 767px) {
    &-searchbar__div{
      // padding: 15px 5px 5px 5px;
      justify-content: center;
    }
  }
}