@import '../../scss/variables';

.strandcastep{
  &-container__div{
    background-color: $default-yellow;
    padding-bottom: 200px;
  }

  &-image__div{
    width: 200px;
    display: block;
    margin: 50px auto 0 auto;
    // padding: 15px;
    position: relative;
    z-index: 1;
  }

  &-image__img{
    width: 100%;
    position: absolute;
  }

  &-content__div{
    padding: 50px 5px;
  }

  &-back__div{
    padding: 5px;
    position: relative;
    left: 15px;
    top: 15px;
  }

  &-card__div{
    background-color: rgba(255, 255, 255, .8);
    padding: 15px;
    position: relative;
    top: 200px;
    z-index: 2;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 25px;
    // margin: 5px 0 10px 0;
    margin: 5px;
    color: $default-red;
  }

  &-accent__div{
    height: 4px;
    width: 35px;
    background-color: $default-yellow;
    margin-left: 5px;
  }

  &-details__div{
    line-height: 1.7;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 20px;
    // margin: 15px 0 15px 0;
    margin: 5px;
  }

  &-header__h4{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    // margin: 15px 0 15px 0;
    margin: 5px;
    line-height: 1.5;
  }

  &-header__h5{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 5px 5px 10px 5px;
    font-weight: 600;
  }

  &-details__p{
      font-family: inherit;
      font-size: inherit;
      margin: 5px;
  }

  &-link__link{
    text-decoration: none;
    color: $default-red;
  }

  &-author__div{
    padding: 5px;
    margin: 15px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
  }

  &-author-image__div{
    padding: 5px;
    margin: 10px 0;
    width: 250px;
  }

  &-author-image__img{
    width: 100%;
  }

  &-author-bio__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
  }

  @media only screen and (min-width: 767px) {
    &-content__div{
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-template-areas: 
      'back .'
      'book card';
    }

    &-image__div{
      width: 250px;
      // top: -200px;
      left: 50px;
      grid-area: book;
    }

    &-card__div{
      grid-area: card;
      position: relative;
      right: 15px;
    }

    &-author__div{
      grid-template-columns: 275px 1fr;
    }

    &-author-image__div{
      margin: 0;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__div{
      grid-template-columns: 200px 1fr;
      max-width: 1100px;
      margin: 0 auto;
    }

    &-image__div{
      width: 300px;
    }

    &-link__link{
      &:hover{
        color: $hover-red;
      }
    }
  }
}