@import '../../scss/variables';

.singleauthorsbookshelf{
      &-back__div{
        padding: 5px;
        margin-left: 15px;
      }

      &-image__div{
        width: 100%;
      }
    
      &-image__img{
        width: 100%;
      }
    
      &-header__div{
        text-align: center;
        font-family: $default-sans-serif-font;
      }
    
      &-feature__div{
        margin-top: 50px;
      }
    
      &-feat-image__div{
        width: 300px;
        height: 300px;
        border-radius: 50% 50%;
        border: 5px solid #b8222e;
        overflow: hidden;
        margin: 25px auto 0 auto;
      }

      &-credit__div{
        font-family: $default-sans-serif-font;
        font-size: 13px;
        padding: 5px;
        text-align: center;
        margin: 5px 0;
        font-weight: 600;
      }
    
      &-feat-image__img{
        width: 100%;
      }
    
      &-header__h3{
        font-family: $default-sans-serif-font;
        font-weight: 400;
        font-size: 18px;
      }
    
      &-feat-details__div{
        font-family: $default-sans-serif-font;
        font-size: 15px;
        line-height: 1.7;
        text-align: center;
        padding: 5px;
      }

      &-border__div{
        height: 3px;
        width: 80%;
        background-color: $default-border-gray;
        margin: 50px auto;
      }

      &-bookshelf__div{
        margin: 50px 0;
      }

      &-header__h2{
        font-family: $default-sans-serif-font;
        font-weight: 400;
        font-size: 25px;
        margin: 5px;
      }
    
      &-accent__div{
        width: 35px;
        height: 3px;
        background-color: $default-red;
        display: block;
        margin: 15px auto 15px auto;
      }

      &-items__div{
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        margin-top: 50px;
      }

      &-item__div{
        padding: 5px;
        margin: 10px 0;
      }

      &-item-image__div{
        width: 125px;
        margin: auto;
        display: grid;
        align-content: center;
      }

      &-item-image__img{
        width: 100%;
      }

      &-details__div{
        font-family: $default-sans-serif-font;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        padding: 5px;
        margin-top: 5px;
      }

      &-header__h4{
        text-align: center;
        font-size: 15px;
        margin: 5px;
        font-weight: 400;
      }

      &-details__p{
        font-family: inherit;
        font-size: inherit;
        text-align: center;
      }

      &-details-price__p{
        margin-top: 10px;
      }

      @media only screen and (min-width: 374px) {
        &-items__div{
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @media only screen and (min-width: 767px) {

        &-feature__div{
          display: grid;
          grid-template-areas:
          'back . .'
          'author name name'
          'author details details'
          'credit . .'
        }

        &-back__div{
          grid-area: back;
        }
    
        &-feat-image__div{
          grid-area: author;
          margin: 10px;
          align-self: center;
        }

        &-credit__div{
          grid-area: credit;
        }
    
        &-header-name__div{
          grid-area: name;
          align-self: end;
        }
    
        &-feat-details__div{
          grid-area: details;
        }

        &-item-image__div{
          width: 125px;
          // height: 225px;
        }

        &-items__div{
          grid-template-columns: repeat(4, 1fr);
        }
      }

      @media only screen and (min-width: 1023px) {

        &-container__div{
          max-width: 1366px;
          margin: auto;
        }

        &-items__div{
          grid-template-columns: repeat(5, 1fr);
        }

        &-item-image__div{
          // width: 1px;
          // height: 250px;
          transition: .5s all ease;

          &:hover{
            transform: translateY(-5px);
          }
        }
      }

      @media only screen and (min-width: 1439px) {
        // &-items__div{
        //   grid-template-columns: repeat(4, 1fr);
        // }
      }
}