@import '../../scss/variables';

.compback{
  &-link__link{
    color: $default-red;
    font-family: $default-sans-serif-font;
    font-size: '15px';
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover{
      color: $hover-red;
    }
  }
}