@import '../../scss/variables';

.cart{
  &-expired__p{
    padding: 15px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 15px;
    color: $default-red;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
  }
  &-checkout__div{
    padding: 5px;
    margin: 50px 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }
  &-subtotal__p{
    text-align: center;
    padding: 5px;
    font-family: inherit;
    font-size: 1em;
  }

  &-checkout-btn__div{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  &-checkout-btn__p{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    width: 240px;
    height: 40px;
    padding: 5px;
    text-align: center;
    background-color: $default-red;
    border-radius: 5px;
    color: $default-white;
    margin-top: 10px;
    line-height: 1.7;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      background-color: $hover-red;
    }
  }

  &-checkout-btn-login__p{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    width: 240px;
    padding: 5px 0px;
    height: 40px;
    text-align: center;
    background-color: $default-red;
    border-radius: 5px;
    color: $default-white;
    margin-top: 10px;
    line-height: 1.7;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-divider__div{
    width: 85%;
    height: 2px;
    // background-color: #f9f9f9;
    background-color: $default-content-gray;
    display: block;
    margin: 20px auto 0px auto;
  }

  &-no-items__div{
    padding: 5px;
  }

  &-header__h2{
    font-weight: 400;
    color: $default-content-gray;
    font-size: 20px;
    margin: 10px;
    text-align: center;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 75px;
    grid-template-rows: 1fr 35px 30px 30px 1fr 1fr 30px;
    grid-template-areas: 
    'prodImage title title'
    'prodImage originator originator'
    'prodImage binding totalPrice'
    'prodImage price totalPrice'
    'prodImage discount totalPrice'
    'prodImage qty qty'
    'prodImage remove remove';
    padding: 0 10px 0 5px;
  }

  &-item-link__link{
    text-decoration: none;
    color: $default-black;
    grid-area: prodImage;
  }

  &-details-image__div{
    padding: 5px;
    width: 125px;
    margin: auto;
    position: relative;
  }

  &-gift-icon__div{
    background: $default-yellow;
    width: 30px;
    height: 30px;
    position: absolute;
    text-align: center;
    // transform: rotate(20deg);
    // bottom: 30px;
    // right: 0px;
    top: -10px;
    z-index: 1;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      background: $default-yellow;
      transform: rotate(135deg);
      z-index: 1;
    }
  }

  &-gift-icon__p{
    position: absolute;
    font-family: $default-sans-serif-font;
    font-size: 11px;
    z-index: 2;
    line-height: 1;
    top: 5px;
    color: $default-black;
  }

  &-dropship__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    color: $default-red;
    text-align: center;
  }

  &-signed__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    color: $default-red;
    text-align: center;
  }

  &-unavailable__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 2px;
    color: $default-yellow;
  }

  &-unavailable__p{
    font-family: $default-sans-serif-font;
    font-size: 10px;
    color: $default-red;
    text-align: left;
    font-weight: 600;
    position: relative;
    top: 2px;
  }

  &-details-image__img{
    width: 100%;
  }

  &-title__div{
    grid-area: title;
    font-family: inherit;
    align-self: end;
  }

  &-title__p{
    font-size: 18px;
    font-family: inherit;
  }

  &-accent__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    margin: 5px 0 10px 0;
  }

  &-author__p{
    font-size: 1em;
    grid-area: originator;
  }

  &-binding__p{
    grid-area: binding;
    font-size: 1em;
  }

  &-price__p{
    padding: 5px;
    font-family: inherit;
    font-size: 1em;
    grid-area: price;
  }

  &-discount-note__p{
    font-family: inherit;
    font-size: 13px;
    color: $default-red;
    padding: 5px;
    grid-area: discount;
  }

  &-qty__div{
    padding: 5px;
    font-family: $default-sans-serif-font;
    font-size: 13px;
  }

  &-item-select__select{
    grid-area: qty;
    justify-self: start;
    padding: 5px 0 5px 0;
    color: $default-red;
    background-color: $default-cream;
    border: 1px solid $default-light-gray;
    margin: 10px;
    font-family: $default-sans-serif-font;
    font-size: 15px;

    &:focus{
      outline: none;
    }
  }

  &-item-total__p{
    grid-area: totalPrice;
    justify-self: center;
    align-self: start;
  }

  &-item-options__div{
    color: $default-red;
    font-size: 15px;
    grid-area: remove;
    justify-self: center;
  }

  &-gift__div{
    // display: flex;
    // flex-flow: row wrap;
    // align-items: center;
    display: grid;
    grid-template-columns: 50px 1fr;
    align-content: center;
    padding: 10px;
    margin: 5px auto;
    background-color: #efefef;
    width: 85%;
  }

  &-gift-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
  }

  &-gift-details__p{
    font-family: inherit;
    font-size: inherit;

    &--size13{
      font-size: 13px;
    }

    &--red{
      color: $default-red;
      font-weight: 600;
    }
  }



  &-checkout-btn__div--margin-bottom{
    margin-bottom: 50px;
  }

  @media only screen and (min-width: 374px) {
    &-qty__div{
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 424px) {
    &-unavailable__p{
      font-size: 11px;
    }
  }

  @media only screen and (min-width: 767px) {
    &-header__h2{
      font-size: 22px;
    }

    &-gift-icon__div{
      width: 40px;
      height: 40px;
      left: -10px;
  
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        width: 40px;
        background: $default-yellow;
        transform: rotate(135deg);
      }
    }
  
    &-gift-icon__p{
      font-size: 13px;
      z-index: 2;
      top: 8px;
    }

    &-unavailable__p{
      font-size: 13px;
    }

    &-details__div{
      grid-template-rows: 1fr 1fr 30px 30px 35px 1fr 30px;
    }

    &-item-options__div{
      justify-self: start;
    }

  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }

    &-checkout__div{
      font-size: 18px;
    }

    &-details__div{
      grid-template-columns: 250px 1fr 1fr;
      font-size: 15px;
    }

    &-details-image__div{
      width: 150px;
    }

    &-item-link__link{
      &:hover{
        color: $hover-red;
      }
    }

    &-title__p{
      font-size: 20px;
    }

    &-item-total__p{
      font-size: 18px;
    }

    &-header__h2{
      font-size: 25px;
    }
  }
}