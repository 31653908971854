@import '../../scss/variables';

.signup{
  &-loading__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  &-success__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    text-align: center;
    // color: $default-red;
    color: #0c8626;
    margin: 0 0 10px 0;
    line-height: 1.5;
    font-weight: 600;
  }

  &-content__div{
    padding: 10px;
    margin: 50px auto;
    width: 275px;
    border-radius: 5px;
    background-color: $default-off-white;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
  }

  &-form__form{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    // grid-row-gap: 30px;
  }

  &-form__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    // grid-row-gap: 20px;
  }

  &-header-image__div{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas: '. logo close';
  }

  &-header-image__img{
    width: 72px;
    grid-area: logo;
    justify-self: center;
  }

  &-close__div{
    width: 48px;
    grid-area: close;
    justify-self: end;
    position: relative;
    top: -20px;
    left: 20px;
  }


  &-header__h1{
    font-size: 20px;
    font-family: $default-sans-serif-font;
    font-weight: 400;
    text-align: center;
  }

  &-subheader__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
    line-height: 1.3;
  }

  &-error__p{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    padding: 5px;
    text-align: center;
    color: $default-red;
    margin: 0 0 10px 0;
    line-height: 1.5;
  }

  &-submit__input{
    width: 80px;
    margin: 10px auto 20px auto;
    padding: 5px;
    background-color: $default-red;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    border-radius: 4px;
    color: $default-white;
    cursor: pointer;
    border: none;
    &:hover{
        background-color: $hover-red;
    }
  }

  &-submit-reset__input{
    width: 150px;
    padding: 10px;
    border: none;
  }

  &-options__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    padding: 10px;
    font-family: $default-sans-serif-font;
    font-size: 13px;
    border-radius: 5px;
    background-color: $default-yellow;
    color: $default-black;
    &:hover{
        background-color: $hover-yellow;
    }
  }

  &-option-btn__link{
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: $default-black;
    &:hover{
      color: #575757;
    }
    // font-weight: 600;
  }

  &-options-forgot__div{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    text-align: center;
    margin: 10px 0;
  }

  &-forgot__p{
    justify-self: start;
    font-family: inherit;
    font-size: inherit;
    &:hover{
        color: $default-red;
        text-decoration: underline;
    }
  }

  &-signup__p{
    justify-self: end;
    text-align: center;
    color: inherit;
  }

  &-or__p{
    font-family: inherit;
    font-size: inherit;
    text-align: center;
  }

  @media only screen and (min-width: 375px) {
    &-content__div{
      width: 300px;
    }
  }

  @media only screen and (min-width: 425px) {
    &-content__div{
        width: 375px;
    }
  }

  @media only screen and (min-width: 767px) {

    &-header-image__img{
        width: 128px;
    }

    &-header__h1{
        font-size: 25px;
    }

    &-subheader__p{
        width: 350px;
        margin: auto;
    }

    &-error__p{
        font-size: 15px;
        width: 350px;
        margin: 0 auto 10px auto;
    }

    &-options-forgot__div{
        font-size: 15px;
    }

    &-options__div{
        font-size: 15px;
    }
  }
}