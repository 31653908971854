@import '../../scss/variables';

.checkoutconfirmation{
  &-main__div{
    margin: 50px 0;
  }

  &-header__h1{
    font-size: 25px;
    text-align: center;
    color: $default-red;
    padding: 8px;
  }

  &-image__div{
    width: 275px;
    padding: 8px;
    margin: 32px auto;
  }

  &-image__img{
    width: 100%;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    padding: 5px;
    text-align: center;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    text-align: center;
    line-height: 1.7;
  }

  &-link__a{
    color: $default-red;

    &:hover{
      color: $hover-red;
    }
  }

  &-btn__div{
    margin: 25px auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 175px;
  }

  &-btn__div{
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 15px auto;
  }

  &-btn__link{
    color: #ffffff;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    background-color: $default-red;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
  
    &:hover{
      background-color: $hover-red;
    }
  }

}