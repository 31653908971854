@import '../../scss/variables';

.gencarousel{
    &-container__div{
    width: 100%;
    }

    &-content__div{
    width: 100%;
    }

    &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
    }

    &-dash__div{
    width: 50px;
    height: 3px;
    background-color: $default-red;
    display: block;
    margin: 10px auto 10px auto;
    }

    &-item__div{
    // padding: 5px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    // min-width: 0;
    }

    &-item-link__link{
    text-decoration: none;
    color: black;
    transition: 1s solid ease;
    }

    &-item-image__div{
    width: 100px;
    height: 200px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-width: 0;
    min-height: 0;
    position: relative;
    }

    &-bestseller-flag__div{
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    //   background-color: $default-red;
      background-color: $default-yellow;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      text-align: center;
      display: flex;
      align-items: center;
    //   color: $default-white;
      color: $default-black;
      font-weight: 600;
      justify-content: center;
    //   border: 2px solid $default-red;
      border: 2px solid $default-yellow;
      right: -15px;
      top: 10px;
    }

    &-item-image__img{
    width: 100%;
    padding: 5px;
    }
    &-item-details__div{
    text-align: center;
    padding: 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    width: 180px;
    margin: auto;
    line-height: 1.7;
    }
    &-item-details-title__p{
    font-family: inherit;
    font-size: 15px;
    margin: 5px 0;
    }
    &-item-details-price__p{
    font-family: inherit;
    font-size: inherit;
    margin: 15px 0 5px 0;
    }

    &-all-link__div{
    padding: 5px;
    text-align: center;
    margin-top: 10px;
    }

    &-all-link__link{
    text-decoration: none;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    color: $default-red;
    font-weight: 400;
    }

    &-accent__div{
    width: 20px;
    height: 3px;
    background-color: $default-red;
    margin: 5px auto 0 auto;
    }
    
    &-border__div{
    width: 80%;
    height: 3px;
    background-color: #efefef;
    margin: 25px auto;
    }

    @media only screen and (min-width: 424px) {
    &-item-image__div{
        // width: 125px;
        // height: 225px;
    }
    
    &-item-details__div{
        // font-size: 18px;
    }

    &-item-details-title__p{
        // font-size: 18px;
    }

    &-all-link__link{
        font-size: 15px;
    }
    }

    @media only screen and (min-width: 767px) {
    &-header__h2{
        // font-size: 22px;
    }
    }

    @media only screen and (min-width: 1023px){
    &-header__h2{
        // font-size: 25px;
    }
    &-item-link__link{
        &:hover{
        color: $default-red;
        }
    }

    &-item-image__div{
        transition: .5s all ease;
        &:hover{
        transform: translateY(-5px);
        }
    }

    &-all-link__link{
        font-size: 15px;
        &:hover{
        color: $hover-red;
        }
    }

    // &-border__div{
    //   width: 80%;
    //   height: 3px;
    //   background-color: #efefef;
    //   margin: 25px auto;
    // }
    }
}