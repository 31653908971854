@import '../../scss/variables';

.submissionspage{
  &-container__div{
    background-color: $default-cream;
  }

  &-header__div{
    margin: 15px auto;
    width: 100%;
  }

  &-header__img{
    width: 100%;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    text-align: center;
    margin: 5px auto 50px auto;
  }

  @media only screen and (min-width: 767px) {
    &-details__div{
      width: 650px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }

    &-details__div{
      font-size: 18px;
      width: 975px;
    }
  }
}