@import '../../scss/variables';

.compmodal{
  &-close__div{
    display: flex;
    justify-content: flex-end;
  }

  &-image__div{
    padding: 0px 48px 48px 48px;
    overflow: scroll;
    display: flex;
    justify-content: center;
    position: relative;
  }
}