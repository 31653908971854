@font-face {
  font-family: "Trade Gothic Lt Std";
  font-style: normal;
  font-weight: normal;
  src: local("Trade Gothic Lt Std"), url(./fonts/TradeGothicLTStd.otf), url(./fonts/TradeGothicLTStd.ttf) format("truetype"); }

p, img, div, body, a, li, ul, section, button {
  padding: 0;
  border: none;
  margin: 0;
  font-family: "Trade Gothic Lt Std", "Proxima Nova, sans-serif", Arial, Helvetica; }

textarea,
input.text,
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 4px;
  border: none; }

input[type="text"],
.input-checkbox {
  -webkit-appearance: none; }

p, img, div, body, a, li, ul, section, button {
  padding: 0;
  border: none;
  margin: 0;
  font-family: "Trade Gothic Lt Std", "Proxima Nova, sans-serif", Arial, Helvetica; }

textarea,
input.text,
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 4px;
  border: none; }

input[type="text"],
.input-checkbox {
  -webkit-appearance: none; }

.app-container__div {
  width: 100%;
  background-color: #fff; }
