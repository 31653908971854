@import '../../scss/variables';

.booksmedia{
  &-subheader__div{
    padding: 5px;
  }
  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin: 5px auto;
    line-height: 1.7;
  }

  &-header__h2--gray{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin: 5px auto;
    line-height: 1.7;
    color: $default-dark-gray;
  }

  &-others__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 5px;
    margin: 15px auto;
    line-height: 1.7;
  }

  &-others-link__link{
    padding: 5px;
    text-decoration: none;
    color: $default-black;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    
    &:hover{
      color: $hover-red;
    }
  }

  @media only screen and (min-width: 767px) {
    &-others__div{
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__div{
      max-width: 1366px;
      margin: auto;
    }

    &-others__div{
      grid-template-columns: repeat(3, 1fr);
    }
  }
}