@import '../../scss/variables';

.pagesubheader{
  &-container__div{
      margin: 50px auto 10px auto;
      font-family: $default-sans-serif-font;
      font-size: 18px;
      text-align: center;
      font-weight: 600;
  }

  &-title__h2{
     font-family: inherit;
     font-size: inherit;
     font-weight: inherit;
     margin: 5px;
  }
  &-dash__div{
    width: 28px;
    height: 3px;
    background-color: $default-red;
    display: block;
    margin: 15px auto 15px auto;
  }
}