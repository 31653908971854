@import '../../scss/variables';

.DateInput{
  position: static;
}

.DateInput_input__focused{
  border-bottom: 2px solid $default-red;
}

.CalendarDay__default{
  &:hover{
    color: $default-white;
    background: $hover-red;
    border: 1px double $hover-red;
  }
}

.CalendarDay__blocked_out_of_range{
  // background: $default-content-gray;
//   background: $default-cream;
  pointer-events: none;
  color: $default-content-gray !important;
}

// .CalendarDay__today{
//   background: $default-red;
//   border: 1px double $default-red;
//   color: $default-white !important;
// }

.DayPickerNavigation_svg__horizontal{
  width: 24px;
  height: 24px;
  &:hover{
    fill: $hover-red;
  }
}

.CalendarMonth_caption{
  color: $default-black;
}

.CalendarDay__default{
  color: $default-black;
}

.CalendarDay__selected{
  background: $default-red;
  border: 1px double $default-red;
  color: $default-white;
}

.CalendarDay__selected_span{
  background: $default-red;
  border: 1px double $default-red;
  color: $default-white;
}

.DayPicker_weekHeader{
  font-weight: 600;
  color: $default-red;
}

.DayPickerKeyboardShortcuts_show__bottomRight{
  &:before{
    border-right: 33px solid $default-red;
  }

  &:hover{
    &:before{
      border-right: 33px solid $hover-red;
    }
  }
}