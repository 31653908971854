@import '../../scss/variables';

.productlistpageops{
   &-container__div{
      display: flex;
      flex-flow: row wrap;
   }

   &-view__div{
      margin: 0 auto 0 0;
      padding: 5px;
      display: flex;
      flex-flow: column wrap;
      font-family: $default-sans-serif-font;
      font-size: 18px;
   }

   &-view-header__p{
      margin-bottom: 5px;
      font-family: inherit;
      font-size: inherit;
   }

   &-sort__div{
      margin: 0 0 0 auto;
      padding: 5px;
      display: flex;
      flex-flow: column wrap;
      font-family: $default-sans-serif-font;
      font-size: 18px;
   }

   &-sort-header__p{
      margin-bottom: 5px;
      font-family: $default-sans-serif-font;
      color: $default-black;
      text-align: center;
   }

   @media only screen and (min-width: 767px) {
    &-view__div{
      flex-flow: row wrap;
    }

    &-view-header__p{
      margin: 0 15px 0 0;
      position: relative;
      top: 7px;
    }

    &-sort__div{
      flex-flow: row wrap;
    }

    &-sort-header__p{
      margin: 0 15px 0 0;
      position: relative;
      top: 7px;
    }
   }

   @media only screen and (min-width: 1023px) {
     &-view__div{
       position: relative;
       left: 30px;
     }

     &-sort__div{
       position: relative;
       right: 30px;
     }
   }
}