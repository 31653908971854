@import '../../scss/variables';

.libraryservices{
  &-hero__img{
    width: 100%;
  }

  &-quote__div{
    font-size: 15px;
    font-style: italic;
    font-family: $default-sans-serif-font;
    padding: 5px;
    line-height: 1.7;
    margin: 0 5px;
  }
  &-quote__p{
    font-size: inherit;
    font-family: inherit;
  }
  &-content__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    line-height: 1.7;
    margin: 0 10px 50px 10px;
  }

  &-header__h1{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    color: $default-red;
  }
  &-text__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    color: $default-red;
  }

  &-link__a{
    text-decoration: none;
    color: $default-red;
    font-family: inherit;
    font-size: inherit;

    &:hover{
      color: red;
    }
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 600;
    color: $default-red;
  }

  &-list__ul{
    font-family: inherit;
    font-size: inherit;
    margin-left: 20px;
    margin-top: 10px;
  }

  &-list-item__li{
    font-family: inherit;
    font-size: inherit;
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      height: 300px;
      overflow: hidden;
    }

    &-hero__img{
      position: relative;
      top: -175px;
    }

    &-quote__div{
      width: 600px;
      margin: auto;
    }

    &-content__div{
      width: 600px;
      margin: 0 auto 50px auto;
    }
  
    &-header__h1{
      font-size: 18px;
    }

    &-header__h2{
      font-size: 15px;
    }
  
    &-header__h3{
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-hero__div{
      height: 500px;
    }
    
    &-quote__div{
      width: 900px;
      font-size: 20px;
    }
    &-content__div{
      width: 800px;
    }
    &-header__h1{
      font-size: 18px;
    }

  }
}