@import '../../scss/variables';

.bbtftop{
  &-header__h2{
    font-size: 18px;
    font-family: $default-serif-font;
    font-weight: 400;
    text-align: center;
    margin: 25px 0 0 0;
    // margin: 0;
  }

  &-header-accent__div{
    width: 28px;
    height: 3px;
    background-color: $default-red;
    margin: 15px auto 15px auto;
  }

  &-items__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
    align-content: center;
  }

  &-item__div{
    padding: 5px;
    margin: 15px 0;
  }

  &-item-image__div{
    width: 150px;
    margin: auto;
  }

  &-item-image__img{
    width: 100%;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    text-align: center;
    max-width: 250px;
    margin: 5px auto;
  }

  &-header__h3{
    font-size: 15px;
    font-family: $default-sans-serif-font;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
    line-height: 1.5;
    padding: 8px;
  }

  &-item-accent__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    margin: 10px auto;   
  }

  &-price__p{
    font-family: inherit;
    font-size: inherit;
    
    &--red{
      color: $default-red;
    }

    &--strong{
      font-weight: 600;
    }
  }

  &-button__link{
    text-decoration: none;
    color: $default-white;
  }

  &-button__div{
    width: 160px;
    height: 40px;
    margin: auto;
    background-color: $default-red;
    color: $default-white;
    border-radius: 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &--disabled{
      color: $default-dark-gray;
      background-color: $default-content-gray;
    }
  }

  &-button__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-more__div{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 5px;
    margin: 25px 0;
  }

  &-link__link{
    text-align: center;
    text-decoration: none;
    color: $default-red;
    font-family: $default-sans-serif-font;
    font-family: 15px;
  }

  @media only screen and (min-width: 767px) {
    &-items__div{
      grid-template-columns: repeat(4, 1fr);
    }

    &-item-image__div{
      height: 195px;
      display: flex;
      align-items: center;
    }
    
    &-header__h3{
      min-height: 53px;
    }
  
    &-link__link{
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-items__div{
      max-width: 1366px;
      margin: auto;
      grid-template-columns: repeat(5, 1fr);
    }

    &-item-image__div{
      transition: .5s all ease;
      &:hover{
        transform: translateY(-5px);
      }
    }

    &-button__div{
      &:hover{
        background-color: $hover-red;
      }

      &--disabled{
        &:hover{
          background-color: $default-content-gray;
        }
        color: $default-dark-gray;
        background-color: $default-content-gray;
      }
    }

    &-link__link{
      &:hover{
        color: $hover-red;
      }
    }
  }
}