@import '../../scss/variables';

.cataloginfo{
  &-content__div{
    padding: 5px;
    margin: 5px;
  }

  &-close__div{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &-title__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    margin: 5px;
    text-align: center;
  }
 
 &-accent__div{
   width: 28px;
   height: 3px;
   background-color: $default-red;
   display: block;
   margin: 15px auto 15px auto;
 }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
    padding: 5px;
  }

  &-catalog__div{
    display: flex;
    flex-flow: column wrap;
    padding: 5px;
  }

  &-catalog-header__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: $default-red;
  }

  &-header-details__p{
    color: $default-black;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 600;
  }

  &-details__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    line-height: 1.5;
  }

  &-conditions__div{
    padding: 5px;
  }

  &-details__span--strong{
    font-weight: 600;
  }


}