@import '../../scss/variables';

.checkoutdialogls{
  &-header__div{
    padding: 5px;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    padding: 5px;
    margin: 5px;
    line-height: 1.5;
    text-align: center;
  }

  &-accent__div{
    width: 35px;
    height: 3px;
    background-color: $default-red;
    display: block;
    margin: 5px auto 15px auto;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    line-height: 1.7;
    margin: 10px auto;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-buttons__div{
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
}