@import '../../scss/variables';

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%; 
  list-style: none;       
  
  > * {
    display: block;
    position: absolute;
    top:  45%; 
    left: 60%;
    width:  $item-size;
    height: $item-size;
    margin: -($item-size / 2);
  
    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: 
          rotate($rot * 1deg) 
          translate($circle-size / 2) 
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.bbtf{
  &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/bbtf-gallery-10-dropshadow-text-embedded.png"));
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-header-border__div{
    padding: 10px;
    width: 85%;
    margin: auto;
    position: relative;
  }

  &-header__div{
    color: $default-white;
    font-size: 15px;
    font-family: $default-serif-font;
    text-align: center;
    padding: 10px;
    animation-name: headerBackgroundBBTF;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  &-header__h1{
    font-size: 1.75em;
    font-family: inherit;
    font-weight: inherit;
  }

  &-about__div{
    padding: 50px 0 50px 0;
    background-color: #ebf5fb;
  }

  &-details-about__div{
    max-width: 300px;
    margin: auto;
  }

  &-header__h2{
    font-size: 18px;
    font-family: $default-serif-font;
    font-weight: 400;
    text-align: center;
    margin: 25px 0 0 0;
    // margin: 0;
  }

  &-header-accent__div{
    width: 28px;
    height: 3px;
    background-color: $default-red;
    margin: 15px auto 15px auto;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    text-align: center;
    width: 100%;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
    padding: 8px;
  }

  &-email-link__a{
    text-decoration: none;
    color: $default-white;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    background-color: $default-red;
  }

  &-email-link__div{
    margin: 16px auto;
    width: 240px;
    height: 40px;
    background-color: $default-red;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    font-family: inherit;

    &:hover{
      background-color: $hover-red;
    }
  }

  &-call-link__a{
    text-decoration: none;
    color: $default-red;
  }

  &-details-link__a{
    text-decoration: none;
    color: $default-red;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-divider__div{
    width: 80%;
    margin: 50px auto;
    height: 3px;
    background-color: $default-content-gray;
  }

  &-step__div{
    margin: auto;
    width: 80%;
  }

  &-icon__div{
    font-size: 100px;
    background-color: $default-red;
    width: 125px;
    height: 125px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $default-white;
    transition: all .5s ease;
  }

  &-arrow__div{
    font-size: 75px;
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 20px 0;
    color: #ff7a7a;
  }

  &-gallery__div{
    margin: 16px auto;
    display: flex;
    justify-content: center;
  }

  &-gallery-link__link{
    text-decoration: none;
    color: $default-white;
    background-color: $default-red;
    border-radius: 5px;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    display: flex;
    width: 160px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  &-image-divider__div{
    padding: 10px;
    width: 300px;
    margin: auto;
  }

  &-image-divider__img{
    width: 100%;
  }

  &-image-divider-2__div{
    width: 300px;
    margin: 30px auto;
  }

  &-header__h3{
    font-size: 15px;
    font-family: $default-serif-font;
    font-weight: 400;
    margin: 20px auto;
    text-align: center;
  }

  &-experts__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
  }

  &-expert-image__div{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid $default-red;
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 20px 0;
  }

  &-expert-image__img{
    width: 100%;
    position: relative;
    top: 25px;
  }

  &-header__h4{
    font-size: 15px;
    margin: 5px auto;
    font-family: $default-sans-serif-font;
    font-weight: 400;
    text-align: center;
  }

  &-top__div{
    margin-bottom: 50px;
  }

  &-seen-list__div{
    display: grid;
    grid-template-columns: 1fr;
    padding: 5px;
  }

  &-seen-category__div{
    padding: 5px;
    margin-bottom: 10px;
  }

  &-seen-items__div{
    padding: 5px;
    display: flex;
    flex-flow: column wrap;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 2;
    text-align: center;
  }

  &-seen-item__div{
    font-family: inherit;
    font-size: inherit;
  }

  &-seen-item__p{
    font-family: inherit;
    font-size: inherit;
  }

  @media only screen and (min-width: 374px) {
    &-image-divider-2__div{
      width: 350px;
      margin: 30px auto;
    }
  }


  @media only screen and (min-width: 766px) {
    &-hero__div{
      height: 500px;
    }

    &-header-border__div{
      width: 60%;
    }

    &-header__div{
      padding: 20px;
    }

    &-about__div{
      border-radius: 50%;
      margin: 50px 0 50px 0;
      position: relative;
      top: 0;
    }

    &-about-circle__div{
      width: 250px;
      height: 250px;
      position: absolute;
      background-color: pink;
      border-radius: 50%;
      right: 10px;
      top: -30px;
      opacity: .3;
    }

    &-details__div{
      font-size: 15px;
    }

    &-details-about__div{
      max-width: 600px;
    }

    &-steps-container__div{
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }

    &-steps__div--desktop{
      @include on-circle($item-count: 4, $circle-size: 15em, $item-size: 9em);
      margin: 100px auto 75px auto;
      border: solid 5px #ffbeb9;
    }

    &-step-num__p{
      font-family: $default-sans-serif-font;
      font-size: .3em;
      position: absolute;
      bottom: 1px;
    }

    &-how__div{
      position: relative;
    }

    &-icon__div{
      width: 75px;
      height: 75px;
      font-size: 50px;
      border: 2px solid $default-red;
    }

    &-step-details__div{
      width: 90%;
      align-self: center;
      position: relative;
      top: 15px;
    }

    &-accent__div{
      width: 50px;
      height: 3px;
      background-color: #ff7a7a;
      margin: 15px auto 15px auto;
    }

    &-process-details__div{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: 
      'process1 process1'
      'process2 divider1'
      'divider2 process3'
      'experts experts';
      align-items: center;
      justify-content: center;
      max-width: 725px;
      margin: 20px auto;
    }

    &-process-1__p{
      grid-area: process1;
    }

    &-process-2__p{
      grid-area: process2;
    }

    &-image-divider-1__div{
      grid-area: divider1;
    }

    &-image-divider-2__div{
      grid-area: divider2;
    }

    &-process-3__div{
      grid-area: process3;
    }

    &-process-4__p{
      grid-area: process4;
    }

    &-process-experts__div{
      grid-area: experts;
      margin: 24px 0;
    }
    &-experts__div{
      grid-template-columns: repeat(3, 1fr);
    }

    &-seen-category__div{
      margin-bottom: 40px;
    }

    &-header__h4{
      font-size: 18px;
      text-align: left;
    }

    &-accent-category__div{
      margin: 15px 10px 15px 0;
    }
    
    &-seen-list__div{
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 
      'commercial commercial'
      'movies movies'
      'tv tv'
      'interior interior'
      'theater theater';
      margin: 15px 10px;
      padding: 0px 50px;
    }

    &-interior{
      grid-area: interior;
    }

    &-theater{
      grid-area: theater;
    }

    &-commercial{
      grid-area: commercial;
    }

    &-tv{
      grid-area: tv;
    }

    &-movies{
      grid-area: movies;
    }

    &-seen-items__div{
      text-align: left;
      padding: 0;
    }

    &-seen-items-commercial{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &-seen-items-tv{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &-seen-items-movies{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (min-width: 1023px) {
    &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }

    &-email-link__a{
      &:hover{
        background-color: $hover-red;
      }
    }

    &-about__div{
      max-width: 900px;
      margin: 50px auto;
    }

    &-about-circle__div{
      width: 300px;
      height: 300px;
    }

    &-steps-container__div{
      max-width: 900px;
      margin: auto;
    }

    &-details-about__div{
      max-width: 700px;
      margin: auto;
    }

    &-process-details__div{
      max-width: 1200px;
    }

    &-icon__div{
      &:hover{
        background-color: $hover-red;
      }
    }

    &-icon__div--active{
      background-color: $default-white;
      color: $default-red;
      border: 2px solid $default-red;

      &:hover{
        color: $default-white;
        background-color: $hover-red;
      }
    }

    &-gallery-link__link{
      &:hover{
        background-color: $hover-red;
      }
    }

    &-experts__div{
      max-width: 1366px;
      margin: auto;
    }

    &-image-divider__div{
      width: 450px;
    }

    &-seen-list__div{
      max-width: 1200px;
      margin: auto;
    }

    &-seen-items-commercial{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &-seen-items-tv{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &-seen-items-movies{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (min-width: 1440px) {
    &-hero__div{
      height: 600px;
    }

    &-about__div{
      max-width: 1200px;
    }

    &-header-border__div{
      width: 600px;
    }

    &-seen-items-commercial{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    &-seen-items-tv{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    &-seen-items-movies{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

  }
}

@keyframes headerIntroBBTF {
  0% {
    width: 0;
    height: 0;
    border-top-color: #004b6d;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: #004b6d;
    border-right-color: #004b6d;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: rgba(255, 255, 255, .8);
    border-right-color: rgba(255, 255, 255, .8);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes headerIntro2BBTF {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #004b6d;
  }
  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #004b6d;
    border-left-color: #004b6d;

  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: rgba(255, 255, 255, .8);
    border-left-color: rgba(255, 255, 255, .8);
  }
}

@keyframes headerBackgroundBBTF {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(0, 75, 109, .8);
  }
}
