@import '../../scss/variables';

.searchbardesktop{

  &-container__div{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }

  &-main__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: #808080;
    transition: .5s all ease-in;
    position: relative;
    &:hover{
      color: $default-red;
    }
  }

  &-form__form{
    display: flex;
    flex-flow: row wrap;
  }

  &-input__input{
    border: 2px solid $default-content-gray;
    border-radius: 10px;
    padding: 1px;
    margin: 0;
    font-family: $default-sans-serif-font;
    min-height: 35px;
    background-color: transparent;
    width: 300px;
    font-size: 15px;
    color: $default-black;
    font-weight: 600;
    position: relative;
    text-indent: 95px;
    z-index: 1;
      &:focus{
        outline: none;
      }
      -webkit-box-shadow: inset 0px 2px 10px 1px $default-content-gray;
      -moz-box-shadow: inset 0px 2px 10px 1px $default-content-gray;
      box-shadow: inset 0px 2px 10px 1px $default-content-gray;
  }

  &-clear__p{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    color: $default-red;
  }

  &-advance-link__navlink{
    text-decoration: none;
    color: $default-red;
    font-family: $default-serif-font;
    font-size: 15px;
  } 

  @media only screen and (min-width: 1023px) {
    &-container__div{
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;
    }
  }

  @media only screen and (min-width: 1439px) {
    &-input__input{
      width: 400px;
    }
  }
}