@import '../../scss/variables';

.apparel{

  &-categories__div{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 5px;
  }

  &-category__div{
    padding: 5px;
    margin: 10px 0;
  }

  &-link__link{
    text-decoration: none;
    color: $default-black;
    &:hover{
      color: $hover-red;
    }
  }

  &-category-image__div{
    width: 275px;
  }

  &-category-image__img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 390px;
    border-radius: 25px;
    border: 3px solid $default-red;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    padding: 5px;
    margin: 5px;
    font-weight: 600;
  }

  &-header-category__h2{
    text-align: center;
  }

  &-border__div{
    width: 80%;
    height: 3px;
    background-color: #efefef;
    margin: 25px auto;
  }

  @media only screen and (min-width: 767px) {
    &-categories__div{
      grid-template-columns: repeat(2, 1fr);
      max-width: 900px;
      margin: auto;
    }

    &-category-image__div{
      width: 300px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-category-image__div{
      transition: all .5s ease;

      &:hover{
        transform: translateY(-5px);
      }
    }
  }

}