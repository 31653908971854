// $default-sans-serif-font: 'Proxima Nova', sans-serif;
$default-sans-serif-font: 'Trade Gothic Lt Std';
$default-serif-font: 'Libre Baskerville', serif;
$default-second-sans-serif-font: 'Strand Gothic Bold, sans-serif';
$default-red: #af272f;
$default-off-white: #fbfaf6;
$default-white: #ffffff;
$default-black: #000000;
$default-dark-gray: #333333;
// $default-yellow: #ffd112;
$default-yellow: #ffc72c;
$hover-yellow: #ffd256;
// $default-light-gray: #eeede9;
$default-light-gray: #f9f9f9;
$default-border-gray: #efefef;
$default-cream: #fbf5df;
$hover-red: #d4301a;
$default-content-gray: #d2d2d2;
// $default-footer-content-gray: #8a8a8a;
$default-footer-content-gray: #616161;

@function urlGeneratorSASS($str) {
    $IMAGE_URL: "https://d2p7wwv96gt4xt.cloudfront.net";
    // $IMAGE_URL: "https://www-img.strandbooks.com";
 
    @return "#{$IMAGE_URL}#{$str}";
}
