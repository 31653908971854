@import '../../scss/variables';

.checkoutordersummary{
  &-container__div{
    border-left: 2px solid $default-content-gray;
  }
  &-header__h2{
    text-align: center;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
    color: #5a5a5a;
  }
  &-items__div{
    padding: 5px;
    margin-top: 25px;
  }

  &-item__div{
    padding: 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.3;
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: 125px 1fr 65px;
    grid-template-rows: auto;
    grid-template-areas: 'product details total';
  }

  &-item-image__div{
    width: 100px;
    grid-area: product;
    position: relative;
  }

  &-gift-icon__div{
    background: $default-yellow;
    width: 30px;
    height: 30px;
    position: absolute;
    text-align: center;
    // transform: rotate(20deg);
    // bottom: 30px;
    // right: 0px;
    top: -10px;
    z-index: 1;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      background: $default-yellow;
      transform: rotate(135deg);
      z-index: 1;
    }
  }

  &-gift-icon__p{
    position: absolute;
    font-family: $default-sans-serif-font;
    font-size: 11px;
    z-index: 2;
    line-height: 1;
    top: 5px;
    color: $default-black;
  }

  &-item-details__div{
    grid-area: details;
    line-height: 1.5;
    font-family: inherit;
    font-size: inherit;
  }

  &-item-binding__p{
    text-transform: capitalize;
  }

  &-item-title__h3{
    font-size: 18px;
    margin: 5px 0;
    font-family: inherit;
    font-weight: 400;
  }

  &-accent__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    margin: 5px 0 10px 0;
  }

  &-item-image__img{
    width: 100%;
  }

  &-item-signed__div{
    font-family: inherit;
    font-size: inherit;
    color: $default-red;
  }

  &-item-discount-note__p{
    font-family: inherit;
    font-size: inherit;
    color: $default-red;
  }

  &-unavailable__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    align-items: center;
    position: relative;
    top: 2px;
    color: $default-yellow;
  }

  &-unavailable__p{
    font-family: $default-sans-serif-font;
    font-size: 11px;
    color: $default-red;
    text-align: left;
    font-weight: 600;
    position: relative;
    top: 2px;
  }

  &-item-price-qty__div{
    grid-area: total;
    align-self: center;
  }

  &-item-qty__select{
    color: $default-red;
    background-color: $default-cream;
    border: 1px solid $default-light-gray;
    margin: 10px;
    font-family: $default-sans-serif-font;
    font-size: 15px;

    &:focus{
      outline: none;
    }
  }

  &-item-qty__p{
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    padding: 5px;
  }

  &-more-items-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  &-continue-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 15px auto 40px auto;
    max-width: 240px;
  }

  &-cart-total__div{
    padding: 5px;
    background-color: $default-cream;
    border: $default-content-gray;
    margin: 5px;
  }

  &-cart-total-detail__div{
    display: grid;
    grid-template-columns: .7fr .3fr;
    grid-template-rows: auto;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    color: #5a5a5a;
    padding: 5px;
  }

  &-cart-total-header__p{
    justify-self: start;
  }

  &-cart-total-value__p{
    justify-self: center;
  }

  &-cart-total-divider__div{
    width: 50px;
    height: 2px;
    background-color: #5a5a5a;
    justify-self: center;
  }

  @media only screen and (min-width: 767px) {
    &-gift-icon__div{
      width: 40px;
      height: 40px;
  
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        width: 40px;
        background: $default-yellow;
        transform: rotate(135deg);
      }
    }
  
    &-gift-icon__p{
      font-size: 13px;
      z-index: 2;
      top: 8px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-unavailable__p{
      font-size: 13px;
    }
  }

  @media only screen and (min-width: 1439px) {
    &-container__div{
      border: none;
    }
  }
}