@import '../../scss/variables';

.checkoutform{
  &-form__div{
    padding: 5px;
  }

  &-form__form{
    display: flex;
    flex-flow: column wrap;
  }

  &-billing-input__div{
    display: flex;
    flex-flow: column wrap;
  }

  &-instructions__div{
    padding: 5px;
  }

  &-header__h3{
    padding: 5px;
    margin: 0 auto 0 0;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
    color: #5a5a5a;
  }

  &-count__span{
    font-size: 13px;
  }

  &-instructions__textarea{
    border: 1px solid rgba(0, 0, 0, .23);
    border-radius: 4px;
    font-size: 15px;
    font-family: $default-sans-serif-font;
    background-color: #fdfdfd;

    &:focus{
      outline: none;
      border: 2px solid $default-red;
    }

    &:hover{
      border: 1px solid $default-black;
    }
  }

  &-note__p{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    padding: 5px;
    color: $default-red;
  }
}
