@import '../../scss/variables';

.productsectionlp{

  &-list__div{
    padding: 5px;
    margin: 0px 10px 50px;
    display: grid;
    grid-template-columns: 1fr;
  }

  &-list-alpha__div{
    padding: 5px;
    margin: 10px 0;
  }

  &-list-alpha-header__div{
    padding: 5px;
    margin-left: 15px;
  }

  &-list-alpha-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    margin: 0;
  }

  &-prefix__div{
    padding: 10px 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  // &-prefix-header__h2{
  //   font-size: 22px;
  //   font-family: $default-sans-serif-font;
  //   font-weight: 600;
  //   margin-left: 25px;
  //   color: $default-footer-content-gray;
  // }

  &-prefix-link__link{
    text-decoration: none;
    color: $default-red;
    margin-left: 25px;
  }

  @media only screen and (min-width: 766px) {
    &-list__div{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
    }

    &-list-alpha-header__div{
      margin-left: 0px;
    }

    &-prefix__div{
      font-size: 15px;
    }

    &-prefix-link__link{
      margin-left: 5px;
    }
  }

  @media only screen and (min-width: 1023px) {

    &-list__div{
      grid-template-columns: repeat(4, 1fr);
      max-width: 1000px;
      margin: 50px auto;
    }

    &-prefix-link__link{
      &:hover{
        color: $hover-red;
      }
    }
  }

  @media only screen and (min-width: 1439px) {

    &-list__div{
      grid-template-columns: repeat(5, 1fr);
      max-width: 1366px;
    }

  }

  @media only screen and (min-width: 2559px) {

    &-list__div{
      grid-template-columns: repeat(9, 1fr);
      max-width: 1800px;
    }

  }
}