@import '../../scss/variables';

.bookhookup{

  &-header__div{
    padding: 5px;
    margin: 50px 0;
  }

  &-title__div{
    width: 300px;
    margin: auto;
    margin-top: 10px;
  }

  &-title__img{
    width: 100%;
  }

  &-header__h3{
    font-size: 18px;
    font-family: $default-sans-serif-font;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    margin: 10px 5px;
  }

  &-about__div{
    padding: 5px;
    background-color: $default-light-gray;
    margin: 25px auto;
  }

  &-about-image__div{
    width: 250px;
    margin: auto;
  }

  &-about-image__img{
    width: 100%;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    text-align: center;
  }

  &-notice__div{
    font-family: inherit;
    font-size: 1.5em;
    font-weight: 600;
    color: $default-red;
    text-align: center;
  }

  &-notice__p{
    font-size: 0.85em;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-details__p--strong{
    font-weight: 600;
  }

  &-link__link{
    text-decoration: none;
    color: $default-red;
    font-family: inherit;
    font-size: inherit;
  }

  &-details-link__a{
    text-decoration: none;
    color: $default-red;
    font-family: inherit;
    font-size: inherit;
  }

  &-Program_a {
    color: $default-red;
    text-decoration: none;
  }

  &-disclaimer__div{
    font-size: 13px;
    font-family: $default-sans-serif-font;
    font-weight: 600;
    padding: 10px;
    text-align: center;
  }

  &-disclaimer__p{
    font-size: 15px;
    font-family: inherit;
  }

  &-bookshelf__div{
    padding: 5px;
    margin-bottom: 25px;
  }

  &-shelf-link__link{
    text-decoration: none;
    color: $default-black;
  }

  &-image__div{
    width: 250px;
    margin: auto;
  }

  &-image__img{
    width: 100%;
  }

  &-header__h4{
    font-size: 18px;
    font-weight: 400;
    font-family: $default-sans-serif-font;
    margin: 10px 0;
    text-align: center;
  }

  &-h4-accent__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    margin: auto;
  }

  &-price__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 600;
    margin: 15px auto 0px auto;
    text-align: center;
  }

  &-price__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  &-note__div{
    padding: 5px;
  }

  &-note__div--min-height{
    min-height: 17px;
    padding: 5px;
    margin: 0;
  }

  &-note__p{
    font-size: 13px;
    color: $default-red;
    text-align: center;
  }

  &-options__div{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 20px auto 10px auto;
  }

  &-scarcity__div{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    text-align: center;
    color: $default-red;
    font-weight: 600;
    padding: 5px;
  }

  &-scarcity__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  &-add__div{
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding: 5px;
  }

  &-partners-feat__div{
    margin-bottom: 100px;
  }

  &-partners-past__div{
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
  }

  &-partner__div{
    padding: 5px;
    width: 90px;
    margin: 10px auto;
  }

  &-partner__img{
    width: 100%;
  }

  &-partner-slider__img{
    width: 125px;
    margin: auto;
  }

  @media only screen and (min-width: 767px) {
    &-title__div{
      width: 350px;
    }

    &-about__div{
      display: grid;
      grid-template-columns: 40% 1fr;
      align-items: center;
    }

    &-about-image__div{
      width: 300px;
      margin: 0;
    }

    &-details__div{
      // font-size: 18px;
      text-align: left;
    }

    &-bookshelves__div{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      margin-bottom: 50px;
    }

    &-image__div{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-note__div--min-height{
      min-height: 32px;
    }

  &-options__div{
    margin: 20px 0 10px 0;
  }

  &-partners-past__div{
    grid-template-columns: repeat(4, 1fr);
  }

  &-partner__div{
    width: 100px;
  }

  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }

    &-title__div{
      width: 400px;
    }

    &-header__h3{
      font-size: 25px;
      width: 800px;
      margin: auto;
    }

    &-about__div{
      grid-template-columns: 35% 1fr;
    }

    &-about-image__div{
      width: 350px;
    }

    &-shelf-link__link{
      &:hover{
        color: $default-red;
      }
    }

    &-bookshelves__div{
      grid-template-columns: repeat(4, 1fr);
    }

    &-partners-past__div{
      grid-template-columns: repeat(6, 1fr);
    }

    &-partner__div{
      width: 125px;
    }
  }

  @media only screen and (min-width: 1440px) {
    &-title__div{
      width: 500px;
    }

    &-header__h3{
      width: 1000px;
    }

    &-about__div{
      grid-template-columns: 30% 1fr;
    }

    &-about-image__div{
      width: 400px;
    }

    &-bookshelves__div{
      grid-template-columns: repeat(4, 1fr);
    }

    &-note__div--min-height{
      min-height: 17px;
    }

    &-partner__div{
      width: 150px;
    }
  }
}
