@import '../../scss/variables';

.footer{
  &-container__div{
  }
  &-item__div{
    border-bottom: 1px solid #dcdcdc;
  }
  &-item-header__div{
    background-color: $default-off-white;
    // background-color: $default-cream;
    padding: 10px;
    cursor: pointer;

    &:hover{
      background-color: $default-red;
    }

    &:active{
      background-color: $default-red;
    }
  }
  &-item__div--first{
    border-top: none;
  }
  &-btn__button{
    width: 100%;
    font-size: 15px;
    padding: 10px;
    border: none;
    background-color: $default-off-white;
    // background-color: $default-cream;
    text-align: left;
    cursor: pointer;
    font-family: $default-sans-serif-font;

    &:hover{
      color: $default-light-gray;
      background-color: $default-red;
    }
    &:focus{
      outline: none;
    }
  }

  &-btn__button--active{
    background-color: $default-red;
    color: $default-light-gray;
  }
  &-expand__expandless{
    position: absolute;
    right: 10px;
  }
  &-expand__expandmore{
    position: absolute;
    right: 10px;
  }

  &-collapse__div{
    display: flex;
    padding: 20px;
    flex-flow: column wrap;
    line-height: 2;
    font-size: 15px;
  }

  &-link__navlink{
    text-decoration: none;
    // color: $default-red;
    color: $default-black;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-link-header__navlink{
    width: 100%;
    font-size: 15px;
    text-decoration: none;
    color: $default-black;
  }

  &-link-header__a{
    width: 100%;
    font-size: 15px;
    text-decoration: none;
    color: $default-black;
    font-family: $default-sans-serif-font;
  }

  &-newsletter__div{
    text-align: center;
    padding: 5px;
    height: 300px;
    background-color: $default-off-white;
  }

  &-form-header__p{
    font-size: 15px;
    margin: 30px 0 20px 0;
    color: $default-red;
  }

  &-input__input{
    height: 20px;
    width: 200px;
    background-color: $default-white;
    border: 1px solid #bfbfbf;
  }

  &-input-submit__input{
    display: block;
    padding: 5px;
    margin: 20px auto;
    font-size: 15px;
    border: 2px solid $default-red;
    border-radius: 4px;
    width: 100px;
    background-color: $default-red;
    color: $default-white;
    cursor: pointer;
  }

  &-copyright__div{
    padding: 5px;
    margin: 40px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  &-copyright-text__p{
    font-size: 13px;
  }

  &-copyright-link__navlink{
    text-decoration: none;
    color: $default-black;

    &:hover{
      color: $default-red;
    }
  }

  &-logo__img{
    width: 30px;
    margin: 10px 20px;
  }
}