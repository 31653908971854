@import '../../scss/variables';

.upcomingevents{
    &-container__div{
      background-color: $default-off-white;
      padding: 5px;
      overflow: scroll;
    }

    &-events__div{
      margin-left: 10px;
    }

    &-event__div{
      display: grid;
      grid-template-areas: 'evImage title';
      grid-template-columns: 100px 1fr;
      grid-template-rows: auto;
      padding: 5px;
      margin: 20px auto;
    }

    &-event-image__div{
      grid-area: evImage;
      width: 100px;
    }

    &-event-image__img{
      width: 100%;
    }

    &-title__div{
      grid-area: title;
    }

    &-title-link__link{
      text-decoration: none;
      color: $default-red;

      &:hover{
        color: $hover-red;
      }
    }

    &-header__h3{
      font-family: $default-sans-serif-font;
      font-size: 15px;
      font-weight: 600;
      margin: 0 0 5px 0;
      padding: 0 10px;
      line-height: 1.5;
    
    }

    &-header__h2--gray{
      color: $default-dark-gray;
    }

    &-time__div{
      grid-area: time;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      padding: 0 10px;
      margin: 0 0 5px 0;
    }

    &-more__div{
      font-family: $default-sans-serif-font;
      font-size: 15px;
      padding: 0 10px;
    }

    &-more__a{
      font-family: inherit;
      font-size: inherit;
      text-decoration: none;
      color: $default-red;
      &:hover{
        color: $hover-red;
      }
    }

    &-details__div{
      font-family: $default-sans-serif-font;
      font-size: 15px;
      line-height: 1.5;
      padding: 0 10px;
    }

    &-view__div{
      text-align: center;
      padding: 5px;
    }

    &-view-link__link{
      text-decoration: none;
      color: $default-red;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      &:hover{
        color: $hover-red;
      }
    }

    @media only screen and (min-width: 1023px) {
      &-container__div{
        padding: 5px;
        margin: 0 25px;
        height: 1096px;
        position: absolute;
        top: 100px;
        width: 90%;
      }

      &-main__div{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        line-height: 1.7;
      }
    }
}