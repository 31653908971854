@import '../../scss/variables';

.productlistdrawer{
  &-container__div{
    // display: flex;
    // flex-flow: column wrap;
    width: 100%;
    margin-bottom: 50px;
    display: grid; 
    grid-template-columns: 1fr;
  }

  &-chips__div{
    display: flex;
    flex-flow: row wrap;
    // height: 100px;
  }

  &-chip__div{
    padding: 5px;
  }

  &-clear__div{
    width: 100px;
    // border: 2px solid $default-red;
    height: 30px;
    color: $default-red;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    border-radius: 5px 5px;
    display: block;
    margin: auto;
    // margin-top: 15px;

    &:hover{
      color: red;
    }
  }

  &-clear-btn__buttonbase{
    font-size: inherit;
    font-family: inherit;
    justify-content: center;
    width: 100%;
  }

  &-clear-btn__p{
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
  }

  &-filter__div{
    padding: 10px;
  }

  &-price__div{
    margin-bottom: 20px;
  }

  &-range__div{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &-apply__div{
    width: 150px;
    // border: 2px solid $default-red;
    height: 30px;
    color: $default-red;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    border-radius: 5px 5px;
    display: block;
    margin: 15px auto 0px auto;

    &:hover{
      color: $hover-red;
    }
  }

  &-filter-header__div{
    display: grid;
    grid-template-columns: 70% 1fr;
    grid-template-rows: auto;
    align-content: center;
  }
  &-filter-header__h2{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
    justify-self: start;
    width: 100%;
  }

  &-filter-expand__div{
    justify-self: center;
  }

  &-filter-details__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-filter-details-subsection-container__div{
    display: flex;
    flex-flow: column wrap;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 20px 0;
  }

  &-filter-details-subsection__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-family: $default-sans-serif-font;
    font-size: 13px;
  }

  &-filter-text-items__div{
    font-size: inherit;
    font-family: inherit;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    width: 150px;
  }

  &-filter__p{
    font-size: inherit;
    font-family: inherit;
    line-height: 1.5;
  }

  &-filter__p--strong{
    font-weight: 600;
    font-size: 15px;
  }

  @media only screen and (min-width: 767px) {
    &-container__div{
      // display: flex;
      // flex-flow: column wrap;
      padding: 10px;
      width: 100%;
      position: sticky;
      top: 0;
    }

    &-chips__div{
      display: flex;
      flex-flow: row wrap;
    }

    &-chip__div{
      padding: 5px;
    }

    &-clear__div{
      width: 100px;
      // border: 2px solid $default-red;
      height: 30px;
      color: $default-red;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      border-radius: 5px 5px;
      display: block;
      margin: auto;
      margin-top: 15px;

      &:hover{
        color: red;
      }
    }

    &-clear-btn__buttonbase{
      font-size: inherit;
      font-family: inherit;
      justify-content: center;
      width: 100%;
    }

    &-clear-btn__p{
      font-family: inherit;
      font-size: inherit;
      padding: 5px;
    }

    &-price__div{
      margin-bottom: 20px;
    }

    &-apply__div{
      width: 150px;
      // border: 2px solid $default-red;
      height: 30px;
      color: $default-red;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      border-radius: 5px 5px;
      display: block;
      margin: auto;
      margin-top: 15px;

      &:hover{
        color: $hover-red;
      }
    }

    &-filter-header__div{
      display: grid;
      grid-template-columns: 70% 1fr;
      grid-template-rows: auto;
      align-content: center;
    }
    &-filter-header__h2{
      font-family: $default-sans-serif-font;
      font-size: 18px;
      font-weight: 400;
      justify-self: start;
      width: 100%;
    }

    &-filter-expand__div{
      justify-self: center;
    }

    &-filter-details__div{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      font-family: $default-sans-serif-font;
      font-size: 15px;
    }

    &-filter-text-items__div{
      font-size: inherit;
      font-family: inherit;
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      width: 150px;
    }

    &-filter__p{
      font-size: inherit;
      font-family: inherit;
      line-height: 1.5;
    }

  }

  @media only screen and (min-width: 1023px) {
    &-filter-header__h2{
      // font-size: 20px;
    }

    &-filter-text-items__div{
      width: 175px;
    }
    &-filter-signed__div{
      // font-size: 18px;
    }
  }
}