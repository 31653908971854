@import '../../scss/variables';

.globalbanner{
  &-container__div{
    color: $default-white;
    background-color: $default-yellow;
    width: 100%;
    z-index: 1100;
    font-size: 13px;
    line-height: 1.5;
    transition: all .5s ease;
  }

  &-container__div--nodisplay{
    display: none;
  }

  &-link__a{
    text-decoration: none;
    color: $default-black;
    transition: .3s all ease;
  }

  &-message__p{
    font-size: 1em;
    font-family: $default-sans-serif-font;
    text-align: center;
    padding: 10px;
  }

  &-message__p--black{
    color: $default-black;
  }

  @media only screen and (min-width: 766px){
    &-container__div{
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-link__a{
      &:hover{
        color: $default-red;
      }
      &:focus{
        outline: none;
      }
    }
  }
}