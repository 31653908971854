@import '../../scss/variables';

$gg-grid-gutter: 20px;
$gg-col-width: 300px;
$gg-col-padding: $gg-grid-gutter / 2;

.twelvedaysofholiday2021 {
  &-container__div {
    border: 5px solid $default-yellow;
  }
  &-hero__div {
    margin: 25px auto;
    width: 300px;
    padding: 25px 5px;
  }

  &-hero__img {
    width: 100%;
  }

  &-intro__div {
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    text-align: center;
    margin: 25px auto;
  }

  &-intro__p {
    font-family: inherit;
    font-size: inherit;
  }

  &-border__div {
    height: 3px;
    background-color: $default-red;
    width: 80%;
    margin: 15px auto;
  }

  &-categories__section {
    max-width: 3 * ($gg-col-width + $gg-grid-gutter);
    margin: 0 auto;
  }

  &-categories__div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px (-1 * $gg-col-padding);
  }
  
  &-category__div {
    max-width: 100%;
    padding: 25px $gg-col-padding;
    transition: .5s all ease;

    &:hover {
      transform: translateY(-5px);
    }
  }
  
  &-category {
    width: $gg-col-width;
  }

  &-category-image__img {
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
    width: 100%;
    &:hover {
        box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
    }
  }

  @media only screen and (min-width: 374px) {
    &-container__div {
      padding: 15px;
    }
  }

  @media only screen and (min-width: 425px) {
    &-hero__div {
      width: 350px;
    }
  }

  @media only screen and (min-width: 767px) {
    &-hero__div {
      width: 100%;
      padding: 0;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__div {
      max-width: 1366px;
      margin: auto;
    }

    &-intro__div {
      font-size: 18px;
    }
  }
}