@import '../../scss/variables';

.productmetalist{
    &-lp-image__div{
      width: 100%;
      margin: 50px 0 25px 0;
    }

    &-lp-image__img{
      width: 100%;
    }

    &-lp-desc__div{
      font-family: $default-sans-serif-font;
      font-size: 15px;
      padding: 5px;
      text-align: center;
      line-height: 1.5;
      font-weight: 600;
    }
  
    &-promotetop__div{
      width: 100%;
      margin: auto;
    }

    &-promotetop__img{
      width: 100%;
    }

    &-products__div{
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      padding: 5px;
      margin: 50px 0;
    }
    
    &-product-link__link{
      text-decoration: none;
      color: $default-black;
      transition: .5s all ease;
    }
    
    &-product__div{
      width: 150px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      padding: 5px;
    }

    &-image__div{
      width: 100px;
      padding: 5px;
      margin: 20px auto 0 auto; 
      position: relative;
    }

    &-flag__div{
      width: 96px;
      background-color: $default-yellow;
      font-family: $default-sans-serif-font;
      font-size: 11px;
      line-height: 1.5;
      text-align: center;
      display: flex;
      align-items: center;
      color: $default-black;
      font-weight: 400;
      justify-content: center;
      border: 2px solid $default-yellow;
      padding: 5px 0;
    }

    &-bestseller-flag__div{
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $default-yellow;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      text-align: center;
      display: flex;
      align-items: center;
      color: $default-black;
      font-weight: 600;
      justify-content: center;
      border: 2px solid $default-yellow;
      right: -10px;
      top: -10px;
    }
    
    &-image__img{
      width: 100%;
    }
    
    &-details__div{
      text-align: center;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      line-height: 1.5;
    }
    
    &-header__h4{
      font-family: $default-sans-serif-font;
      font-size: 18px;
      font-weight: 400;
      margin: 25px 5px 10px 5px;
    }
    
    &-details__p{
      font-family: inherit;
      font-size: inherit;
      padding: 5px;
      line-height: 1.5;
    }

    &-details-out-of-stock__p{
      color: $default-red;
      font-weight: 600;
    }
    
    &-no-products__p{
      margin: 50px 0;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      text-align: left;
      line-height: 1.7;
      text-align: center;
    }

    @media only screen and (min-width: 374px) {
      &-image__div{
        width: 125px;
      }

      &-flag__div{
        width: 121px;
        font-size: 13px;
        top: 40%;
      }

      &-bestseller-flag__div{
        width: 40px;
        height: 40px;
      }
    }

    @media only screen and (min-width: 767px) {
      &-lp-desc__div{
        font-size: 18px;
      }

      &-promotetop__div{
        width: 750px;
      }
      
      &-products__div{
        grid-template-columns: repeat(3, 1fr);
      }

      &-product__div{
        width: 200px;
      }
    }

    @media only screen and (min-width: 1023px) {
      &-container__div{
        max-width: 1366px;
        margin: auto;
      }

      &-content__div{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
      }

      &-lp-image__div{
        max-width: 1300px;
        margin: 50px auto;
      }

      &-promotetop__div{
        width: 1000px;
      }

      &-products__div{
        grid-template-columns: repeat(5, 1fr);
      }

      &-product-link__link{
        &:hover{
          color: $default-red;
        }
      }
  
      &-image__div{
        transition: .5s all ease;
        &:hover{
          transform: translateY(-5px);
        }
      }
    }

    @media only screen and (min-width: 1366px) {
  
      &-promotetop__div{
        width: 1366px;
      }
    }
}