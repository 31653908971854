@import '../../scss/variables';

.bannedbooks{

  &-header__div{
    padding: 5px;
  }

  &-header-link__link{
    text-decoration: none;
    color: $default-red;
    &:hover{
      text-decoration: underline;
      color: $hover-red;
    }
  }

  &-title__div{
    margin: auto;
    margin-top: 10px;
  }

  &-title__img{
    width: 100%;
  }

  &-header__h1--spoiler{
    font-size: 25px;
    font-family: $default-sans-serif-font;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    margin: 5px 5px;
    color: $default-red;
  }

  &-header__h2{
    font-size: 18px;
    font-family: $default-sans-serif-font;
    text-align: center;
    margin: 10px 5px;
  }

  &-subhead__section{
    text-align: center;
  }

  &-subhead-button__link{
    background-color: $default-red;
    display: inline-block;
    font-family: $default-sans-serif-font;
    text-decoration: none;
    color: #ffffff;
    min-width: 130px;
    font-size: 18px;
    border: 2px solid $default-red;
    border-radius: 4px;
    padding: 10px;
    text-align: center;

    &:hover {
        background-color: transparent;
        color: $default-red;
    }
  }

  &-book__div{
    padding: 5px;
  }

  &-book__div--odd{
    background-color: $default-light-gray;
  }

  &-book-image__div{
    width: 150px;
    padding: 5px;
    margin: auto;
  }

  &-book-image__img{
    width: 100%;
  }

  &-header__h3{
    font-size: 15px;
    font-family: $default-sans-serif-font;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    margin: 10px 5px;
  }

  &-header__h4{
    font-size: 15px;
    font-family: $default-sans-serif-font;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    margin: 10px 5px;
  }

  &-book-details__div{
    font-size: 15px;
    font-family: $default-sans-serif-font;
    padding: 5px;
    line-height: 1.7;
    margin: 15px;
  }

  @media only screen and (min-width: 767px) {
    &-book__div{
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-template-areas:
      'shelf shelf'
      'bookInfo details'
      '. details';
    }

    &-header__subheader{
      grid-area: shelf;
    }

    &-book-info__div{
      grid-area: bookInfo;
    }

    &-book-title__div{
      grid-area: title;
    }

    &-book-details__div{
      grid-area: details;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
        max-width: 1100px;
        margin: 50px auto;
      }
  }
}