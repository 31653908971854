@import '../../scss/variables';

.rare{
  &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/rare-main-hero.jpg"));
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-header-border__div{
    padding: 10px;
    width: 85%;
    margin: auto;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 3px solid transparent;
      animation: headerIntro3 1.5s linear forwards;
      animation-delay: .75s;
    }

    &:after{
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 3px solid transparent;
      animation: headerIntro4 1.5s linear forwards;
      animation-delay: .75s;
    }
  }

  &-header__div{
    color: $default-white;
    font-size: 15px;
    font-family: $default-serif-font;
    text-align: center;
    padding: 10px;
    animation-name: headerBackgroundRare;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  &-header__h1{
    font-size: 1.75em;
    font-family: inherit;
    font-weight: inherit;
  }

  &-container__div{
    background-color: $default-white;
  }

  &-header__h2{
    font-size: 18px;
    font-family: $default-serif-font;
    font-weight: 400;
    text-align: center;
    margin: 50px 0 0 0;
  }

  &-header-accent__div{
    width: 28px;
    height: 3px;
    background-color: $default-red;
    margin: 15px auto 5px auto;
  }

  &-featured__div{
    margin: 50px auto;
  }

  &-product-wrapper__div{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  &-product-image__div{
    width: 150px;
  }

  &-product-image__img{
    width: 100%;
  }

  &-product-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    text-align: center;
    width: 100%;
    padding: 5px;
  }

  &-product-title__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  &-header__h3{
    font-family: $default-serif-font;
    font-size: 18px;
    font-weight: 400;
    margin: 5px 0;
  }

  &-product-accent__div{
    width: 28px;
    height: 3px;
    background-color: #d4af37;
    margin: 10px auto;
  }

  &-product-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-product-options__div{
    padding: 5px;
    margin: 10px 0;
  }

  &-product-description__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    text-align: center;
    line-height: 1.7;
    margin: 50px 0 5px 0;
  }

  &-product-details__p--gray{
    color: $default-dark-gray;
  }

  &-divider__div{
    width: 80%;
    margin: 50px auto;
    height: 3px;
    background-color: $default-content-gray;
  }

  &-notice__div{
    padding: 8px;
    line-height: 1.5;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
    color: $default-red;
    max-width: 800px;
    margin: 0 auto;
  }

  &-notice__p{
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
  }

  &-notice__a{
    color: $default-black;

    &:hover{
      color: $hover-red;
    }
  }

  &-subjects__div{
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  &-subject__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    line-height: 1.7;
  }

  &-subject-link__link{
    text-decoration: none;
    color: $default-dark-gray;
    font-family: inherit;
    font-size: 1em;
  }

  &-experts__div{
    background-color: $default-cream;
    padding: 25px 0 25px 0;
  }

  &-experts-list__div{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 5px;
  }

  &-expert__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 20px 0;
    padding: 5px;
  }

  &-expert-image__div{
    padding: 5px;
    margin: auto;
    width: 80%;
  }

  &-details__div{
    text-align: center;
    font-family: $default-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-details__p{
    font-family: inherit;
    font-size: 1em;
  }

  &-expert-image__img{
    width: 100%;
  }

  &-detail-name__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-description__div{
    margin: 20px 0;
  }

  &-logo-image__div{
    margin: auto;
    width: 150px;
  }

  @media only screen and (min-width: 450px) {
    &-dots__div{
      top: 540px;
    }
  }

  @media only screen and (min-width: 767px){
    &-hero__div{
      height: 500px;
    }
    &-header-border__div{
      width: 60%;
    }

    &-header__div{
      padding: 20px;
    }

    &-product-wrapper__div{
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      align-items: start;
      grid-template-areas: 
      'featImage featDetails'
      'featOptions featOptions'
      'featDesc featDesc'
      'featExpand featExpand';
    }

    &-product-image__div{
      margin: 0;
      width: 175px;
      grid-area: featImage;
    }

    &-product-image__img{
      margin: 0;
      width: 100%;
    }

    &-product-details__div{
      text-align: left;
      grid-area: featDetails;
    }

    &-product-accent__div{
      margin: 10px 0;
    }

    &-product-options__div{
      grid-area: featOptions;
    }

    &-product-description__div{
      grid-area: featDesc;
    }

    &-product-description-button__div{
      grid-area: featExpand;
    }

    &-dots__div{
      top: 335px;
    }

    &-subjects__div{
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-experts-list__div{
      grid-template-columns: 300px 1fr;
      grid-template-areas:
      'expert desc'
      'abaa abaa';
    }

    &-detail-name__div{
      font-size: 15px;
      text-align: center;
    }

    &-expert-image__div{
      width: 250px;
    }

    &-expert__div{
      grid-area: expert;
    }

    &-description__div{
      grid-area: desc;
      align-self: center;
    }

    &-details__p{
      text-align: left;
    }

    &-logo-image__div{
      grid-area: abaa;
      width: 150px;
    }

  }

  @media only screen and (min-width: 1023px) {
    &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-header-border__div{
      width: 600px;
    }

    &-container__div{
      margin: auto;
    }

    &-featured__div{
      max-width: 1366px;
      margin: 50px auto;
    }

    &-product-wrapper__div{
      grid-template-columns: 500px 1fr;
    }

    &-product-image__div{
      width: 300px;
      justify-self: end;
    }

    &-product-image__img{
      width: 200px;
    }

    &-product-options__div{
      justify-self: center;
    }

    &-product-details__div{
      font-family: $default-sans-serif-font;
      justify-self: start;
    }

    &-product-description__div{
      max-width: 900px;
      margin: 50px auto 5px auto;
    }

    &-subjects__div{
      max-width: 1366px;
      margin: 50px auto;
    }

    &-subject-link__link{
      &:hover{
        color: $default-red;
      }
    }

    &-experts-list__div{
      max-width: 800px;
      margin: auto;
    }
  }

  @media only screen and (min-width: 1440px) {
    &-hero__div{
      height: 600px;
    }
  }
}

@keyframes headerIntro {
  0% {
    width: 0;
    height: 0;
    border-top-color: $default-red;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: $default-red;
    border-right-color: $default-red;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: rgba(255, 255, 255, .8);
    border-right-color: rgba(255, 255, 255, .8);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes headerIntro3 {
  0% {
    width: 0;
    height: 0;
    border-top-color: #d4af37;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: #d4af37;
    border-right-color: #d4af37;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: rgba(255, 255, 255, .8);
    border-right-color: rgba(255, 255, 255, .8);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes headerIntro2 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: $default-red;
  }
  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: $default-red;
    border-left-color: $default-red;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: rgba(255, 255, 255, .8);
    border-left-color: rgba(255, 255, 255, .8);
  }
}

@keyframes headerIntro4 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #d4af37;
  }
  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #d4af37;
    border-left-color: #d4af37;
  }

  100% {
    width: 100%;
    height: 100;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: rgba(255, 255, 255, .8);
    border-left-color: rgba(255, 255, 255, .8);
  }
}

@keyframes headerBackgroundRare {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(191, 46, 26, .3);
  }
}