@import '../../scss/variables';

.compfilter{
  &-content__div{
    margin-bottom: 75px;
    padding: 5px;
    margin-left: 15px;
  }

  &-close__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  &-filter__div{
    margin: 15px 0;
  }

  &-header__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  &-header__h2{
    margin: 5px auto 5px 5px;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
  }

  &-header__h2--no-margin{
    margin: 5px 0;
  }

  &-range__div{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &-checkbox__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  &-details__p{
    padding: 5px;
  }

  &-apply__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 5px;
    margin: 15px 0;
  }
}