@import '../../scss/variables';

.productlistpagination{
  &-container__div{
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 20px auto;
  }

  &-container__div--nodisplay{
    display: none;
  }

  &-pagination__ul{
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    align-items: center;
    font-size: 15px;
    font-family: $default-sans-serif-font;
  }

  &-page__li{
    padding: 0 10px;
  }

  &-previous__li{
    &:focus{
      outline: none;
    }
  }

  &-previous-link__a{
    &:focus{
      outline: none;
    }
  }

  &-previous__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: $default-red;
    font-size: 15px;
    font-family: $default-sans-serif-font;
    cursor: pointer;

    &:focus{
      outline: none;
    }
  }

  &-previous-text__p{
    color: $default-black;
    font-size: inherit;
    font-family: inherit;
  }

  &-next__li{
    &:focus{
      outline: none;
    }
  }

  &-next-link__a{
    &:focus{
      outline: none;
    }
  }

  &-next__div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: $default-red;
    font-size: 15px;
    font-family: $default-sans-serif-font;
    cursor: pointer;

    &:focus{
      outline: none;
    }
  }

  &-next-text__p{
    color: $default-black;
    font-size: inherit;
    font-family: inherit;
    transition: all .5s ease;
  }

  &-page__li{
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;

    &:focus{
      outline: none;
    }
  }

  &-page__li--active{
    color: $default-red;
  }

  &-page-link__a{
    &:focus{
      outline: none;
    }
  }

  @media only screen and (min-width: 1023px){
    &-next-text__p{
      &:hover{
        color: $default-red;
      }
    }
    &-previous-text__p{
      &:hover{
        color: $default-red;
      }
    }
  }
}