@import '../../scss/variables';

.bbtfcategory{
   &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/bbtf-gallery-10-dropshadow-text-embedded.png"));
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }

   &-products__div{
     display: grid;
     grid-template-columns: 1fr;
     grid-template-rows: auto;
   }

   &-product__div{
     padding: 5px;
     margin: 10px 0;
   }

   &-product-link__link{
    text-decoration: none;
    color: $default-black;
   }
   &-image__div{
    width: 175px;
    margin: auto;
    height: 195px;
    display: flex;
    align-items: center;
   }

   &-image__img{
     width: 100%;
   }

   &-header__h4{
     font-family: $default-sans-serif-font;
     font-size: 15px;
     text-align: center;
   }

   &-details__div{
     font-family: $default-sans-serif-font;
     font-size: 15px;
     text-align: center;
     padding: 5px;
   }

   @media only screen and (min-width: 374px) {
      &-products__div{
        grid-template-columns: repeat(2, 1fr);
      }
   }

   @media only screen and (min-width: 767px) {
      &-hero__div{
        height: 500px;
      }

      &-products__div{
        grid-template-columns: repeat(4, 1fr);
      }

      &-image__div{
        width: 150px;
      }
   }

   @media only screen and (min-width: 1023px) {
     &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
     }

     &-content__div{
        max-width: 1366px;
        margin: auto;
     }

     &-products__div{
       grid-template-columns: repeat(5, 1fr);
     }

     &-image__div{
       transition: all .5s ease;
       &:hover{
         transform: translateY(-5px);
       }
     }
   }

   @media only screen and (min-width: 1440px) {
     &-hero__div{
      height: 600px;
     }
   }
}