@import '../../scss/variables';

.authorsbookshelves{
  &-image__div{
    width: 100%;
  }

  &-image__img{
    width: 100%;
  }

  &-header__div{
    text-align: center;
    font-family: $default-sans-serif-font;
  }

  &-header__h2{
    font-family: $default-sans-serif-font;
    font-weight: 400;
    font-size: 25px;
    margin: 5px;
  }

  &-accent__div{
    width: 35px;
    height: 3px;
    background-color: $default-red;
    display: block;
    margin: 15px auto 15px auto;
  }

  &-feature__div{
    margin-top: 50px;
  }

  &-feat-image__div{
    width: 300px;
    height: 300px;
    border-radius: 50% 50%;
    border: 5px solid #b8222e;
    overflow: hidden;
    margin: 25px auto 0 auto;
  }

  &-feat-image__img{
    width: 300px;
    height: 300px;

  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-weight: 400;
    font-size: 18px;
  }

  &-feat-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    text-align: center;
    padding: 5px;
  }

  &-view__div{
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 25px auto;
    width: 240px;
    height: 40px;
  }

  &-border__div{
    height: 3px;
    width: 80%;
    background-color: $default-border-gray;
    margin: 50px auto;
  }

  &-list__div{
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
  }

  &-item__div{
    margin: 25px 0;
  }

  &-item-image__div{
    width: 275px;
    height: 275px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    border-radius: 50% 50%;
    border: 5px solid $default-red;
    transition: 1s all ease;

    &:hover{
      transform: scale(1.05);
    }
  }

  &-header-authors__div{
    background-color: #b8222e;
    width: 100%;
  }

  &-header__h4{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
    margin: 5px;
    line-height: 1.5;
    color: $default-white;
  }

  &-view__link{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    background-color: #af272f;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    text-decoration: none;

    &:hover{
      background-color: $hover-red;
    }
  }

  &-author-link__link{
    text-decoration: none;
  }

  @media only screen and (min-width: 767px) {
    &-feature__div{
      display: grid;
      grid-template-areas:
      'header header header'
      'author name name'
      'author details details'
      'author view view';
    }

    &-feature-header__div{
      grid-area: header;
    }

    &-feat-image__div{
      grid-area: author;
      margin: 10px;
      align-self: center;
    }

    &-header-name__div{
      grid-area: name;
      align-self: end;
    }

    &-header__h3{
      font-size: 18px;
    }

    &-feat-details__div{
      grid-area: details;
    }

    &-view__div{
      grid-area: view;
      margin: 0;
      align-self: center;
      justify-self: center;
    }

    &-list__div{
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 1023px) {
      &-feature__div{
        max-width: 1200px;
        margin: 50px auto 0 auto;
      }

      &-list__div{
        grid-template-columns: repeat(3, 1fr);
      }
  }

  @media only screen and (min-width: 1439px) {
    &-list__div{
      max-width: 1600px;
      margin: 0 auto 50px auto;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}