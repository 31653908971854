@import '../../scss/variables';

.about{
  &-hero__div{
    background-image: url(urlGeneratorSASS('/S/static/about_nancy-fred-bw_original.jpg'));
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    margin: 5px;
  }

  &-header__h2{
    font-family: inherit;
    font-size: 18px;
    font-weight: 400;
    color: $default-red;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
    margin-left: 10px;
  }

  &-bottom-hero__div{
    height: 300px;
    background-image: url(urlGeneratorSASS('/S/static/about_nancy-fred-color.jpg'));
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 100px 0;
    width: 100%;
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      height: 400px;
      background-position:  55% -125px;
    }

    &-bottom-hero__div{
      height: 400px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }
    &-hero__div{
      height: 500px;
    }

    &-bottom-hero__div{
      height: 600px;
    }
  }

  @media only screen and (min-width: 1439px) {
    &-hero__div{
      height: 700px;
      background-position: 50% -275px;
    }

    &-bottom-hero__div{
      height: 700px;
      background-position: 50% -100px;
    }
  }

  @media only screen and (min-width: 2559px) {
    &-hero__div{
      height: 800px;
      background-position: 50% -450px;
    }

    &-bottom-hero__div{
      height: 800px;
      background-position: 50% -200px;
    }
  }
}