@import '../../scss/variables';

.bbtffaq{
  &-container__div{
    margin-bottom: 50px;
  }

  &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/bbtf-gallery-10-dropshadow-text-embedded.png"));
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-questions__div{
    padding: 5px;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }
  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 25px;
    font-weight: 600;
    color: $default-red;
  }
  &-header__h4{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0;
  }
  &-accent__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    margin: 0 0 10px 0;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      height: 500px;
    }

    &-content__div{
      display: grid;
      grid-template-columns: 1fr 200px;
      grid-template-rows: auto;
      grid-template-areas: 
      'questions sidenav';
      position: relative;
    }

    &-questions__section{
      grid-area: questions;
    }

    &-sidenav__aside{
      grid-area: sidenav;
    }

    &-sidenav__div{
      position: sticky;
      top: 0;
      padding: 5px;
    }

    &-question-header__div{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    &-header__h5{
      font-family: $default-sans-serif-font;
      font-size: 13px;
      font-weight: 400;
    }

    &-link__div{
      padding: 5px;
    }

    &-link__a{
      text-decoration: none;
      color: $default-dark-gray;
      font-family: $default-sans-serif-font;
      font-size: 13px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }

    &-content__div{
      max-width: 1366px;
      margin: auto;
      grid-template-columns: 1fr 300px;
    }

    &-details__div{
      font-size: 15px;
    }

    &-header__h4{
      font-size: 18px;
    }

    &-header__h5{
      font-size: 15px;
    }
    &-link__a{
      font-size: 15px;

      &:hover{
        color: $default-red;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    &-hero__div{
      height: 600px;
    }
  }
}