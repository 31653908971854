@import "../../scss/variables";

.holiday-shop {
    &-details__div {
        font-family: $default-sans-serif-font;
        font-size: 15px;
        line-height: 1.7;
        padding: 5px;
        margin: 5px;
    }

    &-content__div {
        margin-bottom: 40px;
    }

    @media only screen and (min-width: 1023px) {
        &-container__div {
            max-width: 1366px;
            margin: auto;
        }
    }
}
