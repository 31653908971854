@import '../../scss/variables';

.checkoutsnackbar{
  &-container__div{
    display: grid;
    grid-template-columns: 1fr 5%;
    padding: 5px;
    width: 100%;
  }
  &-content__div{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    color: $default-white;
  }

  &-message__div{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: $default-white;
    font-size: 15px;
    font-family: $default-sans-serif-font;
    line-height: 1.5;
    padding: 0 5px;
  }

  &-message__p{
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
  }

  &-close-btn__iconbutton{
    justify-self: flex-end;
    align-self: flex-start;
  }

  @media only screen and (min-width: 767px) {
    &-space__div{
      width: 600px;
    }
  }
}