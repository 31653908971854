@import '../../scss/variables';

.productsectionsub{
  &-subsections__div{
    padding: 5px;
    margin-left: 10px;
  }

  &-subsection__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.5;
    padding: 5px;
    margin: 10px 0;
  }

  &-subsection-link__link{
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    color: $default-red;
  }

  @media only screen and (min-width: 766px){
    &-subsections__div{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
    }
    &-subsection__div{
        font-size: 15px;
      }
  }

  @media only screen and (min-width: 1023px){
    &-content__div{
      max-width: 1366px;
      margin: 50px auto;
    }
    &-subsections__div{
      grid-template-columns: repeat(4, 1fr);
      max-width: 1000px;
      margin: 50px auto;
    }

    &-subsection-link__link{
      &:hover{
        color: $hover-red;
      }
    }
  }
}