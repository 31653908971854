@import '../../scss/variables';

.checkout{

  &-container__div{
    margin-top: 50px;
  }

  &-redirect-dialog-container__div{
    padding: 30px 20px 30px;
  }

  &-redirect-dialog-message__p{
    margin: 0 0 20px;
  }

  &-redirect-dialog-button-wrapper__div{
    text-align: center;
  }
    color: #ffffff;
    display: inline-block;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    background-color: $default-red;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    transition: all .5s ease;

    &:hover{
      background-color: $hover-red;
      box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
    }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: 50px auto;
    }
  }
}