@import '../../scss/variables';

.submissionsclosed{
    &-content__div{
      margin: 50px 0;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
    }
  
    &-image__div{
      width: 125px;
      padding: 5px;
      margin: auto;
    }
  
    &-image__img{
      width: 100%;
    }
  
    &-header__h2{
      font-family: $default-sans-serif-font;
      font-size: 18px;
      text-align: center;
      color: $default-red;
    }
  
    &-details__div{
      font-family: $default-sans-serif-font;
      font-size: 15px;
      padding: 5px;
      text-align: center;
      line-height: 1.7;
    }
  
    &-home-btn__div{
      width: 160px;
      margin: 15px auto;
    }
  }