@import '../../scss/variables';

.inpageloading{
    &-container__div{
      position: fixed;
      top: 50%;
      left: 50%;
      margin-left: -40px;
      margin-top: -40px;
      z-index: 10000;
    }

    &-material__circularprogress{
      width: 80px !important;
      height: 80px !important;
    }
  }
