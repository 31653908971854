@import '../../scss/variables';

.event{
  &-content__main {
   a:not(.event-product-btn__a):not(.event-ticket-btn__a) {
     text-decoration: none;
     color: #af272f;
   }
  }

  &-back__div{
    padding: 5px;
    margin: 50px 25px 0 25px;
  }

  &-details__div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
    padding: 5px;
    margin: 50px 0;
  }

  &-book-image__div{
    width: 125px;
    padding: 5px;
    margin: auto;
  }

  &-book-image__img{
    width: 100%;
  }

  &-ticket-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 150px;
    margin: 15px auto;
  }

  &-ticket-btn__a{
    color: #ffffff;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    background-color: $default-red;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    transition: all .5s ease;

    &:hover{
      background-color: $hover-red;
      box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
    }
  }

  &-copy__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
  }

  &-header__h1{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0;
  }

  &-accent__div{
    height: 3px;
    width: 25px;
    background-color: $default-red;
    margin: 10px 0;
  }

  &-date__div{
    margin: 15px 0;
    font-weight: 600;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-details__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-product__div{
    padding: 5px;
    margin: 5px;
  }

  &-product-img__div{
    padding: 5px;
    width: 125px;
    margin: 10px auto;
  }

  &-product-img__img{
    width: 100%;
  }

  &-product-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 150px;
    margin: 15px auto;
  }

  &-product-btn__div--soldout{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 150px;
    margin: 15px auto;
    background-color: $default-footer-content-gray;
    border-radius: 4px;
    padding: 10px;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    color: $default-white;
    cursor: not-allowed;
  }

  &-product-btn__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-product-btn__a{
    color: #ffffff;
    font-family: $default-sans-serif-font;
    font-size: 18px;
    background-color: $default-red;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    transition: all .5s ease;

    &:hover{
      background-color: $hover-red;
      box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
    }
  }

  &-new-payment__p{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }


  &-image-top__div{
    width: 350px;
    margin: auto;
    padding: 5px;
  }

  &-image-top-intro__div{
    padding: 5px;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: center;
  }

  &-image-top__img{
    width: 100%;
  }

  &-description__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 15px 0;
    // padding: 5px;
  }

  &-author-image__div{
    width: 250px;
    padding: 5px;
    margin: 15px auto;
  }

  &-credit__p{
    font-family: $default-sans-serif-font;
    font-size: 13px;
    text-align: center;
  }

  &-bio__div{
    margin: 15px auto;
  }

  &-author-image__img{
    width: 100%;
  }

  &-others__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 5px;
    margin: 15px;
  }

  &-bottom-image__div{
    // width: 300px;
    margin: auto;
    padding: 5px;
  }

  &-bottom-image__img{
    width: 100%;
  }

  @media only screen and (min-width: 767px) {
    &-details__div{
      grid-template-columns: 150px 1fr;
    }

    &-book-image__div{
      align-self: start;
      margin: 10px 0;
    }

    &-product-img__div{
      width: 150px;
    }

    &-image-top__div{
      width: 500px;
    }

    &-description__div{
      margin-bottom: 20px;
    }

    &-bio__div{
      padding: 5px;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-image-top__div{
      width: 100%;
    }

    &-details__div{
      max-width: 900px;
      margin: 50px auto;
      grid-template-columns: 200px 1fr;
    }

    &-book-image__div{
      width: 150px;
    }
  }
}
