@import '../../scss/variables';

.tou{
    &-content__div{
      padding: 5px;
      font-family: $default-sans-serif-font;
      font-size: 15px;
      line-height: 1.5;
    }
    &-details__div{
      font-family: inherit;
      font-size: inherit;
      padding: 5px;
    }
    &-header__h1{
      font-family: $default-sans-serif-font;
      font-size: 20px;
      color: $default-red;
      margin-bottom: 10px;
    }
    &-text__p{
      font-family: $default-sans-serif-font;
      font-size: 15px;
    }
    &-subheader__h2{
      margin: 10px 0 10px 0;
      font-family: $default-sans-serif-font;
      font-size: 18px;
      font-weight: 600;
    }
    &-list__ul{
      padding: 5px;
      margin-left: 20px;
      font-size: 12px;
      font-family: $default-sans-serif-font;
    }
    &-list-item__li{
      font-size: inherit;
      font-family: inherit;
      margin-bottom: 10px;
    }
  
    &-link-contact__navlink{
      text-decoration: none;
      color: $default-red;
    }

    @media only screen and (min-width: 767px) {
      &-content__div{
        width: 600px;
        margin: 0 auto 50px auto;
      }
    }
  
    @media only screen and (min-width: 1024px) {
      &-container__div{
        max-width: 1366px;
        margin: auto;
      }

      &-content__div{
        width: 900px;
      }

      &-header__h1{
        font-size: 25px;
      }
      &-subheader__h2{
        font-size: 20px;
      }

      &-text__p{
        font-size: 18px;
      }
      &-list__ul{
        font-size: 15px;
      }
    }
  }