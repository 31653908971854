@import '../../scss/variables';

.submissionstou{
  &-header__div{
    margin: 15px auto;
    width: 100%;
  }
    
  &-header__img{
    width: 100%;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 10px;
    margin: 10px auto;
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }
  }
}