@import '../../scss/variables';

.bbtfrentals{
  &-hero__div{
    background-image: url(urlGeneratorSASS("/S/pages/bbtf-gallery-10-dropshadow-text-embedded.png"));
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    text-align: center;
    padding: 5px;
    margin: 50px 0;
  }

  &-link__link{
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: $default-red;
  }
  
  &-divider__div{
    width: 80%;
    margin: 50px auto;
    height: 3px;
    background-color: $default-content-gray;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    text-align: center;
    line-height: 1.7;
  }

  &-header__h4{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0;
  }

  &-accent__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    margin: auto;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-price__p{
    padding: 5px 0;
  }

  &-link__a{
    text-decoration: none;
    color: $default-red;
    font-family: $default-sans-serif-font;
  }

  &-collection__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 5px;
  }

  &-image__div{
    width: 250px;
    padding: 5px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &-image__img{
    width: 225px;
    height: 150px;
  }

  &-button__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 5px;
    margin: 20px 0 0 0;
  }

  @media only screen and (min-width: 767px) {
    &-hero__div{
      height: 500px;
    }

    &-collections__div{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 5px;
      margin: 50px 0;
    }

    &-collection__div{
      margin: 20px 0;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-hero__div{
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }

    &-header__div{
      max-width: 1366px;
      margin: auto;
    }

    &-collections__div{
      grid-template-columns: repeat(3, 1fr);
      max-width: 1366px;
      margin: auto;
    }

    &-price__p{
      font-size: 15px;
    }

    &-link__link{
      &:hover{
        color: $hover-red;
      }
    }

    &-link__a{
      &:hover{
        color: $hover-red;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    &-hero__div{
      height: 600px;
    }
  }
}