@import '../../scss/variables';

.orderdetail{
  &-content__div{
    padding: 5px;
    margin-left: 15px;
  }

  &-header__div{
    display: flex;
    flex-flow: row wrap;
  }

  &-address__div{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 15px 0;
  }

  &-dash__div{
    width: 25px;
    height: 3px;
    background-color: $default-red;
    margin: 5px;
  }

  &-detail__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-detail-address__div{
    line-height: 1.7;
  }

  &-detail__p{
    padding-left: 5px;
  }

  &-items__div{
    padding: 5px;
    margin: 25px 0;
  }

  &-status__div{
    display: flex;
    flex-flow: row wrap;
  }

  &-status-detail__div{
    position: relative;
  }
}