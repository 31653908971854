@import '../../scss/variables';

.bbtfgallerydialog{
  &-image__div{
    margin: auto;
    border: 5px solid $default-white;
  }

  &-image__img{
    width: 100%;
    position: relative;
    top: 3px;
  }
}