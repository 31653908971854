@import '../../scss/variables';

.accessibility-statement{
  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
    padding: 5px;
    margin: 5px;
  }

  &-header__h2{
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    color: $default-red;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
    margin-left: 10px;
  }

  &-content__div {
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 1023px) {
    &-container__div{
      max-width: 1366px;
      margin: auto;
    }
  }
}