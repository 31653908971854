@import '../../scss/variables';

.productsectionlist{

  &-container__div{
    margin: 0 15px;
  }

  &-content__div{
    margin: 50px 0;
    display: grid;
    grid-template-areas: 
    'header header'
    'filterBtn sort'
    'list list';
  }

  &-header__pageheader{
    grid-area: header;
  }

  &-filter-btn__section{
    grid-area: filterBtn;
  }

  &-order__section{
    grid-area: sort;
  }

  &-items__section{
    grid-area: list;
  }

  &-filter__span{
    font-size: 18px;
    font-family: $default-sans-serif-font;
    position: relative;
    top: 3px;
    color: $default-black;
  }

  &-items__div{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: 5px;
  margin: 50px 0;
  }

  &-item-link__link{
    text-decoration: none;
    color: $default-black;
    transition: .5s all ease;
  }

  &-item__div{
    width: 100px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 5px;
    margin-top: 20px;
  }

  &-item-image__img{
    width: 100%;
  }

  &-details__div{
    text-align: center;
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-header__h4{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    font-weight: 400;
    margin: 25px 5px 10px 5px;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
  }

  &-details-out-of-stock__p{
    color: $default-red;
    font-weight: 600;
  }

  &-no-items__p{
    margin: 50px 0;
    font-family: $default-sans-serif-font;
    font-size: 15px;
    text-align: left;
    line-height: 1.7;
  }

  @media only screen and (min-width: 374px) {
    &-item__div{
      width: 125px;
    }
  }

  @media only screen and (min-width: 767px) {
    &-content__main{
      // display: grid;
      // grid-template-columns: 225px 1fr;
      // grid-template-areas: 'filter content';
    }

    // &-content__div{
    //   display: block;
    // }

    &-filter__section{
      grid-area: filter;
      border-right: 3px solid $default-border-gray;
    }

    &-content__div{
      // display: grid;
      // grid-template-columns: 250px 1fr;
      // grid-template-areas: 
      //   'filter header'
      //   'filter sort'
      //   'filter list';
      grid-area: content;
    }

    &-items__div{
      grid-template-columns: repeat(3, 1fr);
    }

    &-no-items__p{
      text-align: center;
    }
  }

  @media only screen and (min-width: 1023px) {
    &-content__main{
      // grid-template-columns: 275px 1fr;
    }

    &-content__div{
      max-width: 1366px;
      margin: auto;
    }

    &-items__div{
      grid-template-columns: repeat(5, 1fr);
    }

    &-item-link__link{
      &:hover{
        color: $default-red;
      }
    }

    &-item-image__img{
      transition: .5s all ease;
      &:hover{
        transform: translateY(-5px);
      }
    }
  }

  @media only screen and (min-width: 1439px) {
    &-content__main{
      // grid-template-columns: 325px 1fr;
    }
  }

  @media only screen and (min-width: 2000px) {
    &-items__div{
      // grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (min-width: 2559px) {
    &-content__main{
      // grid-template-columns: 350px 1fr;
    }

    &-items__div{
      // grid-template-columns: repeat(5, 1fr);
    }
  }
}