@import '../../scss/variables';

.giftcard{
  &-header__div{
    padding: 5px;
    margin-bottom: 15px;
  }
  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 5px;
    line-height: 1.7;
    text-align: center;
    font-weight: 400;
  }

  &-cashstar__div{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    padding: 5px;
  }

  &-cashstar__iframe{
    width: 100%;
    height: 1375px;
  }

  @media only screen and (min-width: 767px) {
    &-cashstar__div{
      max-width: 600px;
      margin: 0 auto 50px auto;
    }
  }

  @media only screen and (min-width: 1023px) {
      &-cashstar__div{
        max-width: 900px;
      }
  }

  @media only screen and (min-width: 1439px) {
      &-header__div{
        width: 900px;
        margin: 0 auto 15px auto;
      }
  }

}