@import './scss/defaults';
@import './scss/variables';

.app{
  &-container__div{
    width: 100%;
    // background-color: #fdfdfd;
    background-color: #fff;
  }
  @media only screen and (min-width: 1024px) {
    &-container__div{
      // max-width: 1366px;
      // margin: auto;
    }
  }
}


