@import '../../scss/variables';

.strandcastfeat{
  &-container__div{
    margin: 50px 0 0 0;
  }

  &-image__div{
    width: 175px;
    position: relative;
    z-index: 1;
    margin-left: 15px;
    transition: all 2s ease;
  }

  &-image__img{
    width: 100%;
    position: absolute;
    padding: 5px;
  }

  &-card__div{
    position: relative;
    z-index: 2;
    top: 100px;
    margin: 0 auto 150px auto;
    width: 80%;
  }

  &-header__h3{
    font-family: $default-sans-serif-font;
    font-size: 20px;
    margin: 5px 0 10px 0;
    color: $default-red;
  }

  &-header__h4{
    font-family: $default-sans-serif-font;
    font-size: 18px;
    margin: 15px 0 15px 0;
  }

  &-header__h5{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    margin: 15px 0 15px 0;
    // font-weight: 400;
    color: $default-dark-gray;
    line-height: 1.5;
  }

  &-accent__div{
    height: 4px;
    width: 30px;
    background-color: $default-yellow;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    line-height: 1.7;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-link__div{
    margin: 5px 0 15px 0;
  }

  &-link__link{
    text-decoration: none;
    color: $default-red;

    &:hover{
      color: $hover-red;
    }
  }

  &-iframe__div{
    margin: 20px auto 0 auto;
  }

  @media only screen and (min-width: 767px) {
    &-image__div{
      width: 175px;
      position: relative;
      z-index: 1;
      margin-left: 40px;
    }

    &-card__div{
      position: relative;
      z-index: 2;
      top: 75px;
      margin: 0 auto 150px auto;
      width: 80%;
    }

  }

  @media only screen and (min-width: 1023px) {
  
    &-image__div{
      width: 200px;
    }

    &-image__div--hover{
      transform: translateY(-50px);
    }
  
    &-link__link{ 
      &:hover{
        color: $hover-red;
      }
    }
  }
}