@import '../../scss/variables';

.bbtfdialog{

  &-image__div{
    padding: 5px;
    margin: 15px;
  }
  
  &-image__img{
    width: 100%; 
  }

  &-alternates__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  &-alternate__div{
    padding: 15px;
    width: 50px;
    position: relative;
  }

  &-alternate__img{
    width: 100%;
  }

  &-accent-active__div{
    width: 20px;
    height: 3px;
    background-color: $default-red;
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &-divider__div{
    width: 80%;
    margin: 10px auto;
    height: 3px;
    background-color: $default-content-gray;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    text-align: center;
    margin: 15px;
    line-height: 1.7;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
  }

  @media only screen and (min-width: 767px) {
    &-alternate__div{
      width: 75px;
    }
    
    &-accent-active__div{
      width: 25px;
    }

  }
}

